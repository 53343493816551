import { MdOutlineNotificationsNone } from "react-icons/md";
import { RxDashboard } from "react-icons/rx";
import { FaWpforms } from "react-icons/fa";
import { CiSettings } from "react-icons/ci";
import { PiUsersThreeBold } from "react-icons/pi";
import { FaUsers } from "react-icons/fa6";
import { ImUsers } from "react-icons/im";

export const DoctorRoutes = [
  {
    id: 1,
    name: "Dashboard",
    path: "/",
    icon: <RxDashboard className="text-[20px]" />
  },
  {
    id: 2,
    name: "Patients",
    path: "/patients",
    icon: <PiUsersThreeBold className="text-[20px]" />
  },
  {
    id: 3,
    name: "Manage Forms",
    icon: <FaWpforms className="text-[20px]" />,
    children: [
      {
        id: 31,
        name: "Medical History Forms",
        path: "/manage-forms/medical-history"
      },
      {
        id: 32,
        name: "Treatment Forms",
        path: "/manage-forms/treatment"
      },
      {
        id: 33,
        name: "Exam Forms",
        path: "/manage-forms/exam"
      },
      {
        id: 34,
        name: "Consent Forms",
        path: "/manage-forms/consent"
      }
    ]
  },
  {
    id: 5,
    name: "Notifications",
    path: "/notifications",
    icon: <MdOutlineNotificationsNone className="text-[20px]" />
  },
  {
    id: 6,
    name: "Settings",
    path: "/settings",
    icon: <CiSettings className="text-[20px]" />
  }
];

export const PatientRoutes = [
  {
    id: 1,
    name: "Dashboard",
    path: "/",
    icon: <RxDashboard className="text-[20px]" />
  },
  {
    id: 3,
    name: "Manage Forms",
    icon: <FaWpforms className="text-[20px]" />,
    children: [
      {
        id: 31,
        name: "Medical History Forms",
        path: "/manage-forms/medical-history"
      },
      {
        id: 32,
        name: "Treatment Forms",
        path: "/manage-forms/treatment"
      },
      {
        id: 33,
        name: "Exam Forms",
        path: "/manage-forms/exam"
      },
      {
        id: 34,
        name: "Consent Forms",
        path: "/manage-forms/consent"
      }
    ]
  },
  {
    id: 5,
    name: "Notifications",
    path: "/notifications",
    icon: <MdOutlineNotificationsNone className="text-[20px]" />
  },
  {
    id: 6,
    name: "Settings",
    path: "/settings",
    icon: <CiSettings className="text-[20px]" />
  }
];

export const AdminRoutes = [
  {
    id: 1,
    name: "Dashboard",
    path: "/",
    icon: <RxDashboard className="text-[20px]" />
  },
  {
    id: 2,
    name: "Doctors",
    path: "/doctors",
    icon: <ImUsers className="text-[20px]" />
  },
  {
    id: 3,
    name: "Patients",
    path: "/patients",
    icon: <FaUsers className="text-[20px]" />
  },
  {
    id: 3,
    name: "Manage Forms",
    icon: <FaWpforms className="text-[20px]" />,
    children: [
      {
        id: 31,
        name: "Medical History Forms",
        path: "/manage-forms/medical-history"
      },
      {
        id: 32,
        name: "Treatment Forms",
        path: "/manage-forms/treatment"
      },
      {
        id: 33,
        name: "Exam Forms",
        path: "/manage-forms/exam"
      },
      {
        id: 34,
        name: "Consent Forms",
        path: "/manage-forms/consent"
      }
    ]
  },
  {
    id: 4,
    name: "Notifications",
    path: "/notifications",
    icon: <MdOutlineNotificationsNone className="text-[20px]" />
  },
  {
    id: 5,
    name: "Settings",
    path: "/settings",
    icon: <CiSettings className="text-[20px]" />
  }
];
