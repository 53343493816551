import React, { useCallback, useEffect, useState } from "react";
import AppHead from "../../seo/AppHead";
import { HiUserGroup } from "react-icons/hi";
import { FaRegFileImage } from "react-icons/fa";
import { FiFileText } from "react-icons/fi";
import { CiSearch } from "react-icons/ci";
import { Link } from "react-router-dom";
import { CardSkelton_1 } from "../../components/shared/Skeltons";
import { MdKeyboardArrowRight } from "react-icons/md";
import useGetPatients from "../../hooks/useGetPatients";
import { debounce } from "lodash";
import { GoChevronRight } from "react-icons/go";
import useDashboardData from "../../hooks/useDashboardData";

function DashboardPage() {
  return (
    <>
      <AppHead title="Dashboard - CHARITE" />
      <div className="p-10">
        <DashboardCard />
        <div className="flex mt-10 gap-4">
          <PatientsList />
          <ActivityLog />
        </div>
      </div>
    </>
  );
}

export default DashboardPage;

function DashboardCard() {
  const { dashboard, DashboardData } = useDashboardData();
  useEffect(() => {
    DashboardData(`/api/doctor/dashboard/`);
  }, []);

  const dashBoard = [
    {
      id: 1,
      title: "Patients",
      counts: dashboard?.data?.total_patients || 0,
      icon: <HiUserGroup />,
      bgColor: "bg-blue-300",
      textColor: "text-primary",
    },
    {
      id: 2,
      title: "Consent form shared",
      counts: dashboard?.data?.consent_forms_submitted || 0,
      icon: <FaRegFileImage />,
      bgColor: "bg-green-200",
      textColor: "text-[#4AD991]",
    },
    {
      id: 3,
      title: "Exam form shared",
      counts: dashboard?.data?.exam_forms_submitted || 0,
      icon: <FiFileText />,
      bgColor: "bg-orange-200",
      textColor: "text-[#FF9066]",
    },
  ];
  return (
    <>
      <div className="flex md:flex-row xs:flex-col gap-2 mt-2">
        {dashboard.loading ? (
          <>
            {Array.from({ length: 3 }).map((_, index) => (
              <CardSkelton_1 key={index} />
            ))}
          </>
        ) : (
          <>
            {dashBoard?.map((data, index) => (
              <div
                key={index}
                className="bg-white rounded-[7px] md:w-[33%] xs:w-full py-6 px-6 flex relative"
                style={{ boxShadow: "0px 0px 7px #e0e0e0" }}
              >
                <div>
                  <h1 className="font-[600] text-[32px]  font-roboto text-[#272727]">
                    {data.counts}
                  </h1>
                  <p className="font-[500] text-[16px] font-roboto text-[#787878] mt-4">
                    {data.title}
                  </p>
                </div>
                <div
                  className={`text-[32px] ${data.textColor} ${data.bgColor} rounded p-3 absolute top-6 right-8`}
                >
                  {data.icon}
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
}

function PatientsList() {
  const [query, setQuery] = useState("");
  const { patients, setPatients, GetPatients } = useGetPatients();

  const debouncedSearch = useCallback(
    debounce((value) => {
      setPatients((prevState) => ({ ...prevState, currentPage: 1 }));
      GetPatients(
        `/api/patient-list/?page=${patients.currentPage}&search=${value}`
      );
    }, 1000),
    []
  );
  const handleChange = (e) => {
    const { value } = e.target;
    setQuery(value);
    debouncedSearch(value);
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  useEffect(() => {
    GetPatients(
      `/api/patient-list/?page=${patients.currentPage}&search=${query}`
    );
  }, [patients.currentPage]);

  return (
    <div className="w-[70%]">
      <div className="flex items-center justify-between mb-3">
        <div className="flex items-center  gap-2">
          <h1 className="font-[700] text-[20px] font-roboto text-[#272727] ">
            Patients List
          </h1>
          <p className="font-[400] text-[16px] font-roboto text-primary">
            ({patients?.data?.count})
          </p>
        </div>
        <div className="flex items-center bg-white border border-[#DEE4ED] px-3 rounded-lg w-[60%] h-[40px]">
          <CiSearch className="text-[22px] mr-3 text-[#788BA5]" />
          <input
            type="search"
            value={query}
            onChange={handleChange}
            placeholder="Search by name or email"
            className="w-[90%] outline-none border-none font-[400] text-[14px] font-roboto"
          />
        </div>
      </div>
      <div className="bg-white border border-[#DEE4ED] rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right">
          <thead className="bg-[#FAFAFA] text-[#272727] font-[500] text-[15px] border-b border-[#DEE4ED] rounded-t-lg">
            <tr>
              {["Patient Name", "Email", "Disease", "Gender"].map(
                (item, index) => (
                  <th
                    scope="col"
                    className={`py-4 px-5 ${
                      index === 0
                        ? "rounded-tl-lg"
                        : index === 3
                        ? "rounded-tr-lg"
                        : ""
                    }`}
                    key={index}
                  >
                    {item}
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody>
            <>
              {patients?.data?.results?.map((item, index) => (
                <tr
                  key={index}
                  className="bg-white text-[14px] text-[#788BA5] border-b border-[#DEE4ED]"
                >
                  <td className="py-3 px-5">
                    <div className="flex items-center gap-2">
                      <svg
                        className="w-[35px] h-[35px] text-gray-200 dark:text-gray-700"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                      </svg>
                      <div>
                        <p className="font-[500] text-[16px] font-roboto text-[#272727] ">
                          {item.user.first_name} {item.user.last_name}
                        </p>
                        <span className="font-[400] text-[12px] font-roboto text-[#787878]">
                          {item.user.age}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="py-3 px-5">{item.user.email}</td>
                  <td className="py-3 px-5"> {item.disease}</td>
                  <td className="py-3 px-5">{item.user.gender}</td>
                </tr>
              ))}
            </>
          </tbody>
        </table>
        <Link
          to="/patients"
          className="text-center py-2 px-3 text-[#3B82F6] flex items-center hover:bg-primary hover:text-white rounded-b-lg"
        >
          View all Patients
          <MdKeyboardArrowRight className="text-[25px]" />
        </Link>
      </div>
    </div>
  );
}

function ActivityLog() {
  return (
    <div className="w-[30%]">
      <h1 className="font-[700] text-[20px] font-roboto text-[#272727] mb-3">
        Activity Log
      </h1>
      <div className="p-4 h-auto border border-[#DEE4ED] bg-white rounded-lg">
        {Array.from({ length: 10 }).map((_, index) => (
          <div className="mb-3" key={index}>
            <p className="text-[14px] font-[400] font-roboto text-[#272727]">
              Dr. Samantha Queque logged in from IP address 192.168.1.1.
            </p>
            <span className="text-[14px] font-[500] font-roboto text-[#788BA5]">
              1 hour ago
            </span>
          </div>
        ))}
        <Link to="#" className="flex items-center mt-3 cursor-pointer">
          <p className="text-[14px] font-[500] font-roboto text-primary">
            View All Activities
          </p>
          <GoChevronRight className="text-[20px] text-primary" />
        </Link>
      </div>
    </div>
  );
}
