import React from "react";
import AppHead from "../../seo/AppHead";
import { BiCheckDouble } from "react-icons/bi";

function NotificationsPage() {
  return (
    <>
      <AppHead title="Notifications - CHARITE" />
      <div className="p-10">
        <div className="flex justify-between">
          <h1 className="text-[#272727] font-roboto text-[20px] font-[700] leading-9">
            Notification
          </h1>
          <div className="flex items-center gap-1 text-primary text-[14px] font-[500] leading-4 cursor-pointer">
            <BiCheckDouble className="text-[20px]" />
            <p>Mark as Read</p>
          </div>
        </div>
        <div className="flex flex-col gap-2 mt-2">
          {Array.from({ length: 10 }).map((item, index) => (
            <div
              key={index}
              className={`w-full h-[70px] rounded border-l-[2px] border-primary bg-white flex items-center justify-between px-4`}
            >
              <div className="flex items-center gap-2">
                <svg
                  class="w-[40px] h-[40px] text-gray-200 dark:text-gray-700"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                </svg>
                <div>
                  <h1 className="text-[#272727] text-[16px] font-[600] leading-5">
                    Dr. Smith
                  </h1>
                  <p className="text-[#797979] text-[14px] font-[400] leading-4">
                    Your prescription for ADHD medication has been updated.
                    Please check your profile for the new dosage instructions
                  </p>
                </div>
              </div>
              <p className="text-[#717171] text-[13px] font-[400] leading-4">
                2024-06-22
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default NotificationsPage;
