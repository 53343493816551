import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ButtonLoader_1 } from "../../components/shared/Loaders";
import AppHead from "../../seo/AppHead";
import useForgotPassword from "../../hooks/useForgotPassword";

function ForgotPassword() {
  return (
    <>
      <AppHead title="Log in - CHARITE" />
      <div className="grid grid-cols-2 w-full h-[100vh]">
        <LeftSide />
        <RightSide />
      </div>
    </>
  );
}

export default ForgotPassword;

function LeftSide() {
  return (
    <div className="w-full h-full bg-[#709ACF] flex flex-col gap-12 justify-center items-center">
      <img
        src="/assets/images/login_image.png"
        alt=""
        className="w-[250px] 2xl:w-auto"
      />
      <div className="flex flex-col gap-1 items-center justify-center text-white">
        <h1 className="text-[32px] font-[600]">
          Welcome to Your Health Journey
        </h1>
        <p className="text-center text-[14px] font-[400]">
          Our portal provides a seamless way for doctors and patients to <br />{" "}
          connect and manage healthcare needs efficiently.
        </p>
      </div>
    </div>
  );
}

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Email is required")
});
function RightSide() {
  const initialValues = {
    email: ""
  };

  const { ForgotPassword, loading } = useForgotPassword();
  const { values, errors, handleChange, handleSubmit, touched, resetForm } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async (values) => {
        await ForgotPassword({ email: values?.email });
        resetForm();
      }
    });
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !loading) {
      handleSubmit();
    }
  };
  return (
    <div className="w-full h-full bg-white flex flex-col gap-6 justify-center items-center">
      <img src="/assets/logo/logo.png" alt="CHARITE" />
      <form
        className="w-[70%] border border-[#DEE4ED] rounded-[6px] py-8 px-8"
        style={{ boxShadow: "rgba(21, 29, 40, 0.04)" }}
      >
        <div className="flex flex-col justify-center items-center">
          <h1 className="font-[600] text-[24px] text-[#272727] mb-2 leading-9">
            Forgot Your Password
          </h1>
          <p className="font-[400] text-[14px] text-[#788BA5] text-center mb-8 leading-5">
            Log in to manage your health records, consult with
            <br /> doctors, and stay updated on your healthcare needs.
          </p>
        </div>
        <div className="w-full flex flex-col gap-6">
          <div>
            <label className="text-[#272727] font-[400] text-[14px] leading-5">
              Email
            </label>
            <input
              type="text"
              placeholder="Enter email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              className="w-full h-[40px] px-3 rounded-md border border-[#DEE4ED] outline-none mt-2 hover:border-primary focus:border-primary"
            />
            {errors.email && touched.email && (
              <p className="text-red-700 text-xs mt-1">{errors.email}</p>
            )}
          </div>
        </div>
        <button
          className="bg-primary w-full h-[40px] text-white rounded-[10px] mt-6 flex justify-center items-center"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? <ButtonLoader_1 /> : "Forgot Password"}
        </button>
      </form>
    </div>
  );
}
