/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import AppHead from "../../seo/AppHead";
import useGetPatients from "../../hooks/useGetPatients";
import { debounce } from "lodash";
import Paginations from "../../components/shared/Paginations";
import { TableSkelton_1 } from "../../components/shared/Skeltons";
import ShowMessage from "../../components/shared/ShowMessage";
import { useNavigate } from "react-router-dom";

function Patients() {
  const [query, setQuery] = useState("");
  const { patients, setPatients, GetPatients } = useGetPatients();

  const debouncedSearch = useCallback(
    debounce((value) => {
      setPatients((prevState) => ({ ...prevState, currentPage: 1 }));
      GetPatients(
        `/api/patient-list/?page=${patients.currentPage}&search=${value}`
      );
    }, 1000),
    []
  );
  const handleChange = (e) => {
    const { value } = e.target;
    setQuery(value);
    debouncedSearch(value);
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  useEffect(() => {
    GetPatients(
      `/api/patient-list/?page=${patients.currentPage}&search=${query}`
    );
  }, [patients.currentPage]);

  const handlePageChange = (event, value) => {
    setPatients((prevState) => ({ ...prevState, currentPage: value }));
  };
  return (
    <>
      <AppHead title="Dashboard - CHARITE" />
      <div className="p-10">
        <div className="flex justify-between ">
          <h1 className="text-[#272727] font-roboto text-[20px] font-[700] leading-9">
            Patient List
          </h1>
          <div className="flex items-center bg-white border border-[#DEE4ED] px-3 rounded-lg w-[45%] h-[40px]">
            <CiSearch className="text-[22px] mr-3" />
            <input
              type="search"
              value={query}
              onChange={handleChange}
              placeholder="Search by name or email"
              className="w-[90%] outline-none border-none font-[400] text-[14px] font-roboto"
            />
          </div>
        </div>
        <div
          className="rounded-lg mt-5"
          style={{ boxShadow: "0px 0px 7px #e0e0e0" }}
        >
          <div className="rounded-lg">
            <Table patients={patients} />
          </div>
        </div>
        <div className="w-full flex justify-between items-center mt-5">
          <p className="text-[#272727] font-roboto text-[14px] font-[400] leading-5">
            {patients?.data?.results.length > 0 &&
              patients?.data?.display_string}
          </p>
          <Paginations
            totalPages={patients.totalPages}
            currentPage={patients.currentPage}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
}

export default Patients;
function Table(props) {
  const Navigate = useNavigate();
  return (
    <>
      <table className="w-full text-sm text-left rtl:text-right rounded-lg">
        <thead className="sticky z-[1] top-0 bg-[#FAFAFA] text-[#272727] font-[500] text-[15px]">
          <tr>
            {[
              "Patient Name",
              "Email",
              "Consent Status",
              "Exam Form Status",
              "Disease"
            ].map((item, index) => (
              <th scope="col" className="py-5 px-5" key={index}>
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.patients.loading ? (
            <>
              {Array.from({ length: 10 }).map((_, index) => (
                <TableSkelton_1 key={index} />
              ))}
            </>
          ) : (
            <>
              {props.patients.data?.results?.map((item, index) => (
                <tr
                  key={index}
                  onClick={() => {
                    Navigate("/patients/patient-details", {
                      state: {
                        selectedPatient: item
                      }
                    });
                  }}
                  className="bg-white text-[14px] text-[#788BA5] border border-[#DEE4ED] hover:bg-primary hover:text-white cursor-pointer"
                >
                  <td className="py-3 px-5">
                    <div className="flex items-center gap-2">
                      <svg
                        class="w-[30px] h-[30px] text-gray-200 dark:text-gray-700"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                      </svg>
                      <p>
                        {item.user.first_name} {item.user.last_name}
                      </p>
                    </div>
                  </td>
                  <td className="py-3 px-5"> {item.user.email}</td>
                  <td className="py-3 px-5">
                    {item.consent_status ? (
                      <div className="bg-[#F5FFF7] text-[#23933B] w-[65%] py-1 flex justify-center rounded-md">
                        Consented
                      </div>
                    ) : (
                      <div className="bg-[#FFEED9] text-[#D78119] w-[65%] py-1 flex justify-center rounded-md">
                        Pending
                      </div>
                    )}
                  </td>
                  <td className="py-3 px-5">
                    {item.exam_status ? (
                      <div className="bg-[#F5FFF7] text-[#23933B] w-[65%] py-1 flex justify-center rounded-md">
                        Received
                      </div>
                    ) : (
                      <div className="bg-[#FFEED9] text-[#D78119] w-[65%] py-1 flex justify-center rounded-md">
                        Pending
                      </div>
                    )}
                  </td>
                  <td className="py-3 px-5"> {item.disease}</td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
      {!props.patients.loading && props.patients.message && (
        <div className="bg-white flex justify-center items-center w-full h-[300px]">
          <ShowMessage message={props.patients.message} />
        </div>
      )}
    </>
  );
}
