/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { CiSearch } from "react-icons/ci";
import AppHead from "../../seo/AppHead";
import useGetPatients from "../../hooks/useGetPatients";
import { debounce } from "lodash";
import Paginations from "../../components/shared/Paginations";
import { TableSkelton_3 } from "../../components/shared/Skeltons";
import ShowMessage from "../../components/shared/ShowMessage";
import { GoPlus } from "react-icons/go";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { NavLink, useNavigate } from "react-router-dom";
import DeleteModal from "../../components/modals/DeleteModal";
import GlobalContext from "../../context/GlobalContext";
import useActiveStatus from "../../hooks/useActiveStatus";

function Patients() {
  const [query, setQuery] = useState("");
  const { patients, setPatients, GetPatients } = useGetPatients();
  const [deleteModal, setDeleteModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const { updateResponse } = useContext(GlobalContext);

  const debouncedSearch = useCallback(
    debounce((value) => {
      setPatients((prevState) => ({ ...prevState, currentPage: 1 }));
      GetPatients(
        `/api/patient-list/?page=${patients.currentPage}&search=${value}`
      );
    }, 1000),
    []
  );

  const handleChange = (e) => {
    const { value } = e.target;
    setQuery(value);
    debouncedSearch(value);
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  useEffect(() => {
    GetPatients(
      `/api/patient-list/?page=${patients.currentPage}&search=${query}`
    );
  }, [patients.currentPage, updateResponse]);

  const handlePageChange = (event, value) => {
    setPatients((prevState) => ({ ...prevState, currentPage: value }));
  };

  return (
    <>
      <AppHead title="Dashboard - CHARITE" />
      <div className="p-10">
        <div className="flex justify-between items-center">
          <h1 className="text-[#272727] font-roboto text-[20px] font-[700] leading-9">
            Patients List
          </h1>
          <div className="flex w-[50%] gap-3">
            <NavLink
              to="/patients/patient-form"
              state={{ action: "add" }}
              className="bg-primary w-[160px] h-[40px] flex justify-center items-center rounded gap-2 cursor-pointer"
            >
              <GoPlus className="text-white text-[20px]" />
              <p className="text-white font-roboto text-[14px] font-[500]">
                New Patient
              </p>
            </NavLink>
            <div className="flex items-center bg-white border border-[#DEE4ED] px-3 rounded-lg w-[80%] h-[40px]">
              <CiSearch className="text-[22px] mr-3" />
              <input
                type="search"
                value={query}
                onChange={handleChange}
                placeholder="Search by name or email"
                className="w-[90%] outline-none border-none font-[400] text-[14px] font-roboto"
              />
            </div>
          </div>
        </div>
        <div className="bg-white border border-[#DEE4ED] shadow-md mt-5">
          <Table
            patients={patients}
            setIsOpenModal={setDeleteModal}
            isOpenModal={deleteModal}
            setSelected={setSelected}
            selected={selected}
          />
        </div>
        <div className="w-full flex justify-between items-center mt-5">
          <p className="text-[#272727] font-roboto text-[14px] font-[400] leading-5">
            {patients?.data?.results?.length > 0 &&
              patients?.data?.display_string}
          </p>
          <Paginations
            totalPages={patients.totalPages}
            currentPage={patients.currentPage}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
}

export default Patients;

function Table({
  setIsOpenModal,
  isOpenModal,
  setSelected,
  patients,
  selected
}) {
  const [activeDropdown, setActiveDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const Navigate = useNavigate();

  const handleShow = (index) => {
    if (activeDropdown === index) {
      setActiveDropdown(!activeDropdown);
    } else {
      setActiveDropdown(index);
    }
  };
  const handleCloseDropdown = () => {
    setActiveDropdown(false);
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        e.target.closest(".small-circle-main") ||
        (dropdownRef.current && dropdownRef.current.contains(e.target))
      ) {
        return;
      }
      handleCloseDropdown();
    };
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const { ActiveStatus } = useActiveStatus();
  const handleSubmit = async (id, action) => {
    await ActiveStatus(
      {
        action: action
      },
      `/api/user/toggle-activation/${id}/`
    );
  };

  return (
    <>
      <table className="w-full text-sm text-left rtl:text-right">
        <thead className="bg-[#FAFAFA] text-[#272727] font-[500] text-[15px] border-b border-[#DEE4ED]">
          <tr>
            {[
              "Name",
              "Gender",
              "Age",
              "Contact",
              "Email",
              "Status",
              "Action"
            ].map((item, index) => (
              <th scope="col" className="py-5 px-5" key={index}>
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {patients.loading ? (
            <>
              {Array.from({ length: 10 }).map((_, index) => (
                <TableSkelton_3 key={index} />
              ))}
            </>
          ) : (
            <>
              {patients.data?.results?.map((item, index) => (
                <tr
                  key={index}
                  className="bg-white text-[14px] text-[#788BA5] border-b border-[#DEE4ED]"
                >
                  <td className="py-3 px-5 flex items-center gap-2">
                    {item?.profile_avatar ? (
                      <img
                        className="rounded-full w-[30px] h-[30px]"
                        src={item?.profile_avatar}
                        style={{ aspectRatio: "1/1", objectFit: "cover" }}
                        alt=""
                      />
                    ) : (
                      <svg
                        class="w-[30px] h-[30px] text-gray-200 dark:text-gray-700"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                      </svg>
                    )}
                    {item.user.first_name} {item.user.last_name}
                  </td>
                  <td className="py-3 px-5"> {item.user.gender}</td>
                  <td className="py-3 px-5">{item.user.age}</td>{" "}
                  <td className="py-3 px-5">{item.user.mobile_no}</td>
                  <td className="py-3 "> {item.user.email}</td>
                  <td className="py-3">
                    {item?.user?.active_status ? (
                      <div className="bg-[#eafcee] text-[#28A745] w-[65%] py-1 px-8 flex justify-center rounded-md">
                        Active
                      </div>
                    ) : (
                      <div className="bg-[#FFEED9] text-[#FD7E14] w-[65%] py-1 px-5 flex justify-center rounded-md">
                        Deactive
                      </div>
                    )}
                  </td>
                  <td className="py-3 px-5">
                    <div className="relative small-circle-main">
                      <HiOutlineDotsVertical
                        className="cursor-pointer"
                        onClick={() => handleShow(index)}
                      />
                      {activeDropdown === index && (
                        <div
                          ref={dropdownRef}
                          className="absolute py-1 w-[130px] bg-white flex flex-col rounded-sm right-[30%] top-[100%] z-20 shadow-md"
                        >
                          <p
                            onClick={() => {
                              Navigate("/patients/patient-details", {
                                state: {
                                  selectedPatient: item
                                }
                              });
                            }}
                            className="px-3 py-1 cursor-pointer font-[400] text-[13px] font-roboto text-[#787878] hover:bg-[#F5F5FE] hover:text-primary"
                          >
                            View Details
                          </p>
                          <div>
                            <p
                              className={`px-3 py-1 cursor-pointer font-[400] text-[13px] font-roboto text-[#787878] hover:bg-[#F5F5FE] hover:text-primary ${
                                item?.user?.active_status
                                  ? "cursor-not-allowed opacity-50"
                                  : ""
                              }`}
                              onClick={() => {
                                if (!item?.user?.active_status) {
                                  handleSubmit(item.user.id, "activate");
                                }
                              }}
                            >
                              Active
                            </p>
                            <p
                              className={`px-3 py-1 cursor-pointer font-[400] text-[13px] font-roboto text-[#787878] hover:bg-[#F5F5FE] hover:text-primary ${
                                !item?.user?.active_status
                                  ? "cursor-not-allowed opacity-50"
                                  : ""
                              }`}
                              onClick={() => {
                                if (item?.user?.active_status) {
                                  handleSubmit(item.user.id, "deactivate");
                                }
                              }}
                            >
                              Deactive
                            </p>
                          </div>
                          <p
                            onClick={() => {
                              Navigate("/patients/patient-form", {
                                state: {
                                  action: "edit",
                                  selectedPatient: item
                                }
                              });
                            }}
                            className="px-3 py-1 cursor-pointer font-[400] text-[13px] font-roboto text-[#787878] hover:bg-[#F5F5FE] hover:text-primary"
                          >
                            Edit
                          </p>
                          <p
                            onClick={() => {
                              setIsOpenModal(!isOpenModal);
                              setSelected(item);
                            }}
                            className="px-3 py-1 cursor-pointer font-[400] text-[13px] font-roboto text-red-400 hover:bg-[#F5F5FE] hover:text-red-400"
                          >
                            Delete
                          </p>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
      {!patients.loading && patients.message && (
        <div className="bg-white flex justify-center items-center w-full h-[300px]">
          <ShowMessage message={patients.message} />
        </div>
      )}
      <DeleteModal
        setIsOpenModal={setIsOpenModal}
        isOpenModal={isOpenModal}
        setSelected={setSelected}
        url={`/api/admin/manage-users/${selected?.user?.id}`}
      />
    </>
  );
}
