export const TableSkelton_1 = () => {
  return (
    <tr
      role="status"
      className="animate-pulse bg-white border border-[#DEE4ED]"
    >
      <td className="py-3 px-5">
        <div className="flex items-center gap-2">
          <svg
            class="w-8 h-8 text-gray-200 dark:text-gray-700"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
          </svg>
          <div className="w-20 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
      </td>
      <td className="py-3 px-5">
        <div className="w-20 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
      </td>
      <td className="py-3 px-5">
        <div className="w-20 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
      </td>
      <td className="py-3 px-5">
        <div className="w-20 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
      </td>
      <td className="py-3 px-5">
        <div className="w-20 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
      </td>
      <td className="py-3 px-5">
        <div className="w-20 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
      </td>
    </tr>
  );
};

export const TableSkelton_2 = () => {
  return (
    <tr
      role="status"
      className="animate-pulse bg-white border border-[#DEE4ED]"
    >
      <td className="py-3 px-5">
        <div className="flex items-center gap-2">
          <svg
            class="w-8 h-8 text-gray-200 dark:text-gray-700"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
          </svg>
          <div className="w-20 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
      </td>
      <td className="py-3 px-5">
        <div className="w-20 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
      </td>
      <td className="py-3 px-5">
        <div className="w-20 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
      </td>
      <td className="py-3 px-5">
        <div className="w-20 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
      </td>
    </tr>
  );
};

export const CardSkelton_1 = () => {
  return (
    <div
      role="status"
      className="shadow-md bg-white rounded-[7px] w-[33.33%] py-12 px-6 flex items-start justify-between relative border border-gray-200 animate-pulse dark:border-gray-700"
    >
      <div>
        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-16 mb-8"></div>
        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48"></div>
      </div>
      <div className="h-12 w-12 bg-gray-200 rounded-[4px] dark:bg-gray-700"></div>
    </div>
  );
};

export const TableSkelton_3 = () => {
  return (
    <tr
      role="status"
      className="animate-pulse bg-white border border-[#DEE4ED]"
    >
      <td className="py-3 px-5">
        <div className="flex items-center gap-2">
          <svg
            class="w-8 h-8 text-gray-200 dark:text-gray-700"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
          </svg>
          <div className="w-20 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
      </td>
      <td className="py-3 px-5">
        <div className="w-20 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
      </td>
      <td className="py-3 px-5">
        <div className="w-20 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
      </td>
      <td className="py-3 px-5">
        <div className="w-20 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
      </td>
      <td className="py-3 px-5">
        <div className="w-20 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
      </td>
      <td className="py-3 px-5">
        <div className="w-20 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
      </td>
      <td className="py-3 px-5">
        <div className="w-20 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
      </td>
    </tr>
  );
};

export const TableSkelton_4 = () => {
  return (
    <div className="grid grid-cols-4 gap-4 w-full mb-4 mt-3 ">
      {Array.from({ length: 4 }).map((_, index) => (
        <div className="px-4 py-6 bg-[#F6F8FB] rounded-xl ">
          <div className="flex justify-between items-center">
            <div className="w-8 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
            <div className="w-8 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
          </div>
          <div className="flex flex-col justify-between items-center gap-2">
            <svg
              class="w-12 h-12 text-gray-200 dark:text-gray-700"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
            </svg>
            <div className="w-12 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
            <div className="w-20 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export const TableSkelton_5 = () => {
  return (
    <div className="grid grid-cols-3 gap-4 w-full mb-4 mt-3 ">
      {Array.from({ length: 3 }).map((_, index) => (
        <div className="px-4 py-6 bg-[#F6F8FB] rounded-xl  ">
          <div className="w-[50%] h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
          <div className="flex justify-between items-center mt-6">
            <div className="w-12 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
            <div className="w-[40%] h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
          </div>
          <div className="flex justify-between items-center mt-6">
            <div className="w-12 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
            <div className="w-[40%] h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
          </div>
          <div className="flex justify-between items-center mt-6">
            <div className="w-8 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
            <div className="w-8 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
          </div>
        </div>
      ))}
    </div>
  );
};
