/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-dupe-keys */
import React, { useState } from "react";
import AppHead from "../../seo/AppHead";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { RiEditFill } from "react-icons/ri";
import { ButtonLoader_1 } from "../../components/shared/Loaders";
import { BiHide, BiShow } from "react-icons/bi";
import { MdOutlineChevronRight } from "react-icons/md";
import { useLocation } from "react-router-dom";
import useEditPatient from "../../hooks/useEditPatient";
import useAddPatient from "../../hooks/useAddPatient";
import useUpload from "../../hooks/useUpload";

const NewValidationSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().nullable(),
  date_of_birth: Yup.string().nullable(),
  mobile_no: Yup.string().nullable(),
  email: Yup.string().required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password length must be at least 6 characters long!")
    .matches(
      /^(?=.*[A-Za-z])(?=.*[@$!%*#?&]).*$/,
      "Password must contain at least one alphabetic character and one special character"
    )
    .max(20, "password must not exceed 20 characters"),
  confirm_password: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("password"), null], "Password must match"),
  disease: Yup.string().required("Disease is required"),
  occupation: Yup.string().nullable(),

  marital_status: Yup.string().nullable(),
  gender: Yup.string().nullable(),

  blood_pressure: Yup.string().nullable(),
  sugar: Yup.string().nullable(),
  blood_group: Yup.string().nullable(),
  height: Yup.string().nullable(),

  address: Yup.string().nullable(),
  state: Yup.string().nullable(),
  city: Yup.string().nullable(),
  postal_code: Yup.string().nullable(),
  profile_avatar: Yup.string().nullable(),

  age: Yup.string().nullable(),
  profile_avatar: Yup.string().nullable(),

  name: Yup.string().nullable(),
  relation: Yup.string().nullable(),
  guardian_email: Yup.string().nullable(),
  contact_no: Yup.string().nullable()
});

const EditValidationSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().nullable(),
  date_of_birth: Yup.string().nullable(),
  mobile_no: Yup.string().nullable(),
  email: Yup.string().nullable(),

  disease: Yup.string().nullable(),
  occupation: Yup.string().nullable(),

  marital_status: Yup.string().nullable(),
  gender: Yup.string().nullable(),

  blood_pressure: Yup.string().nullable(),
  sugar: Yup.string().nullable(),
  blood_group: Yup.string().nullable(),
  height: Yup.string().nullable(),

  address: Yup.string().nullable(),
  state: Yup.string().nullable(),
  city: Yup.string().nullable(),
  postal_code: Yup.string().nullable(),
  profile_avatar: Yup.string().nullable(),

  age: Yup.string().nullable(),
  profile_avatar: Yup.string().nullable(),

  name: Yup.string().nullable(),
  relation: Yup.string().nullable(),
  guardian_email: Yup.string().nullable(),
  contact_no: Yup.string().nullable()
});

function PatientsForm() {
  const location = useLocation();
  const Navigate = useNavigate();
  const { AddPatient } = useAddPatient();
  const { EditPatient } = useEditPatient();
  const { Upload, upload, setUpload } = useUpload();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState(false);
  const [confrimpassword, setConfrimPassword] = useState(false);
  const { selectedPatient = {}, action = "add" } = location.state || {};

  const initialValues = {
    first_name: selectedPatient?.user?.first_name,
    last_name: selectedPatient?.user?.last_name,
    date_of_birth: selectedPatient?.date_of_birth,
    mobile_no: selectedPatient?.mobile_no,
    email: selectedPatient?.user?.email,
    disease: selectedPatient?.disease,
    occupation: selectedPatient?.occupation,
    marital_status: selectedPatient?.marital_status,
    gender: selectedPatient?.user?.gender,
    password: selectedPatient.password,
    blood_pressure: selectedPatient?.blood_pressure,
    sugar: selectedPatient?.sugar,
    blood_group: selectedPatient?.blood_group,
    height: selectedPatient?.height,
    address: selectedPatient?.address,
    city: selectedPatient?.city,
    state: selectedPatient?.state,
    postal_code: selectedPatient?.postal_code,
    profile_avatar: selectedPatient?.user?.profile_avatar,
    age: selectedPatient?.user?.age,

    name: selectedPatient?.name,
    relation: selectedPatient?.relation,
    guardian_email: selectedPatient?.guardian_email,
    contact_no: selectedPatient?.contact_no
  };

  const { errors, touched, values, handleChange, handleSubmit } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema:
      action === "add" ? NewValidationSchema : EditValidationSchema,
    onSubmit: (values) => {
      setLoading(true);
      const guardianDetails = {
        name: values.name || undefined, // Use undefined if not provided
        relation: values.relation || undefined, // Use undefined if not provided
        email: values.guardian_email || undefined, // Use undefined if not provided
        contact_no: values.contact_no || undefined // Use undefined if not provided
      };

      // Create guardians array only if at least one field has a value
      const guardians = Object.values(guardianDetails).some((value) => value)
        ? [guardianDetails]
        : [];

      if (action === "add") {
        AddPatient(
          {
            role: "Patient",
            first_name: values.first_name,
            last_name: values.last_name,
            date_of_birth: values.date_of_birth,
            mobile_no: values.mobile_no,
            email: values.email,
            password: values.password,
            age: values.age,
            occupation: values.occupation,
            marital_status: values.marital_status,
            gender: values.gender,
            patient_profile: {
              bp: values.blood_pressure,
              sugar: values.sugar,
              blood_group: values.blood_group,
              disease: values.disease,
              height: values.height,
              guardians: guardians
            },
            address: values.address,
            city: values.city,
            state: values.state,
            postal_code: values.postal_code,
            profile_avatar: upload.url ? upload.url : values.profile_avatar
          },
          handleClose
        );
      } else {
        EditPatient(
          {
            first_name: values.first_name,
            last_name: values.last_name,
            date_of_birth: values.date_of_birth,
            mobile_no: values.mobile_no,
            age: values.age,
            occupation: values.occupation,
            marital_status: values.marital_status,
            gender: values.gender,
            patient_profile: {
              bp: values.blood_pressure,
              sugar: values.sugar,
              blood_group: values.blood_group,
              disease: values.disease,
              height: values.height,
              guardians: guardians
            },
            address: values.address,
            city: values.city,
            state: values.state,
            postal_code: values.postal_code,
            profile_avatar: upload.url ? upload.url : values.profile_avatar,
            guardians: [
              {
                name: values.name,
                relation: values.relation,
                email: values.email,
                contact_no: values.contact_no
              }
            ]
          },
          selectedPatient?.user?.id,
          handleClose
        );
      }
    }
  });

  const handleClose = () => {
    setLoading(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedFileTypes = ["jpg", "png", "jpeg", "jfif"];
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const isValidFileType = allowedFileTypes.includes(fileExtension);

      if (!isValidFileType) {
        setUpload((prevState) => ({
          ...prevState,
          message: "Upload only image"
        }));
        return;
      }
      Upload({ image: file });
      setUpload((prevState) => ({
        ...prevState,
        message: null
      }));
    }
  };

  return (
    <>
      <AppHead title="Settings - Edit Profile - CHARITE" />
      <div className="p-10">
        <div className="flex gap-3 items-center">
          <div
            onClick={() => {
              Navigate("/patients");
            }}
            className="text-[14px] font-[400] text-[#788BA5] cursor-pointer hover:text-primary"
          >
            Patients
          </div>
          <MdOutlineChevronRight className="text-[#788BA5]" />
          {action === "add" ? (
            <p className="text-[14px] font-[400] text-[#3B82F6]">
              Add new Patient
            </p>
          ) : (
            <p className="text-[14px] font-[400] text-[#3B82F6]">
              Edit new Patient
            </p>
          )}
        </div>
        <h1 className="font-[700] text-[20px] font-roboto text-[#272727] leading-9">
          Patient Details
        </h1>
        <form onSubmit={handleSubmit} className="mt-2 bg-white p-5 rounded-lg">
          <div className="flex items-center gap-6">
            {upload.loading ? (
              <div className="w-[80px] h-[80px] bg-[#0000003d] rounded-full flex justify-center items-center">
                <ButtonLoader_1 />
              </div>
            ) : (
              <div className="relative">
                {upload.url ? (
                  <img
                    className="rounded-full w-[80px] h-[80px]"
                    src={upload.url}
                    style={{ aspectRatio: "1/1", objectFit: "cover" }}
                    alt=""
                  />
                ) : (
                  <>
                    {selectedPatient?.user?.profile_avatar && (
                      <img
                        className="rounded-full w-[80px] h-[80px]"
                        src={`${selectedPatient?.user?.profile_avatar}`}
                        alt=""
                      />
                    )}
                    {!selectedPatient?.user?.profile_avatar && (
                      <svg
                        className="w-[80px] h-[80px] text-gray-200 dark:text-gray-700"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                      </svg>
                    )}
                  </>
                )}
                <div className="flex items-center justify-center bg-primary text-white rounded-full p-1 border border-white absolute right-[0px] bottom-[0px]">
                  <label htmlFor="profile_avatar">
                    <RiEditFill className="edit cursor-pointer" />
                  </label>
                  <input
                    type="file"
                    id="profile_avatar"
                    name="profile_avatar"
                    accept=".jpg, .png, .jpeg, .jfif"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                </div>
              </div>
            )}
            <div className="flex flex-col gap-2">
              <h1 className="text-[#272727] text-[18px] font-[500] leading-5">
                Upload Your Picture
              </h1>
              <p className="text-[#788BA5] text-[12px] font-[400] leading-4">
                For Best Results, use an image at least 256px by 256px in
                <br />
                either .jpg or.png format
              </p>
              {upload.message && (
                <p className="text-red-700 text-xs">{upload.message}</p>
              )}
            </div>
          </div>
          {/* First Inputs Grid */}
          <div className="grid grid-cols-3 gap-4 w-full mt-6">
            <div className="w-full">
              <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                First Name
              </label>
              <input
                placeholder="First Name"
                type="text"
                name="first_name"
                value={values.first_name}
                onChange={handleChange}
                className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
              />
              {errors.first_name && touched.first_name && (
                <p className="text-red-700 text-xs mt-[2px]">
                  {errors.first_name}
                </p>
              )}
            </div>

            <div className="w-full">
              <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                Last Name
              </label>
              <input
                placeholder="Last Name"
                type="text"
                name="last_name"
                value={values.last_name}
                onChange={handleChange}
                className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
              />
              {errors.last_name && touched.last_name && (
                <p className="text-red-700 text-xs mt-[2px]">
                  {errors.last_name}
                </p>
              )}
            </div>

            <div className="w-full">
              <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                Date of Birth
              </label>
              <input
                placeholder="Monday, 19 August 1998"
                type="date"
                name="date_of_birth"
                value={values.date_of_birth}
                onChange={handleChange}
                className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
              />
              {errors.date_of_birth && touched.date_of_birth && (
                <p className="text-red-700 text-xs mt-[2px]">
                  {errors.date_of_birth}
                </p>
              )}
            </div>
          </div>

          <div
            className={`grid grid-cols-${
              action === "add" ? "2" : "1"
            } gap-4 w-full mt-6`}
          >
            <div className="w-full">
              <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                Mobile Number
              </label>
              <input
                placeholder="Mobile Number"
                type="number"
                name="mobile_no"
                value={values.mobile_no}
                onChange={handleChange}
                className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
              />
              {errors.mobile_no && touched.mobile_no && (
                <p className="text-red-700 text-xs mt-[2px]">
                  {errors.mobile_no}
                </p>
              )}
            </div>
            {action === "add" && (
              <div className="w-full">
                <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                  Email ID
                </label>
                <input
                  placeholder="Email"
                  type="text"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none"
                />
                {errors.email && touched.email && (
                  <p className="text-red-700 text-xs mt-[2px]">
                    {errors.email}
                  </p>
                )}
              </div>
            )}
          </div>
          {action === "add" && (
            <div className="grid grid-cols-2 gap-4 w-full mt-6 ">
              <div className="w-full">
                <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                  Password
                </label>
                <div className="relative mt-1">
                  <input
                    type={password ? "text" : "password"}
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    placeholder="Enter password"
                    className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
                  />

                  <div
                    className="h-full absolute top-0 right-3 cursor-pointer flex items-center justify-center text-center"
                    onClick={() => setPassword(!password)}
                  >
                    {password ? (
                      <BiShow className="text-xl text-[#95969D]" />
                    ) : (
                      <BiHide className="text-xl text-[#95969D]" />
                    )}
                  </div>
                </div>
                {errors.password && touched.password && (
                  <p className="text-red-700 text-xs ">{errors.password}</p>
                )}
              </div>
              <div className="w-full">
                <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                  Confirm Password
                </label>
                <div className="relative mt-1">
                  <input
                    type={confrimpassword ? "text" : "password"}
                    name="confirm_password"
                    value={values.confirm_password}
                    onChange={handleChange}
                    placeholder="Enter confirm password"
                    className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
                  />
                  <div
                    className="h-full absolute top-0 right-3 cursor-pointer flex items-center justify-center text-center"
                    onClick={() => {
                      setConfrimPassword(!confrimpassword);
                    }}
                  >
                    {confrimpassword ? (
                      <BiShow className="text-xl text-[#95969D]" />
                    ) : (
                      <BiHide className="text-xl text-[#95969D]" />
                    )}
                  </div>
                </div>
                {errors.confirm_password && touched.confirm_password && (
                  <p className="text-red-700 text-xs ">
                    {errors.confirm_password}
                  </p>
                )}
              </div>
            </div>
          )}

          <div className="grid grid-cols-4 gap-4 w-full mt-6">
            <div className="w-full">
              <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                Disease
              </label>
              <input
                placeholder="DHS"
                type="text"
                name="disease"
                value={values.disease}
                onChange={handleChange}
                maxLength={10}
                className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
              />
              {errors.disease && touched.disease && (
                <p className="text-red-700 text-xs mt-[2px]">
                  {errors.disease}
                </p>
              )}
            </div>
            <div className="w-full">
              <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                Occupation
              </label>
              <input
                placeholder="Teacher"
                type="text"
                name="occupation"
                value={values.occupation}
                onChange={handleChange}
                className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
              />
              {errors.occupation && touched.occupation && (
                <p className="text-red-700 text-xs mt-[2px]">
                  {errors.occupation}
                </p>
              )}
            </div>
            <div className="w-full">
              <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                Material Status
              </label>
              <select
                name="marital_status"
                values={values.marital_status}
                onChange={handleChange}
                className="border border-[#DEE4ED] w-full h-[36px] px-3 cursor-pointer text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
              >
                <option selected disabled>
                  Select Material Status
                </option>
                <option value="single">Single</option>
                <option value="married">Married</option>
              </select>
              {errors.marital_status && touched.marital_status && (
                <p className="text-red-700 text-xs mt-[2px]">
                  {errors.marital_status}
                </p>
              )}
            </div>
            <div className="w-full">
              <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                Gender
              </label>
              <div className="flex items-center gap-6 h-[36px] mt-1">
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    value="male"
                    name="gender"
                    values={values.gender}
                    checked={values.gender === "male"}
                    onChange={handleChange}
                  />
                  Male
                </div>
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    value="female"
                    name="gender"
                    values={values.gender}
                    checked={values.gender === "female"}
                    onChange={handleChange}
                  />
                  Female
                </div>
              </div>
              {errors.gender && touched.gender && (
                <p className="text-red-700 text-xs mt-[2px]">{errors.gender}</p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-4 gap-4 w-full mt-6">
            <div className="w-full">
              <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                Blood Pressure
              </label>
              <input
                placeholder="160"
                type="number"
                name="blood_pressure"
                value={values.blood_pressure}
                onChange={handleChange}
                className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
              />
            </div>
            <div className="w-full">
              <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                Sugar
              </label>
              <input
                placeholder="130"
                type="number"
                name="sugar"
                value={values.sugar}
                onChange={handleChange}
                className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
              />
            </div>

            <div className="w-full">
              <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                Blood Group
              </label>
              <select
                name="blood_group"
                values={values.blood_group}
                onChange={handleChange}
                className="border border-[#DEE4ED] w-full h-[36px] px-3 cursor-pointer text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
              >
                <option selected disabled>
                  Select Group
                </option>
                <option value="A +">A +</option>
                <option value="A -">A -</option>
                <option value="B +">B +</option>
                <option value="B -">B -</option>
                <option value="AB +">AB +</option>
                <option value="AB -">AB -</option>
                <option value="O +"> O +</option>
                <option value="O -">O -</option>
              </select>
            </div>
            <div className="w-full">
              <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                Height
              </label>
              <input
                placeholder="5.4"
                type="text"
                name="height"
                value={values.height}
                onChange={handleChange}
                maxLength={10}
                className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4 w-full mt-6">
            <div className="w-full">
              <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                Address
              </label>
              <input
                placeholder="Address"
                type="text"
                name="address"
                value={values.address}
                onChange={handleChange}
                className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
              />
            </div>
            <div className="w-full">
              <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                Age
              </label>
              <input
                placeholder="Age"
                type="number"
                name="age"
                value={values.age}
                onChange={handleChange}
                className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
              />
            </div>
          </div>

          <div className="grid grid-cols-3 gap-4 w-full mt-6">
            <div className="w-full">
              <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                State
              </label>
              <input
                placeholder="State"
                type="text"
                name="state"
                value={values.state}
                onChange={handleChange}
                className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
              />
              {errors.state && touched.state && (
                <p className="text-red-700 text-xs mt-[2px]">{errors.state}</p>
              )}
            </div>
            <div className="w-full">
              <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                City
              </label>
              <input
                placeholder="City"
                type="text"
                name="city"
                value={values.city}
                onChange={handleChange}
                className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
              />
              {errors.city && touched.city && (
                <p className="text-red-700 text-xs mt-[2px]">{errors.city}</p>
              )}
            </div>
            <div className="w-full">
              <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                Postal Code
              </label>
              <input
                placeholder="Postal Code"
                type="text"
                name="postal_code"
                value={values.postal_code}
                onChange={handleChange}
                maxLength={10}
                className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
              />
              {errors.postal_code && touched.postal_code && (
                <p className="text-red-700 text-xs mt-[2px]">
                  {errors.postal_code}
                </p>
              )}
            </div>
          </div>
          <h1 className="text-[#272727] text-[18px] font-[600] mt-6">
            Guardian Details
          </h1>
          <hr />
          <div className="grid grid-cols-2 gap-4 w-full mt-3">
            <div className="w-full">
              <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                Guardian Name
              </label>
              <input
                placeholder="Carole"
                type="text"
                name="name"
                value={values.name}
                onChange={handleChange}
                className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
              />
            </div>
            <div className="w-full">
              <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                Relation:
              </label>
              <select
                name="relation"
                values={values.relation}
                onChange={handleChange}
                className="border border-[#DEE4ED] w-full h-[36px] px-3 cursor-pointer text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
              >
                <option disabled selected>
                  Select Relation
                </option>
                <option value="brother">Brother</option>
                <option value="friend">Friend</option>
              </select>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 w-full mt-6">
            <div className="w-full">
              <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                Guardian Mobile
              </label>
              <input
                placeholder="123 456 789"
                type="number"
                name="contact_no"
                value={values.contact_no}
                onChange={handleChange}
                className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
              />
            </div>
            <div className="w-full">
              <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                Guardian Email:
              </label>
              <input
                placeholder="example@gmail.com"
                type="text"
                name="guardian_email"
                value={values.guardian_email}
                onChange={handleChange}
                className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
              />
            </div>
          </div>
          <div className="flex items-center gap-3 mt-5">
            <Link
              to="/patients"
              className="bg-transparent border border-[#D8E2EB] text-[#787878] w-[80px] h-[40px] flex justify-center items-center rounded-lg text-[14px]"
            >
              Cancel
            </Link>
            <button
              type="submit"
              className="bg-primary text-white w-[160px] h-[40px] flex justify-center items-center rounded-lg text-[14px]"
              disabled={loading}
            >
              {loading ? <ButtonLoader_1 /> : "Save Profile"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default PatientsForm;
