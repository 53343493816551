import { useState } from "react";
import axios from "axios";
import { getAccessToken } from "../storage/storage";

const useGetConsentForm = () => {
  const token = getAccessToken();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [consentForm, setConsentForm] = useState({
    loading: true,
    data: null,
    message: null,
    totalPages: 0,
    currentPage: 1
  });

  const GetConsentForm = async (url) => {
    await axios
      .get(`${BASE_URL}${url}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        setConsentForm((prevState) => ({
          ...prevState,
          loading: false,
          data: response.data,
          totalPages: response.data.total_pages,
          message:
            response.data?.results?.length === 0 ? response.data.message : null
        }));
      })
      .catch((error) => {
        setConsentForm((prevState) => ({
          ...prevState,
          loading: false,
          message: "Oops! Something went wrong"
        }));
      });
  };
  return {
    consentForm,
    setConsentForm,
    GetConsentForm
  };
};
export default useGetConsentForm;
