import React, { useState, useRef, useEffect } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { TiTick } from "react-icons/ti";

const Dropdown = ({ options, selected, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative mt-1" ref={dropdownRef}>
      <button
        type="button"
        className="w-full bg-white border border-[#E2E8F0] rounded-md pl-3 pr-10 py-2 text-left cursor-pointer focus:outline-none hover:border-secondary focus:border-secondary sm:text-sm"
        onClick={handleToggle}
      >
        <span className="text-[#718096]">{selected?.name}</span>
        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <MdKeyboardArrowDown className="text-[#718096] text-[22px]" />
        </span>
      </button>

      {isOpen && (
        <ul className="absolute z-10 mt-1 right-0 w-[180px] bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
          {options.map((option, index) => (
            <li
              key={index}
              className={`select-none relative py-2 pl-3 pr-9 ${
                option.value === selected.value
                  ? "text-secondary"
                  : "hover:bg-secondary hover:text-primary cursor-pointer"
              }`}
              onClick={() => handleOptionClick(option)}
            >
              {option.name}
              {option.value === selected.value && (
                <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-secondary">
                  <TiTick className="text-[18px]" />
                </span>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
