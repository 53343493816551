import React from "react";
import AppHead from "../../../seo/AppHead";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useGetPatients from "../../../hooks/useGetPatients";

function ViewTreatmentForm() {
  const location = useLocation();
  const { selectedPatient = {} } = location.state || {};
  const { patients, GetPatients } = useGetPatients();
  useEffect(() => {
    GetPatients(`/api/treatment-forms/${selectedPatient}/`);
  }, []);

  return (
    <>
      <AppHead title="Dashboard - CHARITE" />
      <div className="p-8">
        <div className="flex justify-between items-center mt-2">
          <h1 className="text-[#272727] text-[24px] font-[600] ">
            Treatment Form
          </h1>
        </div>
        {patients.loading ? (
          <div className="w-full h-[400px] rounded flex justify-center items-center bg-white flex-col">
            <h1 className="text-[18px] font-[400] ">Loading ... </h1>
          </div>
        ) : (
          <form className="bg-white p-8 mt-2 rounded w-full h-auto">
            <div className="w-full h-auto">
              {patients?.data?.categories?.map((category, categoryIndex) => (
                <div key={categoryIndex}>
                  <h3 className="text-[#272727] text-[20px] font-[600] ">
                    {category.category_name}
                  </h3>
                  {category?.questions?.map((question, questionIndex) => (
                    <div key={questionIndex} className="mt-4">
                      <label
                        htmlFor={`question_${categoryIndex}_${questionIndex}`}
                        className="block mb-2 text-[16px] text-[#272727] font-bold"
                      >
                        {question.question_statement}
                      </label>
                      {question.question_type.toUpperCase() === "DROPDOWN" && (
                        <input
                          className="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-[5px] border border-gray-300 outline-none focus:border-primary"
                          value={question?.answers[0]?.answer_text}
                        />
                      )}

                      {/* Change input field to textarea for TEXT type */}
                      {question.question_type.toUpperCase() === "TEXT" && (
                        <textarea
                          className="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-[5px] border border-gray-300 outline-none focus:border-primary"
                          value={question?.answers[0]?.answer_text}
                        />
                      )}
                      {question.question_type.toUpperCase() === "FILE" && (
                        <div className="w-full border-dashed border border-[#3B82F6] flex flex-col items-center  px-7 py-3 rounded-[5px] ">
                          <div className="">
                            {question?.answers[0]?.file_upload ? (
                              <img
                                className="w-[200px] h-[200px]"
                                src={question.answers[0].file_upload}
                                alt="Uploaded file"
                              />
                            ) : (
                              <p>No file uploaded</p>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </form>
        )}
      </div>
    </>
  );
}

export default ViewTreatmentForm;
