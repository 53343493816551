import React, { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

const AddMedicalQuestionModal = ({ isOpen, onClose, onSave }) => {
  const [newQuestion, setNewQuestion] = useState("");
  const [error, setError] = useState("");

  const handleSave = () => {
    if (newQuestion.trim()) {
      onSave(newQuestion);
      setNewQuestion("");
      setError("");
    } else {
      setError("Please enter a question");
    }
  };
  const handleInputChange = (e) => {
    setNewQuestion(e.target.value);
    if (error) {
      setError("");
    }
  };

  return (
    <Transition.Root show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full max-w-lg p-6">
              <h1 className="2xl:text-[24px] font-semibold lg:text-[20px]">
                Add New Question
              </h1>

              <input
                type="text"
                className="w-full mt-4 p-2 border-gray-300 rounded bg-transparent outline-none border"
                value={newQuestion}
                onChange={handleInputChange}
                placeholder="Enter question"
              />
              {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
              <div className="mt-6 flex justify-end">
                <button
                  className="bg-gray-200 w-[120px] py-2 rounded-md text-gray-800"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  className="bg-blue-500 text-white w-[120px] py-2 rounded-md ml-3"
                  onClick={handleSave}
                >
                  Save
                </button>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AddMedicalQuestionModal;
