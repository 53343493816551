import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Dialog, Transition } from "@headlessui/react";
import { CiSearch } from "react-icons/ci";
import ShowMessage from "../shared/ShowMessage";
import GlobalContext from "../../context/GlobalContext";
import { debounce } from "lodash";
import Paginations from "../shared/Paginations";
import { TableSkelton_4 } from "../shared/Skeltons";
import { ButtonLoader_1 } from "../shared/Loaders";
import useGetDoctors from "../../hooks/useGetDoctors";
import useAssignDoctorToPatient from "../../hooks/useAssignDoctorToPatient";

function ChooseDoctorModal(props) {
  const { AssignDoctor, loading } = useAssignDoctorToPatient();
  const [query, setQuery] = useState("");
  const { doctors, setDoctors, GetDoctors } = useGetDoctors();
  const { updateResponse } = useContext(GlobalContext);

  const debouncedSearch = useCallback(
    debounce((value) => {
      setDoctors((prevState) => ({ ...prevState, currentPage: 1 }));
      GetDoctors(
        `/api/patient/${props.selectedPatient?.id}/unassigned-doctors/?page=${doctors.currentPage}&search=${value}`
      );
    }, 1000),
    []
  );
  const handleChange = (e) => {
    const { value } = e.target;
    setQuery(value);
    debouncedSearch(value);
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  useEffect(() => {
    GetDoctors(
      `/api/patient/${props.selectedPatient?.id}/unassigned-doctors/?page=${doctors.currentPage}&search=${query}`
    );
  }, [doctors.currentPage, updateResponse]);
  const handlePageChange = (event, value) => {
    setDoctors((prevState) => ({ ...prevState, currentPage: value }));
  };
  const cancelButtonRef = useRef(null);
  const handleClose = () => {
    props.setIsOpenModal(false);
  };

  //   Assign  doctor patient to
  const [selectedDoctor, setSelectedDoctor] = useState([]);
  useEffect(() => {
    if (props.isOpenModal) {
      setSelectedDoctor([]);
    }
  }, [props.isOpenModal]);
  const handleCheckboxChange = (event, doctorId) => {
    if (event.target.checked) {
      setSelectedDoctor([...selectedDoctor, doctorId]);
    } else {
      setSelectedDoctor(selectedDoctor.filter((id) => id !== doctorId));
    }
  };
  const handleAssign = async () => {
    await AssignDoctor(
      {
        doctor_ids: selectedDoctor,
        patient_id: props.selectedPatient?.id
      },
      handleClose
    );
  };

  return (
    <Transition.Root show={props.isOpenModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 xs:p-1 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-[#fff] text-left shadow-xl transition-all py-4 px-6 2xl:w-[1200px] lg:w-[1000px] xs:w-full max-h-[100vh]">
                <div className="absolute top-[10px] right-[10px] cursor-pointer">
                  <AiOutlineClose onClick={handleClose} />
                </div>
                <div className="flex flex-col ">
                  <h1 className="2xl:text-[28px] font-[600] lg:text-[24px] font-roboto">
                    Choose Doctors
                  </h1>
                  <div className="border rounded-xl  flex items-center bg-white w-[100%] px-2 ">
                    <CiSearch className="text-[25px] text-[#788BA5]" />
                    <input
                      type="search"
                      value={query}
                      onChange={handleChange}
                      placeholder="Search by Patient Name or Email"
                      className="w-full p-2 outline-none border-none"
                    />
                  </div>
                  {doctors.loading ? (
                    <>
                      {Array.from({ length: 2 }).map((_, index) => (
                        <TableSkelton_4 key={index} />
                      ))}
                    </>
                  ) : (
                    <>
                      <div className="grid grid-cols-4 gap-4 w-full mb-4 mt-3 h-[350px] overflow-y-auto">
                        {doctors?.data?.results?.map((item, index) => (
                          <div
                            key={index}
                            className="p-4 bg-[#F6F8FB] rounded-xl h-[165px]"
                          >
                            <div className="flex justify-between items-center text-[#788BA5]">
                              <input
                                type="checkbox"
                                className="w-[20px] h-[20px] cursor-pointer"
                                onChange={(e) =>
                                  handleCheckboxChange(e, item?.id)
                                }
                              />
                            </div>
                            <div className="flex flex-col items-center justify-center">
                              <svg
                                className="w-[50px] h-[50px] rounded-full text-gray-200 dark:text-gray-700"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                              </svg>
                              <h1 className="text-[#272727] text-[18px] font-[600] font-roboto mt-1">
                                {item?.user?.first_name} {item?.user?.last_name}
                              </h1>
                              <p className="text-[#F6833B] text-[10px] font-[500] font-roboto bg-red-100 py-1 px-3 rounded mt-1">
                                {item?.department}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className="w-full flex justify-between items-center">
                        <p className="text-[#272727] font-roboto text-[14px] font-[400] leading-5">
                          {doctors?.data?.results?.length > 0 &&
                            doctors?.data?.display_string}
                        </p>
                        <Paginations
                          totalPages={doctors.totalPages}
                          currentPage={doctors.currentPage}
                          handlePageChange={handlePageChange}
                        />
                      </div>
                      {!doctors.loading && doctors.message && (
                        <div className="bg-white flex justify-center items-center w-full h-[300px]">
                          <ShowMessage message={doctors.message} />
                        </div>
                      )}
                      <hr className="mt-2" />
                      <div className="flex items-center justify-end gap-2 mt-2">
                        <button
                          className="bg-gray-200 w-[120px] h-[40px] rounded-[7px] outline-none border-none"
                          onClick={handleClose}
                        >
                          Cancel
                        </button>
                        <button
                          onClick={handleAssign}
                          disabled={selectedDoctor?.length === 0 || loading}
                          className={`flex justify-center items-center h-[40px] w-[150px] rounded-[7px] text-white outline-none border-none text-[15px] ${
                            selectedDoctor?.length === 0 || loading
                              ? "bg-gray-400 cursor-not-allowed"
                              : "bg-primary"
                          }`}
                        >
                          {loading ? <ButtonLoader_1 /> : "Assign to Patient"}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
export default ChooseDoctorModal;
