import { useContext, useState } from "react";
import axios from "axios";
import { getAccessToken } from "../storage/storage";
import GlobalContext from "../context/GlobalContext";

const useGetPatients = () => {
  const { setUpdateResponse } = useContext(GlobalContext);

  const token = getAccessToken();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [patients, setPatients] = useState({
    loading: true,
    data: null,
    message: null,
    totalPages: 0,
    currentPage: 1
  });

  const GetPatients = async (url) => {
    await axios
      .get(`${BASE_URL}${url}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        setUpdateResponse(false);
        setPatients((prevState) => ({
          ...prevState,
          loading: false,
          data: response.data,
          totalPages: response.data.total_pages,
          message: response.data.results?.length === 0 ? "Data not found" : null
        }));
      })
      .catch((error) => {
        setPatients((prevState) => ({
          ...prevState,
          loading: false,
          message: "Oops! Something went wrong"
        }));
      });
  };
  return {
    patients,
    setPatients,
    GetPatients
  };
};
export default useGetPatients;
