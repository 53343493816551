import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
const useForgotPassword = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);

  const ForgotPassword = async (payload) => {
    setLoading(true);
    await axios
      .post(`${BASE_URL}/api/password/reset-request/`, payload)
      .then((response) => {
        setLoading(false);
        Cookies.set("email", payload.email, { expires: 1 });
        Swal.fire({
          icon: "success",
          text: "Password reset link sent to your email.Please check your email!",
          customClass: {
            confirmButton: "custom-green-button"
          }
        });
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          text:
            error?.response?.data?.message ||
            "Oops! Something went wrong on our end. Please give us a moment to fix it. Feel free to try again later",
          customClass: {
            confirmButton: "custom-red-button"
          }
        });
      });
  };

  return { loading, ForgotPassword };
};

export default useForgotPassword;
