/* eslint-disable react/jsx-pascal-case */
import React, { useState } from "react";
import AppHead from "../../seo/AppHead";
import { BiHide, BiShow } from "react-icons/bi";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import useChangePassword from "../../hooks/useChangePassword";
import { ButtonLoader_1 } from "../../components/shared/Loaders";

const ChangePasswordValidationSchema = Yup.object().shape({
  old_password: Yup.string().required("Old Password is required"),
  password: Yup.string()
    .required("New password is required")
    .min(6, "Password length must be at least 6 characters long!")
    .matches(
      /^(?=.*[A-Za-z])(?=.*[@$!%*#?&]).*$/,
      "Password must contain at least one alphabetic character and one special character"
    )
    .max(20, "password must not exceed 20 characters"),
  confirm_password: Yup.string()
    .required("Confirm new password is required")
    .oneOf([Yup.ref("password"), null], "Password must match"),
});

function ChangePasswordPage() {
  const [oldPassowrdVisible, setOldPassowrdVisible] = useState(false);
  const [passowrdVisible, setPassowrdVisible] = useState(false);
  const [confirmPassowrdVisible, setConfirmPassowrdVisible] = useState(false);
  const { loading, ChangePassword } = useChangePassword();

  const initialValues = {
    old_password: "",
    password: "",
    confirm_password: "",
  };
  const { values, errors, handleChange, handleSubmit, touched } = useFormik({
    initialValues: initialValues,
    validationSchema: ChangePasswordValidationSchema,
    onSubmit: async (values, action) => {
      await ChangePassword(
        {
          old_password: values.old_password,
          new_password: values.password,
          confirm_password: values.confirm_password,
        },
        action
      );
    },
  });
  return (
    <>
      <AppHead title="Settings - Change Password - CHARITE" />
      <div className="p-10">
        <h1 className="font-[700] text-[20px] font-roboto text-[#272727] leading-9">
          Change Password
        </h1>
        <form onSubmit={handleSubmit} className="mt-2 bg-white p-5 rounded-lg">
          <div className="w-full">
            <label className="text-[#191D23] text-[15px] leading-5 font-[400]">
              Current Password
            </label>
            <div className="relative mt-1">
              <input
                placeholder="Old password"
                type={oldPassowrdVisible ? "text" : "password"}
                name="old_password"
                value={values.old_password}
                onChange={handleChange}
                className="border border-[#DEE4ED] w-full h-[46px] px-4 text-[#5A5A5A] bg-transparent rounded-lg outline-none hover:border-primary focus:border-primary"
              />
              <div
                className="h-full absolute top-0 right-3 cursor-pointer flex items-center justify-center text-center"
                onClick={() => setOldPassowrdVisible(!oldPassowrdVisible)}
              >
                {oldPassowrdVisible ? (
                  <BiShow className="text-2xl text-[#95969D]" />
                ) : (
                  <BiHide className="text-2xl text-[#95969D]" />
                )}
              </div>
            </div>
            {errors.old_password && touched.old_password && (
              <p className="text-red-700 text-xs mt-[2px]">
                {errors.old_password}
              </p>
            )}
          </div>
          <div className="w-full mt-3">
            <label className="text-[#191D23] text-[15px] leading-5 font-[400]">
              New Password
            </label>
            <div className="relative mt-1">
              <input
                placeholder="New password"
                type={passowrdVisible ? "text" : "password"}
                name="password"
                value={values.password}
                onChange={handleChange}
                className="border border-[#DEE4ED] w-full h-[46px] px-4 text-[#5A5A5A] bg-transparent rounded-lg outline-none hover:border-primary focus:border-primary"
              />
              <div
                className="h-full absolute top-0 right-3 cursor-pointer flex items-center justify-center text-center"
                onClick={() => setPassowrdVisible(!oldPassowrdVisible)}
              >
                {passowrdVisible ? (
                  <BiShow className="text-2xl text-[#95969D]" />
                ) : (
                  <BiHide className="text-2xl text-[#95969D]" />
                )}
              </div>
            </div>
            {errors.password && touched.password && (
              <p className="text-red-700 text-xs mt-[2px]">{errors.password}</p>
            )}
          </div>
          <div className="w-full mt-3">
            <label className="text-[#191D23] text-[15px] leading-5 font-[400]">
              Confirm New Password
            </label>
            <div className="relative mt-1">
              <input
                placeholder="Re-type new password"
                type={confirmPassowrdVisible ? "text" : "password"}
                name="confirm_password"
                value={values.confirm_password}
                onChange={handleChange}
                className="border border-[#DEE4ED] w-full h-[46px] px-4 text-[#5A5A5A] bg-transparent rounded-lg outline-none hover:border-primary focus:border-primary"
              />
              <div
                className="h-full absolute top-0 right-3 cursor-pointer flex items-center justify-center text-center"
                onClick={() =>
                  setConfirmPassowrdVisible(!confirmPassowrdVisible)
                }
              >
                {confirmPassowrdVisible ? (
                  <BiShow className="text-2xl text-[#95969D]" />
                ) : (
                  <BiHide className="text-2xl text-[#95969D]" />
                )}
              </div>
            </div>
            {errors.confirm_password && touched.confirm_password && (
              <p className="text-red-700 text-xs mt-[2px]">
                {errors.confirm_password}
              </p>
            )}
          </div>
          <div className="flex items-center gap-3 mt-5">
            <button
              type="submit"
              className="bg-primary text-white w-[160px] h-[40px] flex justify-center items-center rounded-lg text-[14px]"
              disabled={loading}
            >
              {loading ? <ButtonLoader_1 /> : "Save Changes"}
            </button>
            <Link
              to="/settings"
              className="bg-transparent border border-[#D8E2EB] text-[#787878] w-[80px] h-[40px] flex justify-center items-center rounded-lg text-[14px]"
            >
              Cancel
            </Link>
          </div>
        </form>
      </div>
    </>
  );
}

export default ChangePasswordPage;
