import "./App.css";
import "./styles/globals.css";
import { GlobalProvider } from "./context/GlobalContext";
import Routing from "./routing/Routing";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { PrimeReactProvider } from "primereact/api";

function App() {
  return (
    <>
      <PrimeReactProvider>
        <GlobalProvider>
          <Routing />
        </GlobalProvider>
      </PrimeReactProvider>
    </>
  );
}

export default App;
