import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import AppHead from "../../seo/AppHead";

function BeforeLogin() {
  return (
    <>
      <AppHead title="Sign In - CHARITE" />
      <div className="grid grid-cols-2 w-full h-[100vh]">
        <LeftSide />
        <RightSide />
      </div>
    </>
  );
}

export default BeforeLogin;

function LeftSide() {
  return (
    <div className="w-full h-full bg-[#709ACF] flex flex-col gap-12 justify-center items-center">
      <img
        src="/assets/images/login_image.png"
        alt=""
        className="w-[250px] 2xl:w-auto"
      />
      <div className="flex flex-col gap-1 items-center justify-center text-white">
        <h1 className="text-[32px] font-[600]">
          Welcome to Your Health Journey
        </h1>
        <p className="text-center text-[14px] font-[400]">
          Our portal provides a seamless way for doctors and patients to <br />{" "}
          connect and manage healthcare needs efficiently.
        </p>
      </div>
    </div>
  );
}

const validationSchema = Yup.object({
  role: Yup.string().required("Please select a role.")
});

function RightSide() {
  const Navigate = useNavigate();
  const initialValues = {
    role: ""
  };
  const { values, errors, handleChange, handleSubmit, touched } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      Navigate(`/login/${values.role}`);
    }
  });

  const handleDivClick = (role) => {
    handleChange({ target: { name: "role", value: role } });
  };
  return (
    <div className="w-full h-full bg-white flex flex-col gap-6 justify-center items-center">
      <img src="/assets/logo/logo.png" alt="CHARITE" />
      <form
        className="w-[70%] border border-[#DEE4ED] rounded-[6px] py-8 px-8 flex flex-col"
        style={{ boxShadow: "rgba(21, 29, 40, 0.04)" }}
        onSubmit={handleSubmit}
      >
        <div
          className={`w-full h-[140px] border border-[#DEE4ED] rounded-[6px] py-3 px-3 relative hover:border-primary hover:bg-[#3b83f609] cursor-pointer ${
            values.role === "doctor" && "bg-[#3b83f609] border-primary"
          }`}
          onClick={() => handleDivClick("doctor")}
        >
          <input
            type="radio"
            name="role"
            value="doctor"
            checked={values.role === "doctor"}
            onChange={handleChange}
            className="absolute top-3 right-3 w-[15px] h-[15px] rounded-[3px] border border-[#D1D1D6] cursor-pointer"
          />
          <img src="/assets/icons/doctor.png" alt="Doctor" />
          <h1 className="font-[600] text-[18px] text-[#272727] mt-3 mb-1">
            Sign In as a Doctor
          </h1>
          <p className="font-[400] text-[12px] text-[#788BA5]">
            Be a part of our medical community, manage patient records, and
            provide expert care.
          </p>
        </div>
        {errors.role && touched.role && (
          <p className="text-red-700 text-xs mt-1">{errors.role}</p>
        )}
        <div
          className={`w-full h-[140px] border border-[#DEE4ED] rounded-[6px] py-3 px-3 relative hover:border-primary hover:bg-[#3b83f609] mt-4 cursor-pointer ${
            values.role === "patient" && "bg-[#3b83f609] border-primary"
          }`}
          onClick={() => handleDivClick("patient")}
        >
          <input
            type="radio"
            name="role"
            value="patient"
            checked={values.role === "patient"}
            onChange={handleChange}
            className="absolute top-3 right-3 w-[15px] h-[15px] rounded-[3px] border border-[#D1D1D6] cursor-pointer"
          />
          <img src="/assets/icons/patient.png" alt="Patient" />
          <h1 className="font-[600] text-[18px] text-[#272727] mt-3 mb-1">
            Sign In as a Patient
          </h1>
          <p className="font-[400] text-[12px] text-[#788BA5]">
            Manage your health records, consult with doctors, and get
            personalized care.
          </p>
        </div>
        {errors.role && touched.role && (
          <p className="text-red-700 text-xs mt-1">{errors.role}</p>
        )}
        <button
          className={`w-full h-[40px] rounded-[10px] mt-5 ${
            values.role ? "bg-primary text-white" : "bg-gray-200 text-gray-500"
          }`}
          type="submit"
          disabled={!values.role}
        >
          Sign In
        </button>
      </form>
    </div>
  );
}
