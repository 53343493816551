import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
const useResetPassword = () => {
  const Navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);

  const ResetPasswords = async (payload) => {
    setLoading(true);
    await axios
      .post(`${BASE_URL}/api/password-reset/`, payload)
      .then((response) => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          text: "Password reset successfully! You can login now",
          customClass: {
            confirmButton: "custom-green-button"
          }
        }).then(() => {
          Navigate("/login");
        });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        Swal.fire({
          icon: "error",
          text:
            error?.response?.data?.message ||
            "Oops! Something went wrong on our end. Please give us a moment to fix it. Feel free to try again later",
          customClass: {
            confirmButton: "custom-red-button"
          }
        });
      });
  };

  return { loading, ResetPasswords };
};

export default useResetPassword;
