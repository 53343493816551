import React, { useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import Swal from "sweetalert2";
import axios from "axios";
import { getAccessToken } from "../../storage/storage";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ButtonLoader_1 } from "../../components/shared/Loaders";

function ConsentForm() {
  const token = getAccessToken();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const location = useLocation();
  const navigate = useNavigate();
  const sigCanvas = useRef({});
  const [loading, setLoading] = useState(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [patientSignatureImage, setPatientSignatureImage] = useState(null);
  const searchParams = new URLSearchParams(location.search);
  const consentToken = searchParams.get("token");
  const { formId } = useParams();
  const [consentForm, setConsentForm] = useState({
    loading: true,
    data: null,
    message: null
  });

  const saveSignature = () => {
    const canvas = sigCanvas.current.getTrimmedCanvas();
    canvas.toBlob((blob) => {
      const formData = new FormData();
      formData.append("image", blob, "signature.png");

      axios
        .post(`${BASE_URL}/api/upload-image/`, formData, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((response) => {
          setPatientSignatureImage(response?.data?.image_url);
          Swal.fire({
            icon: "success",
            text: "Signature Saved Successfully!",
            customClass: {
              confirmButton: "custom-green-button"
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            text: "Oops! Something went wrong on our end. Please give us a moment to fix it. Feel free to try again later"
          });
        });
    });
  };
  const handleEndSignature = () => {
    setIsSaveEnabled(true);
  };
  const clearSignature = () => {
    sigCanvas.current.clear();
    setIsSaveEnabled(false);
  };

  const GetConsentFormTemplate = async () => {
    setConsentForm((prevState) => ({
      ...prevState,
      loading: true,
      message: null
    }));
    await axios
      .get(`${BASE_URL}/api/consent-forms/${formId}/`)
      .then((response) => {
        setConsentForm((prevState) => ({
          ...prevState,
          loading: false,
          data: response.data,
          message:
            response.data?.results?.length === 0 ? "Data not found" : null
        }));
      })
      .catch((error) => {
        setConsentForm((prevState) => ({
          ...prevState,
          loading: false,
          message: "Oops! Something went wrong"
        }));
      });
  };
  useEffect(() => {
    GetConsentFormTemplate();
  }, []);

  // post form after fill
  const handleSubmit = async () => {
    setLoading(true);
    const payload = {
      patient_signature_url: patientSignatureImage
    };
    await axios
      .put(
        `${BASE_URL}/api/consent-form/${consentForm?.data?.id}/update/?token=${consentToken}`,
        payload
      )
      .then((response) => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          text: response.data?.message,
          customClass: {
            confirmButton: "custom-green-button"
          }
        }).then(() => {
          navigate("/");
        });
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          text:
            error?.response?.data?.message ||
            "Oops! Something went wrong on our end. Please give us a moment to fix it. Feel free to try again later"
        });
      });
  };

  return (
    <div className="p-8">
      <div className="flex justify-between items-center mb-2">
        <h1 className="text-[#272727] text-[24px] font-[600]">Consent form</h1>
      </div>
      <hr />
      <div className="py-3">
        <h1 className="text-[24px] font-[600] text-[#272727]">
          Aufklärungs-, Anamnese- und Einwilligungsbogen
        </h1>
        <p className="text-[18px] font-[500] text-[#787878]">
          ADHS/ADS-Therapie mit Methylphenidat (Ritalin®) und anderen
          Medikamenten
        </p>
        <p className="text-[12px] font-[400] text-[#787878] mt-2">
          Bitte vor dem Arztgespräch sorgfältig lesen, Fragen notieren
        </p>
      </div>

      {consentForm.loading ? (
        <div className="w-full h-[400px] rounded flex justify-center items-center bg-white flex-col">
          <h1 className="text-[18px] font-[400] ">Loading ... </h1>
        </div>
      ) : (
        <>
          <div className="bg-white p-4 shadow-md rounded-lg">
            {consentForm?.data?.categories?.map((category, categoryIndex) => (
              <div key={categoryIndex}>
                {category.category_name.trim() === "Insurance Information" && (
                  <>
                    <h2 className="text-2xl font-semibold mb-4 w-full">
                      {category.category_name}
                    </h2>
                    <div className="grid grid-cols-2 gap-4">
                      {category.questions?.map((question, questionIndex) => (
                        <div
                          key={`${categoryIndex}-${questionIndex}`}
                          className="w-full"
                        >
                          <div className="flex flex-col items-center w-full">
                            <p className="font-[500] text-[16px] text-[#272727] w-[100%]">
                              {question.question_statement}:
                            </p>
                            <p
                              className={`text-[#787878] font-[400] text-[14px] w-[100%] p-2 rounded ${
                                category.category_name ===
                                "Sehr geehrte Patientin, sehr geehrter Patient"
                                  ? "bg-gray-300"
                                  : "bg-[#FAFAFA] "
                              }`}
                            >
                              {question.text_answers?.[0]?.answer_text || "N/A"}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
                {category.category_name.trim() ===
                  "Sehr geehrte Patientin, sehr geehrter Patient" && (
                  <>
                    <h2 className="text-2xl font-semibold my-4 w-full">
                      {category.category_name}
                    </h2>
                    {category.questions.map((question, questionIndex) => (
                      <div
                        key={`${categoryIndex}-${questionIndex}`}
                        className="mb-4"
                      >
                        {questionIndex !== 1 && (
                          <h3 className="text-[18px] font-[600] text-[#272727] mb-2 ">
                            {question.question_statement}
                          </h3>
                        )}
                        <ul className="list-disc list-inside">
                          {question.text_answers?.map((answer, answerIndex) => (
                            <li
                              key={`${questionIndex}-${answerIndex}`}
                              className="p-2 rounded text-[16px] font-[400] text-[#788BA5] bg-[#FAFAFA] "
                            >
                              {answer.answer_text}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </>
                )}
                {category.category_name.trim() ===
                  "Bitte folgendes mitbringen:" && (
                  <>
                    <h2 className="text-2xl font-semibold mb-4">
                      {category.category_name}
                    </h2>
                    {category.questions.map((question, questionIndex) => (
                      <div
                        key={`${categoryIndex}-${questionIndex}`}
                        className="mb-4 bg-[#FAFAFA] p-3 rounded "
                      >
                        <h3 className="text-[16px] font-[400] text-[#788BA5] mb-2 pb-2">
                          {question.question_statement}
                        </h3>
                        <hr />
                        <ul className="list-disc list-inside ml-4">
                          {question.options.map((option, optionIndex) => {
                            const isChecked = question.mcq_answers.some(
                              (answer) =>
                                answer.selected_option.trim() ===
                                option.option_text.trim()
                            );

                            return (
                              <li className="flex gap-3 my-5" key={optionIndex}>
                                <input
                                  type="checkbox"
                                  name={`question-${categoryIndex}-${questionIndex}`}
                                  className="w-[25px] h-[25px]"
                                  value={option.option_text}
                                  checked={isChecked}
                                  readOnly
                                />
                                <p className="text-[16px] font-[400] text-[#788BA5] w-[95%]">
                                  {option.option_text}
                                </p>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    ))}
                  </>
                )}
                {category.category_name.trim() === "Hinweise /Ergänzungen" && (
                  <div className="mb-4">
                    {category.questions.map((question, questionIndex) => (
                      <div
                        key={`${categoryIndex}-${questionIndex}`}
                        className="mb-2"
                      >
                        <p className="text-[18px] font-[600] text-[#272727] mb-2">
                          {question.question_statement}
                        </p>
                        <textarea
                          className={`p-2 rounded text-[16px] font-[400] text-[#788BA5] border mb-2 w-full outline-none h-[130px]`}
                          placeholder={
                            question.text_answers?.length > 0
                              ? question.text_answers
                                  .map((answer) => answer.answer_text)
                                  .join("\n")
                              : "Enter your answer here..."
                          }
                          onChange={(e) => {}}
                        />
                      </div>
                    ))}
                  </div>
                )}
                {category.category_name.trim() ===
                  "Fragen an Ihre Ärztin/Ihren Arzt:" && (
                  <div className="mb-4">
                    {category.questions.map((question, questionIndex) => (
                      <div
                        key={`${categoryIndex}-${questionIndex}`}
                        className="mb-2"
                      >
                        <p className="text-[18px] font-[600] text-[#272727] mb-2">
                          {question.question_statement}
                        </p>
                        <textarea
                          className={`p-2 rounded text-[14px] font-[400] text-[#788BA5] border mb-2 w-full outline-none h-[130px]`}
                          placeholder={
                            question.text_answers?.length > 0
                              ? question.text_answers
                                  .map((answer) => answer.answer_text)
                                  .join("\n")
                              : "Enter your answer here..."
                          }
                          onChange={(e) => {}}
                        />
                      </div>
                    ))}
                  </div>
                )}
                {category.category_name.trim() === "Patientin / Patient" && (
                  <>
                    <h2 className="text-2xl font-semibold mb-4">
                      {category.category_name}
                    </h2>
                    <div className=" grid grid-cols-4 gap-2">
                      {category.questions.map((question, questionIndex) => (
                        <div
                          key={`${categoryIndex}-${questionIndex}`}
                          className={`${
                            questionIndex < 4
                              ? "col-span-1"
                              : "col-span-4 sm:col-span-1"
                          } `}
                        >
                          <p className="text-[14px] font-[400] text-[#787878] mb-2">
                            {question.question_statement}
                          </p>
                          <input
                            className="p-2 rounded text-[14px] font-[400] text-[#788BA5] border mb-2 w-full outline-none"
                            placeholder={
                              question.text_answers?.length > 0
                                ? question.text_answers
                                    .map((answer) => answer.answer_text)
                                    .join("\n")
                                : "Enter your answer here..."
                            }
                            onChange={(e) => {}}
                          />
                        </div>
                      ))}
                    </div>
                  </>
                )}
                {category.category_name.trim() === "Allergien" && (
                  <div className="mb-4 border rounded" key={categoryIndex}>
                    <div className="bg-[#FAFAFA] p-3 flex justify-between items-center">
                      <h2 className="text-[18px] font-[600] ">
                        {category.category_name}
                      </h2>
                      <div>
                        {category.questions
                          .slice(0, 1)
                          .map((question, questionIndex) => (
                            <ul
                              className="list-disc list-inside flex w-[100px] justify-between"
                              key={questionIndex}
                            >
                              {question.options.map((option, optionIndex) => (
                                <div
                                  key={optionIndex}
                                  className="text-[14px] font-[400] text-[#788BA5] w-[25px]"
                                >
                                  {option.option_text}
                                </div>
                              ))}
                            </ul>
                          ))}
                      </div>
                    </div>
                    <div className="px-3">
                      {category.questions.map((question, questionIndex) => (
                        <div
                          key={`${categoryIndex}-${questionIndex}`}
                          className="flex justify-between py-3"
                        >
                          <p className="text-[16px] font-[400] text-[#788BA5] mb-2 w-[87%]">
                            {question?.question_statement}
                          </p>
                          <div className="flex w-[100px] justify-between">
                            {question.options.map((option, optionIndex) => {
                              const isChecked = question.mcq_answers.some(
                                (answer) =>
                                  answer.selected_option.trim() ===
                                  option.option_text.trim()
                              );

                              return (
                                <label
                                  key={optionIndex}
                                  className="flex items-center"
                                >
                                  <input
                                    type="checkbox"
                                    name={`question-${categoryIndex}-${questionIndex}`}
                                    className="w-[25px] h-[25px]"
                                    value={option.option_text}
                                    checked={isChecked}
                                    readOnly
                                  />
                                </label>
                              );
                            })}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {category.category_name.trim() ===
                  "Augenerkrankungen/-verletzungen?" && (
                  <div className="mb-4 border rounded">
                    <div className="bg-[#FAFAFA] p-3 flex justify-between items-center">
                      <h2 className="text-[18px] font-[600] ">
                        {category.category_name}
                      </h2>
                      <div className="  ">
                        {category.questions
                          .slice(0, 1)
                          .map((question, questionIndex) => (
                            <ul
                              className="list-disc list-inside flex w-[100px] justify-between ite "
                              key={questionIndex}
                            >
                              {question.options.map((option, optionIndex) => (
                                <div
                                  key={optionIndex}
                                  className="text-[14px] font-[400] text-[#788BA5] w-[25px]"
                                >
                                  {option.option_text}
                                </div>
                              ))}
                            </ul>
                          ))}
                      </div>
                    </div>
                    <div className="px-3">
                      {category.questions.map((question, questionIndex) => (
                        <div
                          key={`${categoryIndex}-${questionIndex}`}
                          className="flex justify-between  py-3"
                        >
                          <p className="text-[16px] font-[400] text-[#788BA5] mb-2 w-[87%]">
                            {question.question_statement}
                          </p>
                          <div className="flex w-[100px] justify-between">
                            {question.options.map((option, optionIndex) => {
                              const isChecked = question.mcq_answers.some(
                                (answer) =>
                                  answer.selected_option.trim() ===
                                  option.option_text.trim()
                              );

                              return (
                                <label
                                  key={optionIndex}
                                  className="flex items-center"
                                >
                                  <input
                                    type="checkbox"
                                    name={`question-${categoryIndex}-${questionIndex}`}
                                    className="w-[25px] h-[25px]"
                                    value={option.option_text}
                                    checked={isChecked}
                                    readOnly
                                  />
                                </label>
                              );
                            })}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {category.category_name.trim() ===
                  "Bindegewebserkrankungen?" && (
                  <div className="mb-4 border rounded">
                    <div className="bg-[#FAFAFA] p-3 flex justify-between items-center">
                      <h2 className="text-[18px] font-[600] ">
                        {category.category_name}
                      </h2>
                      <div className="  ">
                        {category.questions
                          .slice(0, 1)
                          .map((question, questionIndex) => (
                            <ul
                              className="list-disc list-inside flex w-[100px] justify-between ite "
                              key={questionIndex}
                            >
                              {question.options.map((option, optionIndex) => (
                                <div
                                  key={optionIndex}
                                  className="text-[14px] font-[400] text-[#788BA5] w-[25px]"
                                >
                                  {option.option_text}
                                </div>
                              ))}
                            </ul>
                          ))}
                      </div>
                    </div>
                    <div className="px-3">
                      {category.questions.map((question, questionIndex) => (
                        <div
                          key={`${categoryIndex}-${questionIndex}`}
                          className="flex justify-between  py-3"
                        >
                          <p className="text-[16px] font-[400] text-[#788BA5] mb-2 w-[87%]">
                            {question.question_statement}
                          </p>
                          <div className="flex w-[100px] justify-between">
                            {question.options.map((option, optionIndex) => {
                              const isChecked = question.mcq_answers.some(
                                (answer) =>
                                  answer.selected_option.trim() ===
                                  option.option_text.trim()
                              );

                              return (
                                <label
                                  key={optionIndex}
                                  className="flex items-center"
                                >
                                  <input
                                    type="checkbox"
                                    name={`question-${categoryIndex}-${questionIndex}`}
                                    className="w-[25px] h-[25px]"
                                    value={option.option_text}
                                    checked={isChecked}
                                    readOnly
                                  />
                                </label>
                              );
                            })}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {category.category_name.trim() ===
                  "Herz-/Kreislauf-Erkrankungen?" && (
                  <div className="mb-4 border rounded">
                    <div className="bg-[#FAFAFA] p-3 flex justify-between items-center">
                      <h2 className="text-[18px] font-[600] ">
                        {category.category_name}
                      </h2>
                      <div className="  ">
                        {category.questions
                          .slice(0, 1)
                          .map((question, questionIndex) => (
                            <ul
                              className="list-disc list-inside flex w-[100px] justify-between ite "
                              key={questionIndex}
                            >
                              {question.options.map((option, optionIndex) => (
                                <div
                                  key={optionIndex}
                                  className="text-[14px] font-[400] text-[#788BA5] w-[25px]"
                                >
                                  {option.option_text}
                                </div>
                              ))}
                            </ul>
                          ))}
                      </div>
                    </div>
                    <div className="px-3">
                      {category.questions.map((question, questionIndex) => (
                        <div
                          key={`${categoryIndex}-${questionIndex}`}
                          className="flex justify-between  py-3"
                        >
                          <p className="text-[16px] font-[400] text-[#788BA5] mb-2 w-[87%]">
                            {question.question_statement}
                          </p>
                          <div className="flex w-[100px] justify-between">
                            {question.options.map((option, optionIndex) => {
                              const isChecked = question.mcq_answers.some(
                                (answer) =>
                                  answer.selected_option.trim() ===
                                  option.option_text.trim()
                              );

                              return (
                                <label
                                  key={optionIndex}
                                  className="flex items-center"
                                >
                                  <input
                                    type="checkbox"
                                    name={`question-${categoryIndex}-${questionIndex}`}
                                    className="w-[25px] h-[25px]"
                                    value={option.option_text}
                                    checked={isChecked}
                                    readOnly
                                  />
                                </label>
                              );
                            })}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {category.category_name.trim() === "Gerinnungsstörungen?" && (
                  <div className="mb-4 border rounded">
                    <div className="bg-[#FAFAFA] p-3 flex justify-between items-center">
                      <h2 className="text-[18px] font-[600] ">
                        {category.category_name}
                      </h2>
                      <div className="  ">
                        {category.questions
                          .slice(0, 1)
                          .map((question, questionIndex) => (
                            <ul
                              className="list-disc list-inside flex w-[100px] justify-between ite "
                              key={questionIndex}
                            >
                              {question.options.map((option, optionIndex) => (
                                <div
                                  key={optionIndex}
                                  className="text-[14px] font-[400] text-[#788BA5] w-[25px]"
                                >
                                  {option.option_text}
                                </div>
                              ))}
                            </ul>
                          ))}
                      </div>
                    </div>
                    <div className="px-3">
                      {category.questions.map((question, questionIndex) => (
                        <div
                          key={`${categoryIndex}-${questionIndex}`}
                          className="flex justify-between  py-3"
                        >
                          <p className="text-[16px] font-[400] text-[#788BA5] mb-2 w-[87%]">
                            {question.question_statement}
                          </p>
                          <div className="flex w-[100px] justify-between">
                            {question.options.map((option, optionIndex) => {
                              const isChecked = question.mcq_answers.some(
                                (answer) =>
                                  answer.selected_option.trim() ===
                                  option.option_text.trim()
                              );

                              return (
                                <label
                                  key={optionIndex}
                                  className="flex items-center"
                                >
                                  <input
                                    type="checkbox"
                                    name={`question-${categoryIndex}-${questionIndex}`}
                                    className="w-[25px] h-[25px]"
                                    value={option.option_text}
                                    checked={isChecked}
                                    readOnly
                                  />
                                </label>
                              );
                            })}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {category.category_name.trim() === "Infektionskrankheiten?" && (
                  <div className="mb-4 border rounded">
                    <div className="bg-[#FAFAFA] p-3 flex justify-between items-center">
                      <h2 className="text-[18px] font-[600] ">
                        {category.category_name}
                      </h2>
                      <div className="  ">
                        {category.questions
                          .slice(0, 1)
                          .map((question, questionIndex) => (
                            <ul
                              className="list-disc list-inside flex w-[100px] justify-between ite "
                              key={questionIndex}
                            >
                              {question.options.map((option, optionIndex) => (
                                <div
                                  key={optionIndex}
                                  className="text-[14px] font-[400] text-[#788BA5] w-[25px]"
                                >
                                  {option.option_text}
                                </div>
                              ))}
                            </ul>
                          ))}
                      </div>
                    </div>
                    <div className="px-3">
                      {category.questions.map((question, questionIndex) => (
                        <div
                          key={`${categoryIndex}-${questionIndex}`}
                          className="flex justify-between  py-3"
                        >
                          <p className="text-[16px] font-[400] text-[#788BA5] mb-2 w-[87%]">
                            {question.question_statement}
                          </p>
                          <div className="flex w-[100px] justify-between">
                            {question.options.map((option, optionIndex) => {
                              const isChecked = question.mcq_answers.some(
                                (answer) =>
                                  answer.selected_option.trim() ===
                                  option.option_text.trim()
                              );

                              return (
                                <label
                                  key={optionIndex}
                                  className="flex items-center"
                                >
                                  <input
                                    type="checkbox"
                                    name={`question-${categoryIndex}-${questionIndex}`}
                                    className="w-[25px] h-[25px]"
                                    value={option.option_text}
                                    checked={isChecked}
                                    readOnly
                                  />
                                </label>
                              );
                            })}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {category.category_name.trim() ===
                  "Stoffwechselerkrankungen?" && (
                  <div className="mb-4 border rounded">
                    <div className="bg-[#FAFAFA] p-3 flex justify-between items-center">
                      <h2 className="text-[18px] font-[600] ">
                        {category.category_name}
                      </h2>
                      <div className="  ">
                        {category.questions
                          .slice(0, 1)
                          .map((question, questionIndex) => (
                            <ul
                              className="list-disc list-inside flex w-[100px] justify-between ite "
                              key={questionIndex}
                            >
                              {question.options.map((option, optionIndex) => (
                                <div
                                  key={optionIndex}
                                  className="text-[14px] font-[400] text-[#788BA5] w-[25px]"
                                >
                                  {option.option_text}
                                </div>
                              ))}
                            </ul>
                          ))}
                      </div>
                    </div>
                    <div className="px-3">
                      {category.questions.map((question, questionIndex) => (
                        <div
                          key={`${categoryIndex}-${questionIndex}`}
                          className="flex justify-between  py-3"
                        >
                          <p className="text-[16px] font-[400] text-[#788BA5] mb-2 w-[87%]">
                            {question.question_statement}
                          </p>
                          <div className="flex w-[100px] justify-between">
                            {question.options.map((option, optionIndex) => {
                              const isChecked = question.mcq_answers.some(
                                (answer) =>
                                  answer.selected_option.trim() ===
                                  option.option_text.trim()
                              );

                              return (
                                <label
                                  key={optionIndex}
                                  className="flex items-center"
                                >
                                  <input
                                    type="checkbox"
                                    name={`question-${categoryIndex}-${questionIndex}`}
                                    className="w-[25px] h-[25px]"
                                    value={option.option_text}
                                    checked={isChecked}
                                    readOnly
                                  />
                                </label>
                              );
                            })}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {category.category_name.trim() === "Frühere Operationen?" && (
                  <div className="mb-4 border rounded">
                    <div className="bg-[#FAFAFA] p-3 flex justify-between items-center">
                      <h2 className="text-[18px] font-[600] ">
                        {category.category_name}
                      </h2>
                      <div className="  ">
                        {category.questions
                          .slice(0, 1)
                          .map((question, questionIndex) => (
                            <ul
                              className="list-disc list-inside flex w-[100px] justify-between ite "
                              key={questionIndex}
                            >
                              {question.options.map((option, optionIndex) => (
                                <div
                                  key={optionIndex}
                                  className="text-[14px] font-[400] text-[#788BA5] w-[25px]"
                                >
                                  {option.option_text}
                                </div>
                              ))}
                            </ul>
                          ))}
                      </div>
                    </div>
                    <div className="px-3">
                      {category.questions.map((question, questionIndex) => (
                        <div
                          key={`${categoryIndex}-${questionIndex}`}
                          className="flex justify-between  py-3"
                        >
                          <p className="text-[16px] font-[400] text-[#788BA5] mb-2 w-[87%]">
                            {question.question_statement}
                          </p>
                          <div className="flex w-[100px] justify-between">
                            {question.options.map((option, optionIndex) => {
                              const isChecked = question.mcq_answers.some(
                                (answer) =>
                                  answer.selected_option.trim() ===
                                  option.option_text.trim()
                              );

                              return (
                                <label
                                  key={optionIndex}
                                  className="flex items-center"
                                >
                                  <input
                                    type="checkbox"
                                    name={`question-${categoryIndex}-${questionIndex}`}
                                    className="w-[25px] h-[25px]"
                                    value={option.option_text}
                                    checked={isChecked}
                                    readOnly
                                  />
                                </label>
                              );
                            })}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {category.category_name.trim() ===
                  "Haben/Hatten Sie/Ihr Kind andere wesentl. Erkrankungen/Unfälle?" && (
                  <div className="mb-4 border rounded">
                    <div className="bg-[#FAFAFA] p-3 flex justify-between items-center">
                      <h2 className="text-[18px] font-[600] ">
                        {category.category_name}
                      </h2>
                      <div className="  ">
                        {category.questions
                          .slice(0, 1)
                          .map((question, questionIndex) => (
                            <ul
                              className="list-disc list-inside flex w-[100px] justify-between ite "
                              key={questionIndex}
                            >
                              {question.options.map((option, optionIndex) => (
                                <div
                                  key={optionIndex}
                                  className="text-[14px] font-[400] text-[#788BA5] w-[25px]"
                                >
                                  {option.option_text}
                                </div>
                              ))}
                            </ul>
                          ))}
                      </div>
                    </div>
                    <div className="px-3">
                      {category.questions.map((question, questionIndex) => (
                        <div
                          key={`${categoryIndex}-${questionIndex}`}
                          className="flex justify-between  py-3"
                        >
                          <p className="text-[16px] font-[400] text-[#788BA5] mb-2 w-[87%]">
                            {question.question_statement}
                          </p>
                          <div className="flex w-[100px] justify-between">
                            {question.options.map((option, optionIndex) => {
                              const isChecked = question.mcq_answers.some(
                                (answer) =>
                                  answer.selected_option.trim() ===
                                  option.option_text.trim()
                              );

                              return (
                                <label
                                  key={optionIndex}
                                  className="flex items-center"
                                >
                                  <input
                                    type="checkbox"
                                    name={`question-${categoryIndex}-${questionIndex}`}
                                    className="w-[25px] h-[25px]"
                                    value={option.option_text}
                                    checked={isChecked}
                                    readOnly
                                  />
                                </label>
                              );
                            })}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {category.category_name.trim() === "Geplante Maßnahmen" && (
                  <div className="  flex justify-between items-center ">
                    <h2 className="text-[18px] font-[600] ">
                      {category.category_name}
                    </h2>

                    <div className="px-3">
                      {category.questions.map((question, questionIndex) => (
                        <div
                          key={`${categoryIndex}-${questionIndex}`}
                          className=""
                        >
                          <ul className="list-disc list-inside flex gap-2 ">
                            {question.options.map((option, optionIndex) => {
                              const isChecked = question.mcq_answers.some(
                                (answer) =>
                                  answer.selected_option.trim() ===
                                  option.option_text.trim()
                              );

                              return (
                                <>
                                  <label
                                    key={optionIndex}
                                    className="flex items-center "
                                  >
                                    <input
                                      type="checkbox"
                                      name={`question-${categoryIndex}-${questionIndex}`}
                                      className="w-[20px] h-[20px]"
                                      value={option.option_text}
                                      checked={isChecked}
                                      readOnly
                                    />
                                  </label>
                                  <p
                                    key={optionIndex}
                                    className="text-[12px] font-[400] text-[#788BA5]"
                                  >
                                    {option.option_text}
                                  </p>
                                </>
                              );
                            })}
                          </ul>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {category.category_name.trim() ===
                  "Medikament and Dosierung" && (
                  <>
                    <div className="mb-4  flex flex-col bg-[#FAFAFA] p-3 ">
                      <h2 className="text-[18px] font-[600] ">
                        {category.category_name}
                      </h2>
                      <textarea
                        className="h-[130px] border p-3 rounded outline-none"
                        placeholder="Enter your text"
                      ></textarea>

                      <div className="px-3">
                        {category.questions?.map((question, questionIndex) => (
                          <>
                            <div
                              key={`${categoryIndex}-${questionIndex}`}
                              className="py-1"
                            >
                              <ul className="list-disc list-inside flex justify-between ">
                                {question.options?.map(
                                  (option, optionIndex) => (
                                    <div className="flex items-center gap-2">
                                      <input
                                        type="checkbox"
                                        className="w-[20px] h-[20px]"
                                      />
                                      <p
                                        key={optionIndex}
                                        className="text-[12px] font-[400] text-[#788BA5]"
                                      >
                                        {option.option_text}
                                      </p>
                                    </div>
                                  )
                                )}
                              </ul>
                            </div>
                            <div>
                              {question.dosages?.length > 0 && (
                                <div className="w-[50%] my-5">
                                  {question.dosages?.map((data, index) => (
                                    <div
                                      key={index}
                                      className="mb-4 flex items-center justify-between"
                                    >
                                      <p className="font-[400] text-[14px] text-[#788BA5]">
                                        {data.drug}
                                      </p>
                                      <p className="font-[400] text-[14px] text-[#788BA5]">
                                        {data.dosage}
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </>
                )}
                {category.category_name.trim() === "Einwilligung" && (
                  <div className="mb-4 mt-[-20px] flex flex-col bg-[#FAFAFA] p-3 ">
                    <h2 className="text-[18px] font-[600] ">
                      {category.category_name}
                    </h2>

                    <div className="px-3">
                      {category.questions.map((question, questionIndex) => (
                        <div
                          key={`${categoryIndex}-${questionIndex}`}
                          className=" flex items-center justify-between py-3"
                        >
                          <input type="checkbox" className="w-[4%] h-[20px] " />
                          <p className="text-[14px] font-[400] text-[#788BA5] w-[95%]">
                            {question.question_statement}
                          </p>
                        </div>
                      ))}
                    </div>
                    <div className="w-[600px]">
                      <div className="mt-2 border-[#DEE4ED] border-[1px] rounded-md">
                        <SignatureCanvas
                          ref={sigCanvas}
                          penColor="black"
                          canvasProps={{
                            width: 600,
                            height: 200,
                            className: "sigCanvas"
                          }}
                          onEnd={handleEndSignature}
                        />
                      </div>
                      <div className="flex justify-between items-center mt-3">
                        <p className="text-[#787878] text-[14px]">
                          Unterschrift der Patientin/des Patienten / der Eltern
                          / der Betreuerin/des Betreuers*
                        </p>
                        <button
                          onClick={clearSignature}
                          className="px-2 text-white bg-[#ff4c4c] cursor-pointer font-[500] text-[16px] py-1 rounded"
                        >
                          Clear
                        </button>
                        <button
                          onClick={saveSignature}
                          disabled={!isSaveEnabled}
                          className={`px-2 text-white font-[500] text-[16px] py-1 rounded ml-2 ${
                            isSaveEnabled
                              ? "bg-[#4CAF50] cursor-pointer"
                              : "bg-gray-400 cursor-not-allowed"
                          }`}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
            <div>
              <h2 className="text-[18px] font-[600] mb-2">
                Ärztliche Dokumentation
              </h2>
              {consentForm?.data?.medical_documentation?.questions?.map(
                (data, index) => (
                  <div
                    key={index}
                    className="bg-[#FAFAFA] p-3 flex  gap-2 w-full"
                  >
                    <p className="w-[85%] text-[#788BA5] text-[14px] font-[400]">
                      {data.question_statement}
                    </p>
                    <div className="flex items-center gap-2 w-[10%]">
                      <div className="flex items-center gap-2 text-[#788BA5] text-[14px] font-[400]">
                        <p>Nein</p>
                        <input type="checkbox" className="w-[20px] h-[20px] " />
                      </div>
                      <div className="flex items-center gap-2 text-[#788BA5] text-[14px] font-[400]">
                        <p>Ja</p>
                        <input type="checkbox" className="w-[20px] h-[20px] " />
                      </div>
                    </div>
                  </div>
                )
              )}
              <div className="bg-[#FAFAFA] p-3">
                <p className=" text-[#788BA5] font-[600] text-[14px]">
                  Ort, Datum (tt.mm.jj), Uhrzeit (hh.mm)
                </p>
                <p className="w-[30%] bg-white rounded p-3 border text-[#788BA5] font-[600] text-[14px] mt-2">
                  {consentForm?.data?.medical_documentation?.location_datetime}
                </p>
                <div className="w-[600px]">
                  <div className="mt-2 border-[#DEE4ED] border-[1px] rounded-md  flex justify-center items-center py-5">
                    <img
                      className="rounded-full w-[100px] h-[100px]"
                      src={`${consentForm?.data?.doctor_signature_image}`}
                    />
                  </div>
                  <p className="text-[#787878] text-[14px] mt-1">
                    Unterschrift der Ärztin / des Arztes / ggf. von Zeugen
                  </p>
                </div>
              </div>
              <div onClick={handleSubmit} className="flex justify-end">
                <button
                  className="flex items-center w-[70px] justify-center text-white bg-primary  cursor-pointer font-[700] text-[16px] font-roboto py-1 rounded mt-4"
                  disabled={consentForm?.loading}
                >
                  {loading ? <ButtonLoader_1 /> : "Save"}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ConsentForm;
