import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useGetMedicalHistoryForm from "../../../hooks/useGetMedicalHistoryForm";
import { TableSkelton_5 } from "../../../components/shared/Skeltons";
import { HiArrowLeft, HiOutlineChevronRight } from "react-icons/hi";
import Paginations from "../../../components/shared/Paginations";
import ShowMessage from "../../../components/shared/ShowMessage";
import Dropdown from "../../../components/shared/Dropdown";
import { GoPlus } from "react-icons/go";

function AdminMedicalHistoryFormList() {
  const Navigate = useNavigate();
  const [filters, setFilters] = useState({
    name: "All",
    value: "all"
  });

  const { medicalForm, setMedicalForm, GetMedicalHistoryForm } =
    useGetMedicalHistoryForm();
  useEffect(() => {
    GetMedicalHistoryForm(
      `/api/admin/medical-history-forms/?page=${medicalForm.currentPage}`
    );
  }, [medicalForm.currentPage]);

  const handlePageChange = (event, value) => {
    setMedicalForm((prevState) => ({
      ...prevState,
      currentPage: value,
      loading: true
    }));
  };
  const medicalPerPage = 12;
  const startIndex1 = (medicalForm.currentPage - 1) * medicalPerPage;

  // dropdown
  const [activeDropdown, setActiveDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const handleShow = (index) => {
    if (activeDropdown === index) {
      setActiveDropdown(!activeDropdown);
    } else {
      setActiveDropdown(index);
    }
  };
  const handleCloseDropdown = () => {
    setActiveDropdown(false);
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        e.target.closest(".small-circle-main") ||
        (dropdownRef.current && dropdownRef.current.contains(e.target))
      ) {
        return;
      }
      handleCloseDropdown();
    };
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  return (
    <>
      <div className=" p-6 bg-white m-6 rounded-md">
        <div className="flex items-center justify-between mb-3 ">
          <Link to="/patients" className="flex items-center gap-2 ">
            <HiArrowLeft className="text-[25px] cursor-pointer" />
            <h1 className="text-[24px] font-[600px] text-[#272727]  ">
              Medical History Form{" "}
              <span className="text-primary text-[16px] font-[400]">
                ({medicalForm?.data?.results?.length})
              </span>
            </h1>
          </Link>
          <div className="flex items-center gap-2">
            <Dropdown
              options={[
                { name: "All", value: "all" },
                { name: "Dr. John Smith", value: "Dr. John Smith" },
                { name: "Dr. Emily Johnson", value: "Dr. Emily Johnson" },
                {
                  name: "Dr. Michael Williams",
                  value: "Dr. Michael Williams"
                },
                {
                  name: "Dr. Michael Williams",
                  value: "Dr. Michael Williams"
                }
              ]}
              selected={filters}
              onChange={(option) =>
                setFilters({ name: option.name, value: option.value })
              }
            />
            <div
              onClick={() => {
                Navigate("/manage-forms/medical-history-form");
              }}
              className="flex items-center justify-center text-white bg-primary rounded p-2 cursor-pointer gap-1"
            >
              <GoPlus className="text-[22px]" />
              <p className="text-[14px] font-[500]"> Medical History Form</p>
            </div>
          </div>
        </div>
        <hr />
        {medicalForm.loading ? (
          <>
            {Array.from({ length: 2 }).map((_, index) => (
              <TableSkelton_5 key={index} />
            ))}
          </>
        ) : (
          <>
            <div className="grid grid-cols-3 gap-4 w-full mt-3">
              {medicalForm?.data?.results?.map((data, index) => (
                <div
                  key={index}
                  className="border border-gray-200 rounded-xl cursor-pointer h-auto"
                >
                  <div className="flex flex-col p-3 gap-5">
                    <div className="flex items-center justify-between">
                      <h1 className="text-[16px] font-[600] text-[#272727]">
                        Medical History Form {startIndex1 + index + 1}
                      </h1>
                      {/* 
                      <div className="relative small-circle-main">
                        <HiOutlineDotsVertical
                          className="cursor-pointer"
                          onClick={() => handleShow(index)}
                        />
                        {activeDropdown === index && (
                          <div
                            ref={dropdownRef}
                            className="absolute py-1 w-[100px] bg-white flex flex-col rounded-sm right-[30%] top-[100%] z-20 shadow-md"
                          >
                            <p className="px-3 py-1 cursor-pointer font-[400] text-[13px] font-roboto text-[#787878] hover:bg-[#F5F5FE] hover:text-primary">
                              Edit
                            </p>
                            <p className="px-3 py-1 cursor-pointer font-[400] text-[13px] font-roboto text-[#787878] hover:bg-[#F5F5FE] hover:text-primary">
                              Delete
                            </p>
                          </div>
                        )}
                      </div> */}
                    </div>
                    <div className="w-[25%] text-[10px]  font-[600] bg-[#46F7B7] rounded  text-center py-1">
                      {data.status}
                    </div>
                    <div className="flex justify-between items-center">
                      <p className="text-[14px] font-[400] text-[#787878]">
                        Sent
                      </p>
                      <p className="text-[13px] font-[500] text-[#788BA5] flex items-center gap-2">
                        {data?.sent_time}
                      </p>
                    </div>

                    <div className="flex justify-between items-center">
                      <p className="text-[14px] font-[400] text-[#787878]">
                        Received
                      </p>
                      <p className="text-[13px] font-[500] text-[#788BA5] flex items-center gap-2">
                        {data?.received_time}
                      </p>
                    </div>
                  </div>

                  <hr />

                  <div
                    onClick={() => {
                      Navigate(
                        "/manage-forms/medical-history/view-medical-history",
                        {
                          state: {
                            selectedPatient: data
                          }
                        }
                      );
                    }}
                    className="flex justify-between items-center text-primary text-[13px] p-2"
                  >
                    <p className="font-[500]">View Form</p>
                    <HiOutlineChevronRight className="text-[18px]" />
                  </div>
                </div>
              ))}
            </div>
            <div className="w-full flex justify-between items-center mt-4">
              <p className="text-[#272727] font-roboto text-[14px] font-[400] leading-5">
                {medicalForm?.data?.display_string}
              </p>
              <Paginations
                totalPages={medicalForm?.totalPages}
                currentPage={medicalForm?.currentPage}
                handlePageChange={handlePageChange}
              />
            </div>
            {!medicalForm?.loading && medicalForm?.message && (
              <div className="bg-white flex justify-center items-center w-full h-[300px]">
                <ShowMessage message={medicalForm?.message} />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
export default AdminMedicalHistoryFormList;
