import React, { useContext, useEffect } from "react";
import AppHead from "../../seo/AppHead";
import GlobalContext from "../../context/GlobalContext";
import useGetDoctors from "../../hooks/useGetDoctors";
import { TableSkelton_4 } from "../../components/shared/Skeltons";
import Paginations from "../../components/shared/Paginations";
import ShowMessage from "../../components/shared/ShowMessage";

function DashboardPage() {
  const { userInfo } = useContext(GlobalContext);
  return (
    <>
      <AppHead title="Dashboard - CHARITE" />
      <div className="p-10">
        <PatientDetails userInfo={userInfo} />
        <AssignDoctors userInfo={userInfo} />
      </div>
    </>
  );
}

export default DashboardPage;

function PatientDetails({ userInfo }) {
  return (
    <>
      <div className="">
        <div
          className="w-full bg-white rounded-xl p-6 mt-4 flex"
          style={{ boxShadow: "0px 0px 7px #e0e0e0" }}
        >
          <div className="flex flex-col justify-center items-center w-[25%] mr-4">
            <svg
              className="w-[130px] h-[130px] rounded-full text-gray-200 dark:text-gray-700"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
            </svg>
            <h1 className="text-[#272727] text-[18px] font-[600] font-roboto mt-1">
              {userInfo?.first_name} {userInfo?.last_name}
            </h1>
            <p className="text-[#F6833B] text-[14px] font-[400] font-roboto bg-red-100 py-1 px-3 rounded ">
              {userInfo?.patient_profile?.disease}
            </p>
          </div>
          <div className=" border-l-2 border-gray-100 w-[38%] pl-10 flex flex-col justify-between">
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Phone No.
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {userInfo?.mobile_no}
              </p>
            </div>
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Email
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {userInfo?.email}
              </p>
            </div>{" "}
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Gender
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {userInfo?.gender}
              </p>
            </div>
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Marital status
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {userInfo?.marital_status}
              </p>
            </div>{" "}
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Date of Birth
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {userInfo?.date_of_birth}
              </p>
            </div>{" "}
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Occupation
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {userInfo?.patient_profile?.occupation}
              </p>
            </div>{" "}
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Blood Pressure
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {userInfo?.patient_profile?.bp}
              </p>
            </div>
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Sugar
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {userInfo?.patient_profile?.sugar}
              </p>
            </div>{" "}
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Blood Group
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {userInfo?.patient_profile?.blood_group}
              </p>
            </div>
          </div>
          <div className="w-[37%] pl-10 flex flex-col justify-between gap-2">
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Height
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {userInfo?.patient_profile?.height}
              </p>
            </div>
            {userInfo?.patient_profile?.guardians?.map((data, index) => (
              <div>
                <div className="flex items-center justify-between">
                  <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                    Guardian Name
                  </h1>
                  <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                    {data?.name}
                  </p>
                </div>{" "}
                <div className="flex items-center justify-between">
                  <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                    Relation
                  </h1>
                  <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                    {data?.relation}
                  </p>
                </div>{" "}
                <div className="flex items-center justify-between">
                  <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                    Guardian Mobile
                  </h1>
                  <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                    {data?.contact_no}
                  </p>
                </div>{" "}
                <div className="flex items-center justify-between">
                  <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                    Guardian Email
                  </h1>
                  <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                    {data?.email}
                  </p>
                </div>
              </div>
            ))}
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                State
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {userInfo?.patient_profile?.user?.state}
              </p>
            </div>
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                City
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {userInfo?.patient_profile?.user?.city}
              </p>
            </div>
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Postal Code
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {userInfo?.patient_profile?.user?.postal_code}
              </p>
            </div>{" "}
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Address
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {userInfo?.patient_profile?.user?.address}
              </p>
            </div>
            {/* <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Status
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {userInfo?.guardians?.status}
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
function AssignDoctors({ userInfo }) {
  const { doctors, setDoctors, GetDoctors } = useGetDoctors();
  const { updateResponse } = useContext(GlobalContext);

  useEffect(() => {
    GetDoctors(
      `/api/patient/${userInfo?.patient_profile?.id}/assigned-doctors/?page=${doctors.currentPage}`
    );
  }, [doctors.currentPage, updateResponse]);

  const handlePageChange = (event, value) => {
    setDoctors((prevState) => ({ ...prevState, currentPage: value }));
  };

  return (
    <>
      <div className="mb-2 mt-7 flex justify-between items-center w-full">
        <div className=" flex items-center w-[70%]">
          <div className="flex items-center justify-center">
            <h1 className="font-[700] text-[20px] font-roboto text-[#272727] w-auto ">
              Attending Doctor Details
            </h1>
            <p className="font-[400] text-[16px] font-roboto text-primary ml-1">
              ({doctors?.data?.count})
            </p>
          </div>
        </div>
      </div>

      <div
        className="w-full auto bg-white rounded-xl  p-4"
        style={{ boxShadow: "0px 0px 7px #e0e0e0" }}
      >
        {doctors.loading ? (
          <>
            {Array.from({ length: 2 }).map((_, index) => (
              <TableSkelton_4 key={index} />
            ))}
          </>
        ) : (
          <>
            <div className="grid grid-cols-4 gap-4 w-full">
              {doctors?.data?.results?.map((item, index) => (
                <div key={index} className="border p-4 rounded-xl">
                  <div className="flex justify-between items-center text-[#788BA5] relative ">
                    <p className="text-[14px] font-[500]">2d ago</p>
                  </div>
                  <div className="flex flex-col items-center justify-center">
                    <svg
                      className="w-[50px] h-[50px] rounded-full text-gray-200 dark:text-gray-700"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                    </svg>
                    <h1 className="text-[#272727] text-[18px] font-[600] font-roboto mt-1">
                      {item?.user?.first_name} {item?.user?.last_name}
                    </h1>
                    <p className="text-primary text-[10px] font-[500] font-roboto bg-[#DDE9FC] py-1 px-3 rounded mt-1">
                      {item?.department}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div className="w-full flex justify-between items-center mt-2">
              <p className="text-[#272727] font-roboto text-[14px] font-[400] leading-5">
                {doctors?.data?.results?.length > 0 &&
                  doctors?.data?.display_string}
              </p>
              <Paginations
                totalPages={doctors.totalPages}
                currentPage={doctors.currentPage}
                handlePageChange={handlePageChange}
              />
            </div>
            {!doctors.loading && doctors.message && (
              <div className="bg-white flex justify-center items-center w-full h-[300px]">
                <ShowMessage message={doctors.message} />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
