import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { getAccessToken } from "../storage/storage";

function useUpload() {
  const token = getAccessToken();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [upload, setUpload] = useState({
    loading: false,
    url: null,
    message: null,
  });

  const Upload = async (payload) => {
    setUpload((prevState) => ({
      ...prevState,
      loading: true,
      url: null,
      message: null,
    }));
    await axios
      .post(`${BASE_URL}/api/upload-image/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setUpload((prevState) => ({
          ...prevState,
          loading: false,
          url: response?.data?.image_url,
          message: null,
        }));
      })
      .catch((error) => {
        setUpload((prevState) => ({
          ...prevState,
          loading: false,
          url: null,
          message: error?.response?.data?.message,
        }));
        Swal.fire({
          icon: "error",
          text:
            error?.response?.data?.message ||
            "Oops! Something went wrong on our end. Please give us a moment to fix it. Feel free to try again later",
          customClass: {
            confirmButton: "custom-red-button",
          },
        });
      });
  };
  return { Upload, upload, setUpload };
}
export default useUpload;
