import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { AiOutlineClose } from "react-icons/ai";
import { PiShareFat } from "react-icons/pi";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ButtonLoader_1 } from "../shared/Loaders";
import useShareTreatmentPlan from "../../hooks/useShareTreatmentPlan";

const validationSchema = Yup.object({
  medication_needed: Yup.string().required("Medication needed is required"),
  start_date: Yup.string().required("Start date is required"),
  end_date: Yup.string().required("End date is required"),
  dosage: Yup.string().required("Dosage is required"),
  frequency: Yup.string().required("Frequency is required")
});

const ShareTreatmentPlanModal = ({
  isOpen,
  onClose,
  selectedPatient,
  action
}) => {
  const { ShareTreatmentPlan, loading } = useShareTreatmentPlan();
  const initialValues = {
    medication_needed: "yes",
    start_date: "",
    end_date: "",
    dosage: "",
    frequency: ""
  };

  const { errors, touched, values, handleChange, handleSubmit } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async () => {
      await ShareTreatmentPlan(
        {
          patient: selectedPatient?.id,
          medication_needed: values.medication_needed,
          start_date: values.start_date,
          end_date: values.end_date,
          dosage: values.dosage,
          frequency: values.frequency
        },
        `/api/treatment-plans/`,
        onClose
      );
    }
  });

  return (
    <Transition.Root show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-[800px] pl-5 pr-5 pb-5 pt-3">
              <div className="flex justify-between items-center">
                <h1 className="text-[24px] font-[600] text-[#272727]">
                  Create Treatment Plan
                </h1>
                <AiOutlineClose onClick={onClose} className="cursor-pointer" />
              </div>
              <h1 className="text-[20px] font-[700] mt-4">Treatment Plan</h1>
              <form class="w-full mx-auto mt-3">
                <label
                  for="countries_disabled"
                  class="block mb-2 text-[16px] font-[500] text-[#272727]"
                >
                  Medication Needed
                </label>
                <select
                  class="bg-white border border-[#DEE4ED] text-gray-400 text-sm rounded-[5px] block w-full p-2.5 outline-none"
                  name="medication_needed"
                  value={values.medication_needed}
                  onChange={handleChange}
                >
                  <option selected value="yes">
                    Yes
                  </option>
                  <option value="no">No</option>
                </select>
                {errors.medication_needed && touched.medication_needed && (
                  <p className="text-red-700 text-xs mt-1">
                    {errors.medication_needed}
                  </p>
                )}

                <div className="flex justify-between w-full gap-2 mt-5">
                  <div className="w-1/2">
                    <label
                      for="countries_disabled"
                      class="block mb-2 text-[16px] font-[500] text-[#272727]"
                    >
                      Start Date
                    </label>
                    <input
                      rows="4"
                      type="date"
                      class="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-[5px] border border-gray-300 outline-none focus:border-primary"
                      placeholder="Chose Date"
                      name="start_date"
                      value={values.start_date}
                      onChange={handleChange}
                    />
                    {errors.start_date && touched.start_date && (
                      <p className="text-red-700 text-xs mt-1">
                        {errors.start_date}
                      </p>
                    )}
                  </div>
                  <div className="w-1/2">
                    <label
                      for="countries_disabled"
                      class="block mb-2 text-[16px] font-[500] text-[#272727]"
                    >
                      End Date
                    </label>
                    <input
                      rows="4"
                      type="date"
                      class="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-[5px] border border-gray-300 outline-none focus:border-primary"
                      placeholder="Chose Date"
                      name="end_date"
                      value={values.end_date}
                      onChange={handleChange}
                    />
                    {errors.end_date && touched.end_date && (
                      <p className="text-red-700 text-xs mt-1">
                        {errors.end_date}
                      </p>
                    )}
                  </div>
                </div>
                <label
                  for="countries_disabled"
                  class="block mb-2 text-[16px] font-[500] text-[#272727] mt-5"
                >
                  Dosage
                </label>
                <input
                  id="message"
                  rows="4"
                  class="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-[5px] border border-gray-300 outline-none focus:border-primary"
                  placeholder="Enter Dosage Details"
                  name="dosage"
                  value={values.dosage}
                  onChange={handleChange}
                />
                {errors.dosage && touched.dosage && (
                  <p className="text-red-700 text-xs mt-1">{errors.dosage}</p>
                )}
                <label
                  for="countries_disabled"
                  class="block mb-2 text-[16px] font-[500] text-[#272727] mt-5"
                >
                  Frequency
                </label>
                <input
                  id="message"
                  rows="4"
                  class="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-[5px] border border-gray-300 outline-none focus:border-primary"
                  placeholder="Enter Frequency Details"
                  name="frequency"
                  value={values.frequency}
                  onChange={handleChange}
                />
                {errors.frequency && touched.frequency && (
                  <p className="text-red-700 text-xs mt-1">
                    {errors.frequency}
                  </p>
                )}
              </form>
              {action === "view" ? (
                <div className="mt-6 flex justify-end cursor-pointer">
                  <div
                    onClick={handleSubmit}
                    className="bg-blue-500 flex text-white w-[120px] py-2 rounded-md ml-3 items-center gap-3 justify-center"
                  >
                    {loading ? (
                      <ButtonLoader_1 />
                    ) : (
                      <>
                        <span className="text-[16px] font-normal">Share</span>
                        <PiShareFat className="text-[25px]" />
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <div className="pb-3"></div>
              )}
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ShareTreatmentPlanModal;
