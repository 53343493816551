import { useState } from "react";
import axios from "axios";
import { getAccessToken } from "../storage/storage";

const useGetMedicalHistoryTemplate = () => {
  const token = getAccessToken();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [medicalTemplate, setMedicalTemplate] = useState({
    loading: true,
    data: null,
    message: null
  });

  const GetMedicalHistoryTemplate = async (url) => {
    setMedicalTemplate((prevState) => ({
      ...prevState,
      loading: true,
      message: null
    }));
    await axios
      .get(`${BASE_URL}${url}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        setMedicalTemplate((prevState) => ({
          ...prevState,
          loading: false,
          data: response.data?.length >= 1 ? response.data[0] : null,
          message: response.data?.length === 0 ? "Data not found" : null
        }));
      })
      .catch((error) => {
        setMedicalTemplate((prevState) => ({
          ...prevState,
          loading: false,
          message: "Oops! Something went wrong"
        }));
      });
  };
  return {
    medicalTemplate,
    setMedicalTemplate,
    GetMedicalHistoryTemplate
  };
};
export default useGetMedicalHistoryTemplate;
