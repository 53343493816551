import React from "react";
import "../../styles/checkbox.css";

function CheckBox({ onChange, checked }) {
  return (
    <>
      <label className="custom-checkbox">
        <input
          name="dummy"
          type="checkbox"
          onChange={onChange}
          checked={checked}
        />
        <span className="checkmark"></span>
      </label>
    </>
  );
}

export default CheckBox;
