import React from "react";
import { useContext } from "react";
import GlobalContext from "../../context/GlobalContext";
import { MdOutlineMenu } from "react-icons/md";
import { IoIosArrowDown, IoMdNotificationsOutline } from "react-icons/io";
import { Link } from "react-router-dom";

function Navbar({ toggleSidebar }) {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { userInfo } = useContext(GlobalContext);

  return (
    <>
      <nav
        className="w-full bg-white h-[70px] px-[20px] flex items-center justify-between sticky top-0 z-40"
        style={{ boxShadow: "0px 0px 14px 0px rgba(204, 204, 204, 0.25)" }}
      >
        <div className="flex items-center gap-3">
          <div className="lg:hidden flex">
            <MdOutlineMenu
              onClick={toggleSidebar}
              className="text-[30px] text-[#788BA5] hover:text-primary cursor-pointer"
            />
          </div>
          <div className="flex items-center bg-[#F6F8FB] rounded-xl py-2 px-3">
            <i className="fas fa-search text-[#788BA5] px-2"></i>
            <input
              className="appearance-none bg-transparent border-none w-full mr-3 py-1 px-2 outline-none text-[#788BA5] text-[15px] font-[500]"
              type="search"
              placeholder="Search here"
            />
          </div>
        </div>
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-3 pr-4">
            <Link to="/notifications" className="relative">
              <IoMdNotificationsOutline className="text-[30px] hover:text-primary cursor-pointer" />
              <div className="absolute top-[-2px] right-[-2px] h-[14px] w-[14px] bg-primary rounded-full text-white text-[8px] flex justify-center items-center">
                10
              </div>
            </Link>
            <div className="flex items-center gap-3 cursor-pointer">
              {userInfo?.profile_avatar ? (
                <img
                  className="rounded-full w-[45px] h-[45px]"
                  src={`${userInfo?.profile_avatar}`}
                  alt=""
                />
              ) : (
                <svg
                  className="w-[45px] h-[45px] text-gray-200 dark:text-gray-700"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                </svg>
              )}
              <div>
                <h1 className="font-[600] text-[16px] text-[#272727] leading-5">
                  {userInfo?.first_name} {userInfo?.last_name}
                </h1>
                <p className="font-[400] text-[12px] text-primary leading-5">
                  {userInfo?.role === "Admin" && "Admin"}
                  {userInfo?.role === "Doctor" && "Doctor"}
                  {userInfo?.role === "Patient" && "Patient"}
                </p>
              </div>
              <IoIosArrowDown className="text-[20px] text-[#272727]" />
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
