/* eslint-disable react/jsx-pascal-case */
import React, { useContext } from "react";
import AppHead from "../../seo/AppHead";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext";
import { RiEditFill } from "react-icons/ri";
import useUpdateProfile from "../../hooks/useUpdateProfile";
import { ButtonLoader_1 } from "../../components/shared/Loaders";
import useUpload from "../../hooks/useUpload";

const UpdateProfileValidationSchema = Yup.object().shape({
  first_name: Yup.string().required("Firstname is required"),
  last_name: Yup.string().nullable(),
  age: Yup.string().nullable(),
  material_status: Yup.string().nullable(),
  gender: Yup.string().nullable(),
  mobile_number: Yup.string().nullable(),
  email: Yup.string().required("Email is required"),
  address: Yup.string().nullable(),
  state: Yup.string().nullable(),
  city: Yup.string().nullable(),
  postal_code: Yup.string().nullable(),
  profile_avatar: Yup.string().nullable(),
  // Doctor fields
  department: Yup.string().nullable(),
  // Patitent fields
  occupation: Yup.string().nullable(),
  blood_pressure: Yup.string().nullable(),
  sugar: Yup.string().nullable(),
  blood_group: Yup.string().nullable(),
  height: Yup.string().nullable(),
});

function EditProfile() {
  const { userInfo } = useContext(GlobalContext);
  const { loading, UpdateProfile } = useUpdateProfile();
  const { Upload, upload, setUpload } = useUpload();

  const initialValues = {
    first_name: userInfo?.first_name,
    last_name: userInfo?.last_name,
    age: userInfo?.age,
    material_status: userInfo?.marital_status,
    gender: userInfo?.gender,
    mobile_number: userInfo?.mobile_no,
    email: userInfo?.email,
    address: userInfo?.address,
    state: userInfo?.state,
    city: userInfo?.city,
    postal_code: userInfo?.postal_code,
    profile_avatar: userInfo?.profile_avatar,
    // Doctor
    department: userInfo?.doctor_profile?.department,
    // Patient
    occupation: userInfo?.patient_profile?.occupation,
    blood_pressure: userInfo?.patient_profile?.bp,
    sugar: userInfo?.patient_profile?.sugar,
    blood_group: userInfo?.patient_profile?.blood_group,
    height: userInfo?.patient_profile?.height,
  };

  const { errors, touched, values, handleChange, handleSubmit } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: UpdateProfileValidationSchema,
    onSubmit: async (values) => {
      if (userInfo?.role === "Patient") {
        await UpdateProfile({
          first_name: values.first_name,
          last_name: values.last_name,
          age: values.age,
          material_status: values.material_status,
          gender: values.gender,
          mobile_number: values.mobile_no,
          email: values.email,
          address: values.address,
          state: values.state,
          city: values.city,
          postal_code: values.postal_code,
          profile_avatar: upload.url ? upload.url : values.profile_avatar,
          patient_profile: {
            occupation: values.occupation,
            bp: values.blood_pressure,
            sugar: values.sugar,
            blood_group: values.blood_group,
            height: values.height,
          },
        });
      } else if (userInfo?.role === "Doctor") {
        await UpdateProfile({
          first_name: values.first_name,
          last_name: values.last_name,
          age: values.age,
          material_status: values.material_status,
          gender: values.gender,
          mobile_number: values.mobile_no,
          email: values.email,
          address: values.address,
          state: values.state,
          city: values.city,
          postal_code: values.postal_code,
          profile_avatar: upload.url ? upload.url : values.profile_avatar,
          doctor_profile: {
            department: values.department,
          },
        });
      } else if (userInfo?.role === "Admin") {
        await UpdateProfile({
          first_name: values.first_name,
          last_name: values.last_name,
          age: values.age,
          material_status: values.material_status,
          gender: values.gender,
          mobile_number: values.mobile_no,
          email: values.email,
          address: values.address,
          state: values.state,
          city: values.city,
          postal_code: values.postal_code,
          profile_avatar: upload.url ? upload.url : values.profile_avatar,
        });
      }
    },
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedFileTypes = ["jpg", "png", "jpeg", "jfif"];
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const isValidFileType = allowedFileTypes.includes(fileExtension);

      if (!isValidFileType) {
        setUpload((prevState) => ({
          ...prevState,
          message: "Upload only image",
        }));
        return;
      }
      Upload({ image: file });
      setUpload((prevState) => ({
        ...prevState,
        message: null,
      }));
    }
  };

  return (
    <>
      <AppHead title="Settings - Edit Profile - CHARITE" />
      <div className="p-10">
        <h1 className="font-[700] text-[20px] font-roboto text-[#272727] leading-9">
          Personal Information
        </h1>
        <form onSubmit={handleSubmit} className="mt-2 bg-white p-5 rounded-lg">
          <div className="flex items-center gap-6">
            {upload.loading ? (
              <div className="w-[80px] h-[80px] bg-[#0000003d] rounded-full flex justify-center items-center">
                <ButtonLoader_1 />
              </div>
            ) : (
              <div className="relative">
                {upload.url ? (
                  <img
                    className="rounded-full w-[80px] h-[80px]"
                    src={upload.url}
                    style={{ aspectRatio: "1/1", objectFit: "cover" }}
                    alt=""
                  />
                ) : (
                  <>
                    {userInfo?.profile_avatar && (
                      <img
                        className="rounded-full w-[80px] h-[80px]"
                        src={`${userInfo?.profile_avatar}`}
                        alt=""
                      />
                    )}
                    {!userInfo?.profile_avatar && (
                      <svg
                        className="w-[80px] h-[80px] text-gray-200 dark:text-gray-700"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                      </svg>
                    )}
                  </>
                )}
                <div className="flex items-center justify-center bg-primary text-white rounded-full p-1 border border-white absolute right-[0px] bottom-[0px]">
                  <label htmlFor="profile_avatar">
                    <RiEditFill className="edit cursor-pointer" />
                  </label>
                  <input
                    type="file"
                    id="profile_avatar"
                    name="profile_avatar"
                    accept=".jpg, .png, .jpeg, .jfif"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                </div>
              </div>
            )}
            <div className="flex flex-col gap-2">
              <h1 className="text-[#272727] text-[18px] font-[500] leading-5">
                Upload Your Picture
              </h1>
              <p className="text-[#788BA5] text-[12px] font-[400] leading-4">
                For Best Results, use an image at least 256px by 256px in
                <br />
                either .jpg or.png format
              </p>
              {upload.message && (
                <p className="text-red-700 text-xs">{upload.message}</p>
              )}
            </div>
          </div>
          {/* First Inputs Grid */}
          <div className="grid grid-cols-3 gap-4 w-full mt-6">
            <div className="w-full">
              <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                First Name
              </label>
              <input
                placeholder="First Name"
                type="text"
                name="first_name"
                value={values.first_name}
                onChange={handleChange}
                className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
              />
              {errors.first_name && touched.first_name && (
                <p className="text-red-700 text-xs mt-[2px]">
                  {errors.first_name}
                </p>
              )}
            </div>
            <div className="w-full">
              <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                Last Name
              </label>
              <input
                placeholder="Last Name"
                type="text"
                name="last_name"
                value={values.last_name}
                onChange={handleChange}
                className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
              />
              {errors.last_name && touched.last_name && (
                <p className="text-red-700 text-xs mt-[2px]">
                  {errors.last_name}
                </p>
              )}
            </div>
            <div className="w-full">
              <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                Age
              </label>
              <input
                placeholder="Age"
                type="text"
                name="age"
                value={values.age}
                onChange={handleChange}
                className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
              />
              {errors.age && touched.age && (
                <p className="text-red-700 text-xs mt-[2px]">{errors.age}</p>
              )}
            </div>
          </div>
          {/* Second Inputs Grid */}
          <div
            className={`${
              userInfo?.role === "Doctor"
                ? "grid grid-cols-3"
                : "grid grid-cols-2"
            } gap-4 w-full mt-6`}
          >
            {userInfo?.role === "Doctor" && (
              <div className="w-full">
                <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                  Department
                </label>
                <input
                  placeholder="Department"
                  type="text"
                  name="department"
                  value={values.department}
                  onChange={handleChange}
                  className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
                />
                {errors.department && touched.department && (
                  <p className="text-red-700 text-xs mt-[2px]">
                    {errors.department}
                  </p>
                )}
              </div>
            )}
            {userInfo?.is_patient && (
              <div className="w-full">
                <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                  Occupation
                </label>
                <input
                  placeholder="Occupation"
                  type="text"
                  name="occupation"
                  value={values.occupation}
                  onChange={handleChange}
                  className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
                />
                {errors.occupation && touched.occupation && (
                  <p className="text-red-700 text-xs mt-[2px]">
                    {errors.occupation}
                  </p>
                )}
              </div>
            )}
            <div className="w-full">
              <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                Material Status
              </label>
              <select
                name="material_status"
                values={values.material_status}
                onChange={handleChange}
                className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
              >
                {values.material_status ? (
                  <option disabled>Select Material Status</option>
                ) : (
                  <option value="" selected disabled>
                    Select Material Status
                  </option>
                )}
                <option value="single">Single</option>
                <option value="married">Married</option>
              </select>
              {errors.material_status && touched.material_status && (
                <p className="text-red-700 text-xs mt-[2px]">
                  {errors.material_status}
                </p>
              )}
            </div>
            <div className="w-full">
              <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                Gender
              </label>
              <div className="flex items-center gap-6 h-[36px] mt-1">
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    value="male"
                    name="gender"
                    values={values.gender}
                    checked={values.gender === "male"}
                    onChange={handleChange}
                  />
                  Male
                </div>
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    value="female"
                    name="gender"
                    values={values.gender}
                    checked={values.gender === "female"}
                    onChange={handleChange}
                  />
                  Female
                </div>
              </div>
              {errors.gender && touched.gender && (
                <p className="text-red-700 text-xs mt-[2px]">{errors.gender}</p>
              )}
            </div>
          </div>
          {/* Patient Grid */}
          {userInfo?.role === "Patient" && (
            <div className="grid grid-cols-4 gap-4 w-full mt-6">
              <div className="w-full">
                <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                  Blood Pressure
                </label>
                <input
                  placeholder="Blood Pressure"
                  type="text"
                  name="blood_pressure"
                  value={values.blood_pressure}
                  onChange={handleChange}
                  className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
                />
                {errors.blood_pressure && touched.blood_pressure && (
                  <p className="text-red-700 text-xs mt-[2px]">
                    {errors.blood_pressure}
                  </p>
                )}
              </div>
              <div className="w-full">
                <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                  Sugar
                </label>
                <input
                  placeholder="Sugar"
                  type="text"
                  name="sugar"
                  value={values.sugar}
                  onChange={handleChange}
                  className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
                />
                {errors.sugar && touched.sugar && (
                  <p className="text-red-700 text-xs mt-[2px]">
                    {errors.sugar}
                  </p>
                )}
              </div>
              <div className="w-full">
                <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                  Blood Group
                </label>
                <input
                  placeholder="Blood Group"
                  type="text"
                  name="blood_group"
                  value={values.blood_group}
                  onChange={handleChange}
                  className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
                />
                {errors.blood_group && touched.blood_group && (
                  <p className="text-red-700 text-xs mt-[2px]">
                    {errors.blood_group}
                  </p>
                )}
              </div>
              <div className="w-full">
                <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                  Height
                </label>
                <input
                  placeholder="Height"
                  type="text"
                  name="height"
                  value={values.height}
                  onChange={handleChange}
                  className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
                />
                {errors.height && touched.height && (
                  <p className="text-red-700 text-xs mt-[2px]">
                    {errors.height}
                  </p>
                )}
              </div>
            </div>
          )}
          {/* Third Inputs Grid */}
          <div className="grid grid-cols-2 gap-4 w-full mt-6">
            <div className="w-full">
              <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                Mobile Number
              </label>
              <input
                placeholder="Mobile Number"
                type="text"
                name="mobile_number"
                value={values.mobile_number}
                onChange={handleChange}
                className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
              />
              {errors.mobile_number && touched.mobile_number && (
                <p className="text-red-700 text-xs mt-[2px]">
                  {errors.mobile_number}
                </p>
              )}
            </div>
            <div className="w-full">
              <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                Email ID
              </label>
              <input
                placeholder="Email"
                type="text"
                name="email"
                value={values.email}
                readOnly
                className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none"
              />
              {errors.email && touched.email && (
                <p className="text-red-700 text-xs mt-[2px]">{errors.email}</p>
              )}
            </div>
          </div>
          {/* Fifth Inputs Grid */}
          <div className="grid grid-cols-1 gap-4 w-full mt-6">
            <div className="w-full">
              <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                Address
              </label>
              <input
                placeholder="Address"
                type="text"
                name="address"
                value={values.address}
                onChange={handleChange}
                className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
              />
              {errors.address && touched.address && (
                <p className="text-red-700 text-xs mt-[2px]">
                  {errors.address}
                </p>
              )}
            </div>
          </div>
          {/* Sixth Inputs Grid */}
          <div className="grid grid-cols-3 gap-4 w-full mt-6">
            <div className="w-full">
              <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                State
              </label>
              <input
                placeholder="State"
                type="text"
                name="state"
                value={values.state}
                onChange={handleChange}
                className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
              />
              {errors.state && touched.state && (
                <p className="text-red-700 text-xs mt-[2px]">{errors.state}</p>
              )}
            </div>
            <div className="w-full">
              <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                City
              </label>
              <input
                placeholder="City"
                type="text"
                name="city"
                value={values.city}
                onChange={handleChange}
                className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
              />
              {errors.city && touched.city && (
                <p className="text-red-700 text-xs mt-[2px]">{errors.city}</p>
              )}
            </div>
            <div className="w-full">
              <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                Postal Code
              </label>
              <input
                placeholder="Postal Code"
                type="text"
                name="postal_code"
                value={values.postal_code}
                onChange={handleChange}
                className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] mt-1 bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
              />
              {errors.postal_code && touched.postal_code && (
                <p className="text-red-700 text-xs mt-[2px]">
                  {errors.postal_code}
                </p>
              )}
            </div>
          </div>
          {/* Form Buttons */}
          <div className="flex items-center gap-3 mt-5">
            <Link
              to="/settings"
              className="bg-transparent border border-[#D8E2EB] text-[#787878] w-[80px] h-[40px] flex justify-center items-center rounded-lg text-[14px]"
            >
              Cancel
            </Link>
            <button
              type="submit"
              className="bg-primary text-white w-[160px] h-[40px] flex justify-center items-center rounded-lg text-[14px]"
              disabled={loading}
            >
              {loading ? <ButtonLoader_1 /> : "Save Profile"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default EditProfile;
