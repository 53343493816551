import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { getAccessToken } from "../storage/storage";
import { useNavigate } from "react-router-dom";

const useChangePassword = () => {
  const Navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);
  const token = getAccessToken();

  const ChangePassword = async (payload, action) => {
    setLoading(true);
    await axios
      .post(`${BASE_URL}/api/change-password/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        action.resetForm();
        Swal.fire({
          icon: "success",
          text: "Your password successfully changed!",
          customClass: {
            confirmButton: "custom-green-button",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            Navigate("/");
          }
        });
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          text:
            error?.response?.data?.message ||
            "Oops! Something went wrong on our end. Please give us a moment to fix it. Feel free to try again later",
          customClass: {
            confirmButton: "custom-red-button",
          },
        });
      });
  };

  return { loading, ChangePassword };
};

export default useChangePassword;
