import React from "react";
import Pagination from "@mui/material/Pagination";

function Paginations(props) {
  console.log(props.totalPages);
  console.log(props.currentPage);
  return (
    <>
      {props.totalPages > 1 && (
        <Pagination
          sx={{
            "& .MuiPaginationItem-page.Mui-selected": {
              backgroundColor: "#3B82F6 !important",
              color: "#ffffff !important",
              borderColor: "#3B82F6 !important"
            },
            "& .MuiPaginationItem-root": {
              "&:hover": {
                background: "#3B82F6",
                borderColor: "#3B82F6",
                color: "#ffff"
              }
            }
          }}
          count={props.totalPages}
          page={props.currentPage}
          onChange={props.handlePageChange}
          variant="outlined"
          shape="rounded"
        />
      )}
    </>
  );
}

export default Paginations;
