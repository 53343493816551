import React, { useContext, useRef } from "react";
import AppHead from "../../../seo/AppHead";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { TbUsersPlus } from "react-icons/tb";
import CheckBox from "../../../components/shared/CheckBox";
import useGetTreatmentForm from "../../../hooks/useGetTreatmentFormTemplate";
import { HiOutlineChevronRight } from "react-icons/hi";
import { FaPlus } from "react-icons/fa";
import ShareTreatmentPlanModal from "../../../components/modals/ShareTreatmentPlanModal";
import { treatmentData } from "../../../staticData/TreatmentFormData";
import useAddForm from "../../../hooks/useAddForm";
import { ButtonLoader_1 } from "../../../components/shared/Loaders";
import { RiUploadCloud2Line } from "react-icons/ri";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { FaRegFileLines } from "react-icons/fa6";
import Swal from "sweetalert2";
import axios from "axios";
import { getAccessToken } from "../../../storage/storage";
import GlobalContext from "../../../context/GlobalContext";

function DoctorTreatmentForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedPatient = {} } = location.state || {};
  const [treatmentFormData, setTreatmentFormData] = useState(treatmentData);

  const [activeDropdown, setActiveDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const handleShow = () => {
    setActiveDropdown(!activeDropdown);
  };

  const handleCloseDropdown = () => {
    setActiveDropdown(false);
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        e.target.closest(".small-circle-main") ||
        (dropdownRef.current && dropdownRef.current.contains(e.target))
      ) {
        return;
      }
      handleCloseDropdown();
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleInputChange = (categoryIndex, questionIndex, value) => {
    const updatedFormData = { ...treatmentFormData };
    updatedFormData.categories[categoryIndex].questions[
      questionIndex
    ].answers.answer_text = value;
    setTreatmentFormData(updatedFormData);
  };

  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const token = getAccessToken();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [imageLoading, setImageLoading] = useState(false);

  const handleImageUpload = (e, categoryIndex, questionIndex) => {
    const image = e.target.files[0];
    if (image) {
      const formData = new FormData();
      formData.append("image", image);
      setImageLoading(true);
      axios
        .post(`${BASE_URL}/api/upload-image/`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data"
          }
        })
        .then((response) => {
          const imageUrl = response?.data?.image_url;
          setImage(image);
          setImageUrl(imageUrl);
          setImageLoading(false);

          const updatedFormData = { ...treatmentFormData };
          updatedFormData.categories[categoryIndex].questions[
            questionIndex
          ].answers.file_upload = imageUrl;
          setTreatmentFormData(updatedFormData);
          Swal.fire({
            icon: "success",
            text: "Image Successfully Uploaded!",
            customClass: {
              confirmButton: "custom-green-button"
            }
          });
        })
        .catch((error) => {
          setImageLoading(false);
          Swal.fire({
            icon: "error",
            text: "Oops! Something went wrong while uploading the image."
          });
        });
    }
  };

  const RemoveImage = (categoryIndex, questionIndex) => {
    URL.revokeObjectURL(imageUrl);
    setImage(null);
    setImageUrl("");

    const updatedFormData = { ...treatmentFormData };
    updatedFormData.categories[categoryIndex].questions[
      questionIndex
    ].answers.file_upload = null;
    setTreatmentFormData(updatedFormData);
  };

  // assign treatment form to patient
  const { AddForm, loading } = useAddForm();
  const handleSubmit = async () => {
    const modifiedFormData = JSON.parse(JSON.stringify(treatmentFormData));
    modifiedFormData.categories.forEach((category, categoryIndex) => {
      category.questions = category.questions.map((question, questionIndex) => {
        question.question_type = question.question_type.toUpperCase();
        if (question.question_type === "FILE") {
          const imageUrl =
            treatmentFormData.categories[categoryIndex].questions[questionIndex]
              .answers.file_upload;
          if (imageUrl) {
            question.answers.file_upload = imageUrl;
          }
        } else if (question.question_type === "DROPDOWN") {
          delete question.dropdown_options;
        }
        return question;
      });
    });

    const payload = {
      patient: selectedPatient.id,
      categories: modifiedFormData.categories
    };
    await AddForm(payload, `/api/treatment-forms/`);
  };

  return (
    <>
      <AppHead title="Dashboard - CHARITE" />
      <div className="p-8">
        <div className="flex justify-between items-center mt-2">
          <h1 className="text-[#272727] text-[24px] font-[600] ">
            Treatment Form
          </h1>
          {/* <div className=" border border-[#3B82F6] rounded-[5px] cursor-pointer relative  ">
            <div
              className="mall-circle-main justify-center items-center flex gap-2  p-2"
              onClick={handleShow}
            >
              <span className="text-[#3B82F6] text-[14px]">Share</span>
              <PiShareFat className="text-[#3B82F6] text-[20px] s" />
            </div>
            <TreatmentDropdown
              dropdownRef={dropdownRef}
              activeDropdown={activeDropdown}
              setActiveDropdown={setActiveDropdown}
              selectedPatient={selectedPatient}
            />
          </div> */}
        </div>

        <div className="w-full h-auto bg-white p-8 mt-2 rounded ">
          {treatmentFormData?.categories?.map((category, categoryIndex) => (
            <div key={categoryIndex}>
              <h3 className="text-[#272727] text-[20px] font-[600] ">
                {category.category_name}
              </h3>
              {category.questions?.map((question, questionIndex) => (
                <div key={questionIndex} className="mt-4">
                  <label
                    htmlFor={`question_${categoryIndex}_${questionIndex}`}
                    className="block mb-2 text-[16px] text-[#272727] font-bold"
                  >
                    {question.question_statement}
                  </label>

                  {question.question_type.toUpperCase() === "DROPDOWN" && (
                    <select
                      id={`question_${categoryIndex}_${questionIndex}`}
                      className="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-[5px] border border-gray-300 outline-none focus:border-primary"
                      value={question.answers.answer_text}
                      onChange={(e) =>
                        handleInputChange(
                          categoryIndex,
                          questionIndex,
                          e.target.value
                        )
                      }
                    >
                      <option value="" disabled>
                        Select {question.question_statement}
                      </option>
                      {question.dropdown_options?.map((option, optIndex) => (
                        <option key={optIndex} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  )}
                  {question.question_type.toUpperCase() === "TEXT" && (
                    <textarea
                      id={`question_${categoryIndex}_${questionIndex}`}
                      className="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-[5px] border border-gray-300 outline-none focus:border-primary"
                      placeholder={`Enter ${question.question_statement}`}
                      value={question.answers.answer_text}
                      onChange={(e) =>
                        handleInputChange(
                          categoryIndex,
                          questionIndex,
                          e.target.value
                        )
                      }
                      rows="4"
                    />
                  )}
                  {question?.question_type.toUpperCase() === "FILE" && (
                    <>
                      <div
                        className={`w-full border-dashed border border-[#3B82F6] flex flex-col items-center justify-center p-7 rounded-[5px] h-[170px] relative ${
                          imageLoading ? "opacity-50" : ""
                        }`}
                      >
                        <input
                          type="file"
                          accept=".jpg, .png, .jpeg, .jfif"
                          id={`question_${categoryIndex}_${questionIndex}`}
                          style={{ display: "none" }}
                          onChange={(e) =>
                            handleImageUpload(e, categoryIndex, questionIndex)
                          }
                        />
                        <RiUploadCloud2Line className="text-[45px] text-primary" />
                        <div className="flex items-center gap-1 text-[16px] font-[600]">
                          <p> Drag & drop images or</p>
                          <label
                            className="text-primary cursor-pointer underline text-[16px] font-[600]"
                            htmlFor={`question_${categoryIndex}_${questionIndex}`}
                          >
                            Browse
                          </label>
                        </div>
                        <p className="text-[12px] font-[400] text-[#788BA5] mt-2">
                          Supports: PNG, JPG, JPEG (Max. File size: 2 MB)
                        </p>

                        {/* Loading overlay */}
                        {imageLoading && (
                          <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
                            <span className="text-gray-700 font-semibold">
                              Loading...
                            </span>
                          </div>
                        )}
                      </div>

                      {image && (
                        <div className="w-full text-sm text-gray-900 bg-white rounded-[5px] border border-gray-300 h-[70px] mt-2 px-3 flex justify-between items-center">
                          <div className="flex items-center gap-2">
                            <FaRegFileLines className="text-gray-300 text-[25px]" />
                            <span className="text-sm text-[#788BA5]">
                              {image?.name}
                            </span>
                          </div>
                          <button
                            onClick={() =>
                              RemoveImage(categoryIndex, questionIndex)
                            }
                            className="text-red-400 text-sm"
                          >
                            <MdOutlineDeleteOutline className="text-[30px]" />
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              ))}
            </div>
          ))}
          <ShareTreatmentPlan selectedPatient={selectedPatient} />
          <div>
            <hr className="mt-6" />
            <div className="flex justify-end mt-4">
              <div
                onClick={() => navigate(-1)}
                className="shadow-sm text-gray-400 py-2 px-4 rounded-lg font-roboto font-bold text-[14px] ml-2 border-2 cursor-pointer"
              >
                Cancel
              </div>
              <button
                onClick={handleSubmit}
                className="bg-blue-500 text-white py-2 px-6 rounded-lg font-roboto font-bold text-[14px] ml-2 flex justify-center items-center"
              >
                {loading ? <ButtonLoader_1 /> : "Share"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DoctorTreatmentForm;

function ShareTreatmentPlan({ selectedPatient }) {
  const [action, setAction] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { updateResponse } = useContext(GlobalContext);
  const { treatmentForm, GetTreatmentForm } = useGetTreatmentForm();
  useEffect(() => {
    GetTreatmentForm(`/api/doctor/${selectedPatient.id}/treatment-plans/`);
  }, [updateResponse]);

  //   convert date and time in formal form
  function formatDate(apiDate) {
    const date = new Date(apiDate);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return `${day}-${month}-${year} ${formattedHours}:${formattedMinutes}`;
  }
  return (
    <>
      <label
        for="countries_disabled"
        class="block mb-2 text-[24px] font-[600] text-[#272727] mt-5"
      >
        Treatment Plan
      </label>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 w-full ">
        {treatmentForm?.data?.results?.map((data, index) => (
          <div
            key={index}
            className="border border-gray-200 rounded-xl cursor-pointer h-auto"
          >
            <div className="flex flex-col p-3 gap-5">
              <div className="flex items-center ">
                <h1 className="text-[16px] font-[600] text-[#272727]">
                  Treatment Plan {index + 1}
                </h1>
              </div>
              <div className="w-[25%] text-[10px]  font-[600] bg-[#46F7B7] rounded  text-center py-1">
                {data.status}
              </div>
              <div className="flex justify-between items-center">
                <p className="text-[14px] font-[400] text-[#787878]">Sent</p>
                <p className="text-[13px] font-[500] text-[#788BA5] flex items-center gap-2">
                  {data?.sent ? formatDate(data?.sent) : ""}
                </p>
              </div>
            </div>

            <hr />

            <div
              onClick={() => {
                setIsOpenModal(true);
              }}
              className="flex justify-between items-center text-primary text-[13px] p-2"
            >
              <p className="font-[500]">View Form</p>
              <HiOutlineChevronRight className="text-[18px]" />
            </div>
          </div>
        ))}

        <div
          onClick={() => {
            setIsOpenModal(true);
            setAction("view");
          }}
          className="border border-primary px-5 py-7 rounded-xl flex flex-col items-center justify-center gap-6 cursor-pointer bg-white shadow-md"
        >
          <FaPlus className="text-white bg-primary rounded-[50%] p-1 text-[25px]" />
          <p className="text-[20px] font-[600] text-[#272727] text-center">
            Add Treatment <br /> Plan
          </p>
        </div>
      </div>

      <ShareTreatmentPlanModal
        isOpen={isOpenModal}
        onClose={() => {
          setIsOpenModal(false);
          setAction(null);
        }}
        selectedPatient={selectedPatient}
        action={action}
      />
    </>
  );
}

function TreatmentDropdown({
  activeDropdown,
  dropdownRef,
  setActiveDropdown,
  selectedPatient
}) {
  const [selectedIds, setSelectedIds] = useState([]);
  const handleCheckboxChange = (id) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };
  const { treatmentForm, setTreatmentForm, GetTreatmentForm } =
    useGetTreatmentForm();
  useEffect(() => {
    GetTreatmentForm(`/api/patient-guardian-data/${selectedPatient.id}/`);
  }, []);
  console.log({ treatmentForm });

  return (
    <div>
      {activeDropdown && (
        <div
          className="w-[400px] h-[auto] bg-[white] absolute top-[40px] right-0 rounded-lg border-[1px] border-[#E4E7EC] p-[15px] dropdown-content z-50"
          ref={dropdownRef}
        >
          <div className="flex justify-between items-center">
            <div className="p-2 border-[1px] border-[#E4E7EC] rounded-md text-[25px] cursor-pointer">
              <TbUsersPlus className="text-[#344054]" />
            </div>
            <AiOutlineClose
              className="text-[#667085] text-[18px] cursor-pointer"
              onClick={() => setActiveDropdown(!activeDropdown)}
            />
          </div>
          <div>
            <h1 className="18px text-[#272727] font-bold mt-2">
              Share with people
            </h1>
            <p className="text-[14px] text-[#788BA5]">
              You are about to share the treatment plan with the <br />
              selected patient and their guardian.
            </p>
            <div className="flex gap-2 mt-4 items-start">
              <CheckBox
                onChange={() => handleCheckboxChange(1)}
                checked={selectedIds.includes(1)}
              />
              <div>
                <span className="text-[14px] font-medium">Patient</span>
                <br />
                <span className="text-[14px] text-[#787878] font-normal">
                  Flores
                </span>
              </div>
            </div>
            <div className="flex gap-2 mt-2 items-start">
              <CheckBox
                onChange={() => handleCheckboxChange(2)}
                checked={selectedIds.includes(2)}
              />
              <div>
                <span className="text-[14px] font-medium">Guardian 01</span>
                <br />
                <span className="text-[14px] text-[#787878] font-normal">
                  John Deee
                </span>
              </div>
            </div>
            <div className="flex gap-2 mt-2 items-start">
              <CheckBox
                onChange={() => handleCheckboxChange(3)}
                checked={selectedIds.includes(3)}
              />
              <div>
                <span className="text-[14px] font-medium">Guardian 02</span>
                <br />
                <span className="text-[14px] text-[#787878] font-normal">
                  Aliciea
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
