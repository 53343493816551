import React, { useEffect, useRef, useState } from "react";
import { FaPlus, FaTimes, FaTrash } from "react-icons/fa";

const AddQuestionA1Modal = ({
  isOpen,
  onClose,
  onSave,
  questionData,
  setQuestionData,
  isEditing
}) => {
  const [errors, setErrors] = useState({ question: "", options: [] });
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
        setErrors({ question: "", options: [] });
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setQuestionData((prev) => ({
      ...prev,
      [name]: value
    }));
    setErrors((prev) => ({ ...prev, question: "" }));
  };

  const handleOptionChange = (index, value) => {
    const updatedOptions = questionData.options.map((opt, i) =>
      i === index ? { ...opt, text: value } : opt
    );
    setQuestionData((prev) => ({
      ...prev,
      options: updatedOptions
    }));
    setErrors((prev) => {
      const newOptionsErrors = [...prev.options];
      newOptionsErrors[index] = "";
      return { ...prev, options: newOptionsErrors };
    });
  };

  const handleAddOption = () => {
    if ((questionData.options || []).length < 10) {
      setQuestionData((prev) => ({
        ...prev,
        options: [...(prev.options || []), { text: "" }]
      }));
      setErrors((prev) => ({
        ...prev,
        options: [...(prev.options || []), ""]
      }));
    }
  };

  const handleRemoveOption = (index) => {
    const updatedOptions = questionData.options.filter((_, i) => i !== index);
    const updatedErrors = errors.options.filter((_, i) => i !== index);
    setQuestionData((prev) => ({
      ...prev,
      options: updatedOptions
    }));
    setErrors((prev) => ({
      ...prev,
      options: updatedErrors
    }));
  };

  const handleSave = () => {
    let hasErrors = false;
    const newErrors = { question: "", options: [] };

    if (!questionData.question_statement?.trim()) {
      newErrors.question = "* Enter Question";
      hasErrors = true;
    }

    questionData.options.forEach((option, index) => {
      if (!option.text?.trim()) {
        newErrors.options[index] = "Option cannot be empty.";
        hasErrors = true;
      }
    });
    setErrors(newErrors);
    if (!hasErrors) {
      onSave();
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div ref={modalRef} className="bg-white rounded-lg w-[500px] max-w-md">
        <div className="flex justify-between items-center mb-4 px-6 pt-6">
          <h2 className="text-lg font-bold font-roboto">
            {isEditing ? "Edit Question" : "Add New Question"}
          </h2>
          <button
            onClick={() => {
              onClose();
              setErrors({ question: "", options: [] });
            }}
            className="text-gray-500 hover:text-gray-700"
          >
            <FaTimes />
          </button>
        </div>

        <div className="max-h-[370px] overflow-y-scroll px-6">
          <div className="mb-4">
            <label
              htmlFor=""
              className="text-[15px] font-bold font-roboto text-gray-600"
            >
              Enter Question:
            </label>
            <input
              type="text"
              name="question_statement"
              value={questionData.question_statement}
              onChange={handleChange}
              placeholder="Enter question"
              className="border border-[#E1E1E2] p-2 w-full rounded outline-none"
            />
            {errors.question && (
              <p className="text-red-500 text-sm mb-2">{errors.question}</p>
            )}
          </div>

          <div className="mb-4">
            {questionData?.options?.map((option, index) => (
              <div key={index} className="mb-2">
                <label className="text-[15px] font-bold font-roboto text-gray-600">
                  Enter Option {index + 1}
                </label>
                <div className="flex items-center border border-[#E1E1E2] rounded">
                  <input
                    type="text"
                    value={option.text}
                    onChange={(e) => handleOptionChange(index, e.target.value)}
                    placeholder={`Option ${index + 1}`}
                    className="p-2 w-full outline-none mb-1"
                  />
                  <button
                    onClick={() => handleRemoveOption(index)}
                    className="text-red-400 mx-2"
                  >
                    <FaTrash />
                  </button>
                </div>
                {errors.options[index] && (
                  <p className="text-red-500 text-sm mb-1">
                    {errors.options[index]}
                  </p>
                )}
              </div>
            ))}
            <div
              className="flex bg-primary gap-2 items-center text-white w-[120px] py-2 px-2 rounded font-roboto font-bold text-[14px] cursor-pointer"
              onClick={handleAddOption}
            >
              <FaPlus />
              <p>Add Option</p>
            </div>
          </div>
        </div>

        <div className="px-6 pb-6 flex justify-end">
          <button
            onClick={() => {
              onClose();
              setErrors({ question: "", options: [] });
            }}
            className="text-gray-600 py-2 px-4 rounded font-roboto font-bold text-[14px] ml-2"
            style={{ boxShadow: "0px 0px 7px gray" }}
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="bg-blue-500 text-white py-2 px-6 rounded font-roboto font-bold text-[14px] ml-2"
          >
            {isEditing ? "Save" : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddQuestionA1Modal;
