const config = {
  seo: {
    title: "CHARITE",
    description: "",
    url: "",
    image: "",
    site_name: "CHARITE",
    twitter: {
      handle: "@handle",
      cardType: "summary_large_image",
    },
  },
  baseUrl: "",
  assetsUrl: "",
};

export default config;
