/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import AppHead from "../../seo/AppHead";
import { Link, useLocation } from "react-router-dom";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import useGetPatients from "../../hooks/useGetPatients";
import { CiSearch } from "react-icons/ci";
import Dropdown from "../../components/shared/Dropdown";
import { FaPlus } from "react-icons/fa";
import { HiOutlineDotsVertical } from "react-icons/hi";
import ChoosePatientModal from "../../components/modals/ChoosePatientModal";
import Paginations from "../../components/shared/Paginations";
import ShowMessage from "../../components/shared/ShowMessage";
import GlobalContext from "../../context/GlobalContext";
import { debounce } from "lodash";
import { TableSkelton_4 } from "../../components/shared/Skeltons";
import DeleteModal from "../../components/modals/DeleteModal";
import DeleteAssignedModal from "../../components/modals/DeleteAssignedModal";

function DoctorDetails() {
  const location = useLocation();
  const { selectedDoctor = {} } = location.state || {};

  //   convert date and time in formal form
  function formatDate(apiDate) {
    const date = new Date(apiDate);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year} `;
  }
  return (
    <>
      <AppHead title="Dashboard - CHARITE" />
      <div className="p-10">
        <div className="flex items-center gap-2">
          <Link
            to="/doctors"
            className="text-[#788BA5] cursor-pointer bg-transparent text-[14px] font-[400] hover:text-primary"
          >
            Doctors
          </Link>
          <MdOutlineKeyboardArrowRight className="text-[#788BA5] text-[20px] " />
          <p className="text-primary text-[14px] font-[400]">Doctor Profile</p>
        </div>
        <h1 className="text-[#272727] text-[24px] font-[600] mt-2">
          Doctor Profile
        </h1>
        <div
          className="w-full bg-white rounded-xl p-6 mt-4 flex"
          style={{ boxShadow: "0px 0px 7px #e0e0e0" }}
        >
          <div className="flex flex-col items-center w-[25%] mr-4">
            <svg
              className="w-[130px] h-[130px] rounded-full text-gray-200 dark:text-gray-700"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
            </svg>
            <h1 className="text-[#272727] text-[18px] font-[600] font-roboto mt-1">
              {selectedDoctor?.first_name} {selectedDoctor?.last_name}
            </h1>
            <p className="text-primary text-[14px] font-[400] font-roboto bg-[#DDE9FC] py-1 px-3 rounded">
              {selectedDoctor?.doctor_profile?.department}
            </p>
          </div>
          <div className=" border-l-2 border-gray-100 w-[38%] pl-10 flex flex-col justify-between">
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Phone No.
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedDoctor?.mobile_no}
              </p>
            </div>
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Email
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedDoctor?.email}
              </p>
            </div>{" "}
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Gender
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedDoctor?.gender}
              </p>
            </div>
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Marital status
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedDoctor?.marital_status}
              </p>
            </div>{" "}
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Date of Birth
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedDoctor?.date_of_birth
                  ? formatDate(selectedDoctor?.date_of_birth)
                  : ""}
              </p>
            </div>{" "}
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto"></h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedDoctor?.status}
              </p>
            </div>
          </div>
          <div className="w-[37%] pl-10 flex flex-col justify-between gap-4">
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Education
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedDoctor?.doctor_profile?.education}
              </p>
            </div>
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Specialization
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedDoctor?.doctor_profile?.department}
              </p>
            </div>{" "}
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                State
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedDoctor?.state}
              </p>
            </div>{" "}
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                City
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedDoctor?.city}
              </p>
            </div>{" "}
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Postal Code
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedDoctor?.postal_code}
              </p>
            </div>
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Address
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedDoctor?.address}
              </p>
            </div>
          </div>
        </div>
        <AssignPatients selectedDoctor={selectedDoctor} />
      </div>
    </>
  );
}

export default DoctorDetails;

function AssignPatients({ selectedDoctor }) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [filters, setFilters] = useState({ name: "Name (A-Z)", value: "asc" });
  const [query, setQuery] = useState("");
  const { patients, setPatients, GetPatients } = useGetPatients();
  const { updateResponse } = useContext(GlobalContext);
  const debouncedSearch = useCallback(
    debounce((value) => {
      setPatients((prevState) => ({ ...prevState, currentPage: 1 }));
      GetPatients(
        `/api/admin/doctor/${selectedDoctor?.doctor_profile?.id}/patients/?page=${patients.currentPage}&search=${value}&sorting=${filters.value}`
      );
    }, 1000),
    []
  );
  const handleChange = (e) => {
    const { value } = e.target;
    setQuery(value);
    debouncedSearch(value);
  };
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);
  useEffect(() => {
    GetPatients(
      `/api/admin/doctor/${selectedDoctor?.doctor_profile?.id}/patients/?page=${patients.currentPage}&search=${query}&sorting=${filters.value}`
    );
  }, [patients.currentPage, updateResponse, filters]);

  const handlePageChange = (event, value) => {
    setPatients((prevState) => ({ ...prevState, currentPage: value }));
  };

  // dropdown for delete
  const [selected, setSelected] = useState(null);

  const [activeDropdown, setActiveDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const handleShow = (index) => {
    if (activeDropdown === index) {
      setActiveDropdown(!activeDropdown);
    } else {
      setActiveDropdown(index);
    }
  };
  const handleCloseDropdown = () => {
    setActiveDropdown(false);
  };
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        e.target.closest(".small-circle-main") ||
        (dropdownRef.current && dropdownRef.current.contains(e.target))
      ) {
        return;
      }
      handleCloseDropdown();
    };
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  return (
    <>
      <div className="mb-2 mt-7 flex justify-between items-center w-full">
        <div className=" flex items-center w-[70%]">
          <div className="flex items-center justify-center">
            <h1 className="font-[700] text-[20px] font-roboto text-[#272727] w-[130px] ">
              Patients List
            </h1>
            <p className="font-[400] text-[16px] font-roboto text-primary">
              ({patients?.data?.count})
            </p>
          </div>
          <div className="border rounded-xl  flex items-center bg-white w-[100%] px-2 ml-2">
            <CiSearch className="text-[25px] text-[#788BA5]" />
            <input
              type="search"
              value={query}
              onChange={handleChange}
              placeholder="Search by name or email"
              className="w-full p-2 outline-none border-none"
            />
          </div>
        </div>
        <Dropdown
          options={[
            { name: "Name (A-Z)", value: "asc" },
            { name: "Name (Z-A)", value: "desc" },
            { name: "Age (Youngest First)", value: "youngest" },
            { name: "Age (Oldest First)", value: "oldest" }
          ]}
          selected={filters}
          onChange={(option) =>
            setFilters({ name: option.name, value: option.value })
          }
        />
      </div>
      <div
        className="w-full auto bg-white rounded-xl  p-4"
        style={{ boxShadow: "0px 0px 7px #e0e0e0" }}
      >
        {patients.loading ? (
          <>
            {Array.from({ length: 2 }).map((_, index) => (
              <TableSkelton_4 key={index} />
            ))}
          </>
        ) : (
          <>
            <div className="grid grid-cols-4 gap-4 w-full">
              {patients?.data?.results?.map((item, index) => (
                <div key={index} className="border p-4 rounded-xl">
                  <div className="flex justify-between items-center text-[#788BA5] relative">
                    <p className="text-[14px] font-[500]">2d ago</p>
                    <HiOutlineDotsVertical
                      onClick={() => handleShow(index)}
                      className="cursor-pointer "
                    />
                    {activeDropdown === index && (
                      <div
                        className=" absolute  w-[100px] bg-white flex flex-col rounded right-0 top-6 z-20"
                        ref={dropdownRef}
                        style={{ boxShadow: "0px 0px 7px #e0e0e0" }}
                      >
                        <p
                          onClick={() => {
                            setIsOpen(!isOpen);
                            setSelected(item);
                          }}
                          className="px-3 py-2 cursor-pointer font-[400] text-[13px] font-roboto text-[#787878] hover:bg-[#F5F5FE] hover:text-primary"
                        >
                          Remove
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col items-center justify-center">
                    <svg
                      className="w-[50px] h-[50px] rounded-full text-gray-200 dark:text-gray-700"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                    </svg>
                    <h1 className="text-[#272727] text-[18px] font-[600] font-roboto mt-1">
                      {item?.user?.first_name} {item?.user?.last_name}
                    </h1>
                    <p className="text-[#F6833B] text-[10px] font-[500] font-roboto bg-red-100 py-1 px-3 rounded mt-1">
                      {item?.disease}
                    </p>
                  </div>
                </div>
              ))}

              <div
                onClick={() => setIsOpenModal(true)}
                className="border border-primary px-5 py-7 rounded-xl flex flex-col items-center justify-center gap-6 cursor-pointer"
              >
                <FaPlus className="text-white bg-primary rounded-[50%] p-1 text-[25px]" />
                <p className="text-[18px] font-[600] text-[#272727] text-center">
                  Assign Patient
                </p>
              </div>
            </div>
            <div className="w-full flex justify-between items-center mt-2">
              <p className="text-[#272727] font-roboto text-[14px] font-[400] leading-5">
                {patients?.data?.results?.length > 0 &&
                  patients?.data?.display_string}
              </p>
              <Paginations
                totalPages={patients.totalPages}
                currentPage={patients.currentPage}
                handlePageChange={handlePageChange}
              />
            </div>
            {!patients.loading && patients.message && (
              <div className="bg-white flex justify-center items-center w-full h-[300px]">
                <ShowMessage message={patients.message} />
              </div>
            )}
          </>
        )}
      </div>
      <ChoosePatientModal
        setIsOpenModal={setIsOpenModal}
        isOpenModal={isOpenModal}
        selectedDoctor={selectedDoctor}
      />
      <DeleteAssignedModal
        setIsOpenModal={setIsOpen}
        isOpenModal={isOpen}
        setSelected={setSelected}
        url={`/api/admin/remove-patient-from-doctor/?patient_id=${selected?.id}&doctor_id=${selectedDoctor?.doctor_profile?.id}`}
      />
    </>
  );
}
