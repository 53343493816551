import React, { useContext } from "react";
import AppHead from "../../seo/AppHead";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext";

function SettingsPage() {
  const { userInfo } = useContext(GlobalContext);
  return (
    <>
      <AppHead title="Settings - CHARITE" />
      <div className="p-10">
        <h1 className="font-[700] text-[20px] font-roboto text-[#272727] leading-9">
          Settings
        </h1>
        <div className="mt-2">
          <div className="text-[#272727] text-[18px] font-[600] leading-6 bg-[#FAFAFA] w-full h-[50px] flex items-center px-4 rounded-ss-lg rounded-t-lg border border-[#DEE4ED]">
            Account Details
          </div>
          <div className="text-[#272727] text-[16px] font-[400] leading-6 bg-white w-full h-[60px] flex items-center justify-between px-4 border border-t-0 border-[#DEE4ED]">
            <p>{userInfo?.email}</p>
            <IoIosArrowForward className="text-[#788BA5]" />
          </div>
          <Link
            to="/settings/change-password"
            className="text-[#272727] text-[16px] font-[400] leading-6 bg-white w-full h-[60px] flex items-center justify-between px-4 border border-t-0 border-[#DEE4ED]"
          >
            <p>Password:*********</p>
            <IoIosArrowForward className="text-[#788BA5]" />
          </Link>
          <Link
            to="/settings/edit-profile"
            className="text-[#272727] text-[16px] font-[400] leading-6 bg-white w-full h-[60px] flex items-center justify-between px-4 rounded-ee-lg rounded-b-lg border border-t-0 border-[#DEE4ED]"
          >
            <p>Edit Profile Setting</p>
            <IoIosArrowForward className="text-[#788BA5]" />
          </Link>
        </div>
      </div>
    </>
  );
}

export default SettingsPage;
