import React, { useEffect, useState } from "react";
import AppHead from "../../seo/AppHead";
import Dropdown from "../../components/shared/Dropdown";
import { HiOutlineChevronRight } from "react-icons/hi";
import Paginations from "../../components/shared/Paginations";
import ShowMessage from "../../components/shared/ShowMessage";
import { useNavigate } from "react-router-dom";
import useGetMedicalHistoryForm from "../../hooks/useGetMedicalHistoryForm";
import { TableSkelton_5 } from "../../components/shared/Skeltons";
import useGetTreatmentForm from "../../hooks/useGetTreatmentFormTemplate";

function PatientTreatmentPage() {
  const Navigate = useNavigate();
  const [filters, setFilters] = useState({
    name: "All",
    value: "all"
  });

  const { treatmentForm, setTreatmentForm, GetTreatmentForm } =
    useGetTreatmentForm();
  useEffect(() => {
    GetTreatmentForm(
      `/api/patient/treatment-plans/?page=${treatmentForm.currentPage}`
    );
  }, [treatmentForm.currentPage]);

  const handlePageChange = (event, value) => {
    setTreatmentForm((prevState) => ({
      ...prevState,
      currentPage: value,
      loading: true
    }));
  };
  const treatmentPerPage = 12;
  const startIndex = (treatmentForm.currentPage - 1) * treatmentPerPage;

  //   convert date and time in formal form
  function formatDate(apiDate) {
    const date = new Date(apiDate);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return `${day}-${month}-${year} ${formattedHours}:${formattedMinutes}`;
  }
  return (
    <>
      <AppHead title="Dashboard - CHARITE" />
      <div className="p-10">
        <div className="bg-white p-6 rounded">
          <div className="flex items-center justify-between mb-3">
            <h1 className="text-[24px] font-[600] text-[#272727]">
              Treatment Plan{" "}
              <span className="text-primary text-[16px] font-[400]">
                ({treatmentForm?.data?.results?.length})
              </span>
            </h1>
            <Dropdown
              options={[
                { name: "All", value: "all" },
                { name: "Dr. John Smith", value: "Dr. John Smith" },
                { name: "Dr. Emily Johnson", value: "Dr. Emily Johnson" },
                {
                  name: "Dr. Michael Williams",
                  value: "Dr. Michael Williams"
                },
                {
                  name: "Dr. Michael Williams",
                  value: "Dr. Michael Williams"
                }
              ]}
              selected={filters}
              onChange={(option) =>
                setFilters({ name: option.name, value: option.value })
              }
            />
          </div>
          <hr />
          {treatmentForm.loading ? (
            <>
              {Array.from({ length: 2 }).map((_, index) => (
                <TableSkelton_5 key={index} />
              ))}
            </>
          ) : (
            <>
              <div className="grid grid-cols-3 gap-4 w-full mt-3">
                {treatmentForm?.data?.results?.map((data, index) => (
                  <div
                    key={index}
                    className="border border-gray-200 rounded-xl cursor-pointer h-auto"
                  >
                    <div className="flex flex-col p-3 gap-5">
                      <div className="flex items-center ">
                        <h1 className="text-[16px] font-[600] text-[#272727]">
                          Treatment Plan {startIndex + index + 1}
                        </h1>
                      </div>
                      <div className="flex justify-between items-center">
                        <p className="text-[14px] font-[400] text-[#787878]">
                          Received
                        </p>
                        <p className="text-[13px] font-[500] text-[#788BA5] flex items-center gap-2 ">
                          {data?.received_date
                            ? formatDate(data?.received_date)
                            : ""}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <p className="text-[14px] font-[400] text-[#787878]">
                          Doctor
                        </p>
                        <p className="text-[13px] font-[500] text-[#788BA5] flex items-center gap-2 ">
                          {data?.doctor_name}
                        </p>
                      </div>
                    </div>

                    <hr />

                    <div
                      onClick={() => {
                        Navigate("/manage-forms/treatment/treatment-form", {
                          state: {
                            selectedForm: data
                          }
                        });
                      }}
                      className="flex justify-between items-center text-primary text-[13px] p-2"
                    >
                      <p className="font-[500]">View Form</p>
                      <HiOutlineChevronRight className="text-[18px]" />
                    </div>
                  </div>
                ))}
              </div>
              <div className="w-full flex justify-between items-center mt-2">
                {treatmentForm?.data?.results?.length > 0 && (
                  <p className="text-[#272727] font-roboto text-[14px] font-[400] leading-5">
                    {treatmentForm?.data?.display_string}
                  </p>
                )}
                <Paginations
                  totalPages={treatmentForm?.totalPages}
                  currentPage={treatmentForm?.currentPage}
                  handlePageChange={handlePageChange}
                />
              </div>
              {!treatmentForm?.loading && treatmentForm?.message && (
                <div className="bg-white flex justify-center items-center w-full h-[300px]">
                  <ShowMessage message={treatmentForm?.message} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default PatientTreatmentPage;
