import React, { useEffect, useState } from "react";
import AppHead from "../../../seo/AppHead";
import { FaEdit, FaTrash } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import AddQuestionA1Modal from "../../../components/modals/AddQuestionA1Modal";
import OtherCategoryQuestionModal from "../../../components/modals/OtherCategoryQuestionModal";
import { Link, useLocation } from "react-router-dom";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import ChoosePatientForAssignExamForm from "../../../components/modals/ChoosePatientForAssignExamForm";
import useGetExamFormTemplate from "../../../hooks/useGetExamFormTemplate";

function DoctorExamForm() {
  const [share, setShare] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const { examTemplate, setExamTemplate, GetExamFormTemplate } =
    useGetExamFormTemplate();
  useEffect(() => {
    GetExamFormTemplate(`/api/exam-form/template/`);
  }, []);

  const data = examTemplate.data?.categories || [];

  const handleNextTab = () => {
    if (data.length) {
      setSelectedTabIndex((prevIndex) =>
        prevIndex < data.length - 1 ? prevIndex + 1 : prevIndex
      );
    }
  };

  const handlePreviousTab = () => {
    if (data.length) {
      setSelectedTabIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    }
  };

  return (
    <>
      <AppHead title="Settings - Edit Profile - CHARITE" />

      <div className="p-8">
        <div className="flex items-center gap-2">
          <Link
            to="/manage-forms/exam"
            className="text-[#788BA5] cursor-pointer bg-transparent text-[14px] font-[400] hover:text-primary"
          >
            Exam Form
          </Link>
          <MdOutlineKeyboardArrowRight className="text-[#788BA5] text-[20px] " />
          <p className="text-primary text-[14px] font-[400]">User</p>
        </div>

        <div className="flex justify-between items-center gap-2">
          <h1 className="font-[600] text-[24px] font-roboto  mt-2">
            Exam Form
          </h1>
        </div>
        {examTemplate.loading ? (
          <div className="w-full h-[400px] rounded flex justify-center items-center bg-white flex-col">
            <h1 className="text-[18px] font-[400] ">Loading ... </h1>
          </div>
        ) : (
          <div className="h-auto p-5 mt-2 bg-white rounded">
            {data[selectedTabIndex] && (
              <div className="flex gap-2 p-3">
                <h1 className="text-[16px] font-[600] font-roboto">
                  {data[selectedTabIndex].category}:
                </h1>
                <h1 className="text-[16px] font-[600] font-roboto">
                  {data[selectedTabIndex].category_name}
                </h1>
              </div>
            )}

            <hr />
            {data[selectedTabIndex]?.category === "A1" ? (
              <CategoryA1
                selectedTabIndex={selectedTabIndex}
                examTemplate={examTemplate}
                setExamTemplate={setExamTemplate}
              />
            ) : (
              <OtherCategories
                selectedTabIndex={selectedTabIndex}
                examTemplate={examTemplate}
                setExamTemplate={setExamTemplate}
              />
            )}
            <hr />
            <div className="h-auto mt-4">
              <div className="flex w-[100%] ">
                {examTemplate.data?.categories?.map((category, index) => (
                  <div key={index} className=" pt-2 w-[7%] ">
                    <div
                      className={` rounded-[7px] mx-1 ${
                        index <= selectedTabIndex
                          ? "border-[#69C08C] border-b-2"
                          : "bg-gray-200 border-b-2"
                      }`}
                    ></div>
                  </div>
                ))}
              </div>
              <div className="flex justify-between items-center px-3 mt-4">
                {selectedTabIndex === 0 ? (
                  <div className="flex gap-2 items-center justify-start text-gray-400 font-[700] text-[16px] font-roboto min-w-[120px] ">
                    <button>Back</button>
                  </div>
                ) : (
                  <div
                    onClick={handlePreviousTab}
                    className="flex gap-2 items-center justify-start text-gray-600 font-[700] text-[16px] font-roboto min-w-[120px]  cursor-pointer"
                  >
                    <button>Back</button>
                  </div>
                )}

                {selectedTabIndex ===
                examTemplate.data?.categories?.length - 1 ? (
                  <button
                    onClick={() => setShare(true)}
                    className="flex items-center w-[70px] justify-center text-white bg-primary  cursor-pointer font-[700] text-[16px] font-roboto py-1 rounded"
                    disabled={examTemplate?.loading}
                  >
                    Share
                  </button>
                ) : (
                  <div
                    onClick={handleNextTab}
                    className="flex items-center justify-end gap-2 text-white bg-primary  cursor-pointer font-[700] text-[16px] font-roboto py-1 px-4 rounded"
                  >
                    <button>Next</button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <ChoosePatientForAssignExamForm
        examTemplate={examTemplate}
        setIsOpenModal={setShare}
        isOpenModal={share}
      />
    </>
  );
}

export default DoctorExamForm;

const CategoryA1 = React.memo(
  ({ examTemplate, setExamTemplate, selectedTabIndex }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [questionData, setQuestionData] = useState({
      question_statement: "",
      options: [{ text: "" }, { text: "" }, { text: "" }, { text: "" }]
    });

    const questions =
      examTemplate.data?.categories[selectedTabIndex]?.questions || [];

    const handleAddQuestionClick = () => {
      setIsEditing(false);
      setQuestionData({
        question_statement: "",
        options: [{ text: "" }, { text: "" }, { text: "" }, { text: "" }]
      });
      setModalOpen(true);
    };

    const handleEditQuestion = (index) => {
      setIsEditing(true);
      setCurrentIndex(index);
      setQuestionData({
        question_statement: questions[index].question_statement,
        options: questions[index].options
      });
      setModalOpen(true);
    };

    const handleSave = () => {
      const updatedQuestions = isEditing
        ? questions?.map((q, idx) => (idx === currentIndex ? questionData : q))
        : [...questions, questionData];

      const updatedCategories = examTemplate.data?.categories?.map(
        (item, idx) =>
          idx === selectedTabIndex
            ? { ...item, questions: updatedQuestions }
            : item
      );
      setExamTemplate((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          categories: updatedCategories
        }
      }));

      setModalOpen(false);
    };

    const handleDeleteQuestion = (index) => {
      const updatedQuestions = questions?.filter((_, idx) => idx !== index);
      const updatedData = examTemplate.data?.categories?.map((item, idx) =>
        idx === selectedTabIndex
          ? { ...item, questions: updatedQuestions }
          : item
      );
      setExamTemplate((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          categories: updatedData
        }
      }));
    };

    return (
      <>
        {questions?.map((question, index) => (
          <div key={index} className="p-3">
            <div className="flex justify-between ">
              <h1 className="text-[16px] font-[600] font-roboto">
                {index + 1}. {question?.question_statement}
              </h1>

              <div className="flex gap-2">
                <button
                  onClick={() => handleEditQuestion(index)}
                  className="text-gray-300"
                >
                  <FaEdit />
                </button>
                <button
                  onClick={() => handleDeleteQuestion(index)}
                  className="text-red-300"
                >
                  <FaTrash />
                </button>
              </div>
            </div>
            {question?.options?.map((option, idx) => (
              <div
                key={idx}
                className="flex justify-between gap-2 my-1 text-[14px] font-[400] font-roboto"
              >
                <p>{option?.text}</p>
                <input
                  type="checkbox"
                  className="border border-[#E1E1E2] w-[18px] outline-none cursor-pointer "
                  disabled
                />
              </div>
            ))}
          </div>
        ))}

        <div
          className="flex items-center gap-2 text-primary text-[14px] font-[500] cursor-pointer px-3 my-2"
          onClick={handleAddQuestionClick}
        >
          <FaPlus />
          <p>Add new question </p>
        </div>

        <AddQuestionA1Modal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          onSave={handleSave}
          questionData={questionData}
          setQuestionData={setQuestionData}
          isEditing={isEditing}
        />
      </>
    );
  }
);

function OtherCategories({ selectedTabIndex, examTemplate, setExamTemplate }) {
  const [isModalVisible, setModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [questionData, setQuestionData] = useState({
    question_statement: "",
    options: [{ text: "" }, { text: "" }, { text: "" }, { text: "" }]
  });

  const questions =
    examTemplate.data?.categories[selectedTabIndex]?.questions || [];

  const handleAddQuestionClick = () => {
    setIsEditing(false);
    setQuestionData({
      question_statement: "",
      options:
        questions?.length > 0
          ? questions[questions?.length - 1].options
          : [{ text: "" }, { text: "" }, { text: "" }, { text: "" }]
    });
    setModalVisible(true);
  };

  const handleEditQuestion = (index) => {
    setIsEditing(true);
    setCurrentIndex(index);
    setQuestionData({
      question_statement: questions[index].question_statement,
      options: questions[index].options
    });
    setModalVisible(true);
  };

  const handleSave = () => {
    const updatedQuestions = isEditing
      ? questions?.map((q, idx) => (idx === currentIndex ? questionData : q))
      : [...questions, questionData];

    const updatedData = examTemplate.data?.categories?.map((item, idx) =>
      idx === selectedTabIndex ? { ...item, questions: updatedQuestions } : item
    );

    setExamTemplate((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        categories: updatedData
      }
    }));
    setModalVisible(false);
  };

  const handleDeleteQuestion = (index) => {
    const updatedQuestions = questions?.filter((_, idx) => idx !== index);
    const updatedData = examTemplate.data?.categories?.map((item, idx) =>
      idx === selectedTabIndex ? { ...item, questions: updatedQuestions } : item
    );
    setExamTemplate((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        categories: updatedData
      }
    }));
  };

  const previousOptions =
    questions?.length > 0 ? questions[questions?.length - 1].options : [];

  return (
    <>
      <div className="py-3 px-2">
        <div className="flex justify-end text-[10px]">
          <div className="flex w-[30%] justify-end">
            {examTemplate.data?.categories[selectedTabIndex]?.questions
              ?.length > 0 &&
              examTemplate.data?.categories[
                selectedTabIndex
              ]?.questions[0].options.map((option, index) => (
                <p key={index} className="w-[25%] text-center">
                  {option.text}
                </p>
              ))}
          </div>
          <div className="w-[5%]"></div>
        </div>
        {questions?.map((question, index) => (
          <div
            key={index}
            className={`flex p-2 rounded hover:bg-[#F9F9F9] w-[100%] ${
              index % 2 === 0 ? "bg-[#F9F9F9]" : "bg-white"
            }`}
          >
            <h1 className="text-[14px] font-[400] font-roboto w-[65%]">
              {question.question_statement}
            </h1>
            <div className="w-[30%] flex justify-end">
              {question?.options?.map((option, optionIndex) => (
                <div
                  key={optionIndex}
                  className="w-[25%] flex justify-center items-center"
                >
                  <input
                    type="checkbox"
                    className="border border-[#E1E1E2] w-[20px] h-[20px] outline-none cursor-pointer"
                    disabled
                  />
                </div>
              ))}
            </div>

            <div className="flex gap-2 justify-center w-[5%]">
              <button
                onClick={() => handleEditQuestion(index)}
                className="text-gray-300"
              >
                <FaEdit />
              </button>
              <button
                onClick={() => handleDeleteQuestion(index)}
                className="text-red-300"
              >
                <FaTrash />
              </button>
            </div>
          </div>
        ))}
      </div>

      <div
        className="flex items-center gap-2 text-primary text-[14px] font-[500] cursor-pointer px-3 my-2"
        onClick={handleAddQuestionClick}
      >
        <FaPlus />
        <p>Add new question</p>
      </div>

      <OtherCategoryQuestionModal
        isVisible={isModalVisible}
        onClose={() => setModalVisible(false)}
        onSave={handleSave}
        questionData={questionData}
        setQuestionData={setQuestionData}
        isEditing={isEditing}
        previousOptions={previousOptions}
      />
    </>
  );
}
