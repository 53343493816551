import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import useCurrentUser from "../hooks/useCurrentUser";
import { getAccessToken } from "../storage/storage";
import BeforeLogin from "../pages/auth/BeforeLogin";
import GlobalContext from "../context/GlobalContext";

// Common Pages
import Login from "../pages/auth/Login";
import NotificationsPage from "../pages/common/NotificationsPage";
import SettingsPage from "../pages/common/SettingsPage";
import EditProfilePage from "../pages/common/EditProfile";
import ChangePasswordPage from "../pages/common/ChangePasswordPage";
import Layout from "../components/shared/Layout";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ResetPassword from "../pages/auth/ResetPassword";

// Doctor Pages
import DoctorDashboardPage from "../pages/doctor/DashboardPage";
import DoctorPatientsPage from "../pages/doctor/Patients";
import DoctorPatientsDetailPage from "../pages/doctor/PatientsDetailPage ";
import DoctorConsentPage from "../pages/doctor/DoctorConsentPage";
import DoctorExamPage from "../pages/doctor/DoctorExamPage";
import DoctorTreatmentPage from "../pages/doctor/DoctorTreatmentPage";
import DoctorMedicalHistorPage from "../pages/doctor/DoctorMedicalHistoryPage";

// Patient Pages
import PatientDashboardPage from "../pages/patient/DashboardPage";
import PatientMedicalHistoryPage from "../pages/patient/PatientMedicalHistoryPage";
import PatientMedicalHistoryForm from "../pages/patient/Forms/PatientMedicalHistoryForm";

// Admin Pages
import AdminDashboardPage from "../pages/super-admin/DashboardPage";
import AdminDoctorsPage from "../pages/super-admin/Doctors";
import AdminPatientsPage from "../pages/super-admin/Patients";
import DoctorForm from "../pages/super-admin/DoctorForm";
import PatientsForm from "../pages/super-admin/PatientsForm";
import PatientDetails from "../pages/super-admin/PatientDetails";
import DoctorDetails from "../pages/super-admin/DoctorDetails";
import AdminMedicalHistoryTemplate from "../pages/super-admin/Forms/AdminMedicalHistoryTemplate";
import AdminMedicalHistoryFormList from "../pages/super-admin/Forms/AdminMedicalHistoryFormList";
import DoctorMedicalHistoryForm from "../pages/doctor/Forms/DoctorMedicalHistoryForm";
import DoctorTreatmentForm from "../pages/doctor/Forms/DoctorTreatmentForm";
import PatientExamPage from "../pages/patient/PatientExamPage";
import PatientExamForm from "../pages/patient/Forms/PatientExamForm";
import PatientTreatmentPage from "../pages/patient/PatientTreatmentPage";
import PatientTreatmentForm from "../pages/patient/Forms/PatientTreatmentForm";
import ExamFormforAssignPatient from "../pages/doctor/Forms/DoctorExamForm";
import ViewTreatmentForm from "../pages/doctor/Forms/ViewTreatmentForm";
import AdminViewMedicalHistoryForm from "../pages/super-admin/Forms/AdminViewMedicalHistoryForm";
import DoctorConsentForm from "../pages/doctor/Forms/DoctorConsentForm";
import PatientConsentPage from "../pages/patient/PatientConsentPage";
import PatientConsentForm from "../pages/patient/Forms/PatientConsentForm";
import ViewExamForm from "../pages/doctor/Forms/ViewExamForm";
import DoctorExamForm from "../pages/doctor/Forms/DoctorExamForm";
import ViewConsentForm from "../pages/doctor/Forms/ViewConsentForm";
import ExamForm from "../pages/guardian-forms/ExamForm";
import ConsentForm from "../pages/guardian-forms/ConsentForm";

const Routing = () => {
  const token = getAccessToken();
  const { userInfo, updateResponse } = useContext(GlobalContext);
  const { getCurrentUser } = useCurrentUser();

  useEffect(() => {
    if (token) {
      getCurrentUser(token);
    }
  }, [token, updateResponse]);

  return (
    <>
      <Router>
        <Routes>
          {token ? (
            <>
              {/* Doctor Pages */}
              {userInfo?.role === "Doctor" && (
                <Route path="/" element={<Layout />}>
                  <Route index element={<DoctorDashboardPage />} />
                  <Route path="patients" element={<DoctorPatientsPage />} />
                  <Route
                    path="patients/patient-details"
                    element={<DoctorPatientsDetailPage />}
                  />
                  <Route
                    path="manage-forms/medical-history/medical-history-form"
                    element={<DoctorMedicalHistoryForm />}
                  />
                  <Route
                    path="manage-forms/treatment-form"
                    element={<DoctorTreatmentForm />}
                  />
                  <Route
                    path="manage-forms/exam/exam-form"
                    element={<ViewExamForm />}
                  />

                  <Route
                    path="manage-forms/medical-history"
                    element={<DoctorMedicalHistorPage />}
                  />
                  <Route
                    path="manage-forms/treatment"
                    element={<DoctorTreatmentPage />}
                  />
                  <Route
                    path="manage-forms/exam"
                    element={<DoctorExamPage />}
                  />
                  <Route
                    path="manage-forms/consent"
                    element={<DoctorConsentPage />}
                  />
                  <Route
                    path="manage-forms/consent-form"
                    element={<DoctorConsentForm />}
                  />
                  <Route
                    path="manage-forms/consent/view-consent-form"
                    element={<ViewConsentForm />}
                  />
                  <Route
                    path="manage-forms/assign-exam-form"
                    element={<DoctorExamForm />}
                  />
                  <Route path="*" element={<DoctorDashboardPage />} />
                  <Route
                    path="manage-forms/treatment/view-treatment-form"
                    element={<ViewTreatmentForm />}
                  />
                </Route>
              )}

              {/* Patient Pages */}
              {userInfo?.role === "Patient" && (
                <Route path="/" element={<Layout />}>
                  <Route index element={<PatientDashboardPage />} />
                  <Route path="*" element={<PatientDashboardPage />} />
                  <Route
                    path="manage-forms/medical-history"
                    element={<PatientMedicalHistoryPage />}
                  />
                  <Route
                    path="manage-forms/exam"
                    element={<PatientExamPage />}
                  />
                  <Route
                    path="manage-forms/treatment"
                    element={<PatientTreatmentPage />}
                  />
                  <Route
                    path="manage-forms/consent"
                    element={<PatientConsentPage />}
                  />
                  <Route
                    path="manage-forms/medical-history/medical-history-form"
                    element={<PatientMedicalHistoryForm />}
                  />
                  <Route
                    path="manage-forms/exam/exam-form"
                    element={<PatientExamForm />}
                  />
                  <Route
                    path="manage-forms/treatment/treatment-form"
                    element={<PatientTreatmentForm />}
                  />
                  <Route
                    path="manage-forms/consent/consent-form"
                    element={<PatientConsentForm />}
                  />
                </Route>
              )}

              {/* Admin Pages */}
              {userInfo?.role === "Admin" && (
                <Route path="/" element={<Layout />}>
                  <Route index element={<AdminDashboardPage />} />
                  <Route path="doctors" element={<AdminDoctorsPage />} />
                  <Route path="patients" element={<AdminPatientsPage />} />
                  <Route path="doctors/doctor-form" element={<DoctorForm />} />
                  <Route
                    path="patients/patient-form"
                    element={<PatientsForm />}
                  />
                  <Route
                    path="patients/patient-details"
                    element={<PatientDetails />}
                  />
                  <Route
                    path="manage-forms/medical-history"
                    element={<AdminMedicalHistoryFormList />}
                  />

                  <Route
                    path="manage-forms/medical-history-form"
                    element={<AdminMedicalHistoryTemplate />}
                  />

                  <Route
                    path="doctors/doctor-details"
                    element={<DoctorDetails />}
                  />
                  <Route
                    path="manage-forms/medical-history/view-medical-history"
                    element={<AdminViewMedicalHistoryForm />}
                  />
                  <Route path="*" element={<AdminDashboardPage />} />
                </Route>
              )}

              {/* Common Pages */}
              <Route path="/" element={<Layout />}>
                <Route path="notifications" element={<NotificationsPage />} />
                <Route path="settings" element={<SettingsPage />} />
                <Route
                  path="settings/edit-profile"
                  element={<EditProfilePage />}
                />
                <Route
                  path="settings/change-password"
                  element={<ChangePasswordPage />}
                />
              </Route>
            </>
          ) : (
            <>
              <Route path="/" element={<BeforeLogin />} />
              <Route path="/login/:type" element={<Login />} />
              <Route path="/forgot-passowrd" element={<ForgotPassword />} />
              <Route
                path="/reset-password/:token"
                element={<ResetPassword />}
              />
              <Route path="*" element={<BeforeLogin />} />
              <Route
                path="/guardian/exam-form/:formId/fill/"
                element={<ExamForm />}
              />
              <Route
                path="/guardian/consent-form/:formId/update/"
                element={<ConsentForm />}
              />
            </>
          )}
        </Routes>
      </Router>
    </>
  );
};

export default Routing;
