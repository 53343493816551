import React, { useState, useRef, useEffect } from "react";
import { FaTimes } from "react-icons/fa";

function OtherCategoryQuestionModal({
  isVisible,
  onClose,
  onSave,
  questionData,
  setQuestionData,
  isEditing,
  previousOptions = [] // Add this prop for previous options
}) {
  const [error, setError] = useState("");
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
        setError("");
      }
    };

    if (isVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisible, onClose]);

  if (!isVisible) return null;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setQuestionData((prevData) => ({
      ...prevData,
      [name]: value
    }));
    setError("");
  };

  const handleSubmit = () => {
    if (!questionData.question_statement.trim()) {
      setError("* Enter Question");
      return;
    }

    if (!isEditing) {
      console.log("Options from previous question:", previousOptions);
    }

    onSave();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div
        ref={modalRef}
        className="bg-white p-6 rounded-lg w-11/12 max-w-md shadow-lg h-auto"
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">
            {isEditing ? "Edit Question" : "Add New Question"}
          </h2>
          <button
            onClick={() => {
              onClose();
              setError("");
            }}
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <FaTimes />
          </button>
        </div>
        <label
          htmlFor=""
          className="text-[15px] font-bold font-roboto text-gray-600"
        >
          Enter Question:
        </label>
        <input
          type="text"
          name="question_statement"
          value={questionData.question_statement}
          onChange={handleInputChange}
          placeholder="Question Name"
          className="border border-[#E1E1E2] p-2 mb-1 w-full rounded outline-none"
        />
        {error && <p className="text-red-500 text-sm text-[14px] ">{error}</p>}
        <div className="flex justify-end space-x-2 mt-4">
          <button
            onClick={() => {
              onClose();
              setError("");
            }}
            className=" text-gray-600 py-2 px-4 rounded font-roboto font-bold text-[14px]"
            style={{ boxShadow: "0px 0px 7px gray" }}
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="bg-blue-500 text-white py-2 px-6 rounded font-roboto font-bold text-[14px] ml-2"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default OtherCategoryQuestionModal;
