import React, { Fragment, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Dialog, Transition } from "@headlessui/react";
import { FaPlus } from "react-icons/fa";
import { MdOutlineDelete } from "react-icons/md";
const AddMedicationTableModal = ({ isOpen, onClose, onSave }) => {
  const [question, setQuestion] = useState("");
  const [headers, setHeaders] = useState([
    { row_number: 1, column_header: [""], value: [] }
  ]);
  const [selectedRowCount, setSelectedRowCount] = useState(1);
  const [questionError, setQuestionError] = useState("");
  const [headerErrors, setHeaderErrors] = useState([]);

  const handleHeaderChange = (index, field, value) => {
    const newHeaders = [...headers];
    newHeaders[index][field] = value;
    setHeaders(newHeaders);
    setHeaderErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[index] = "";
      return newErrors;
    });
  };

  const validateFields = () => {
    let isValid = true;
    if (question.trim() === "") {
      setQuestionError("Question cannot be empty");
      isValid = false;
    } else {
      setQuestionError("");
    }
    const newHeaderErrors = headers.map((header) =>
      header.column_header.trim() === "" ? "Header cannot be empty" : ""
    );
    setHeaderErrors(newHeaderErrors);
    if (newHeaderErrors.some((error) => error !== "")) {
      isValid = false;
    }
    return isValid;
  };

  const handleSave = () => {
    if (validateFields()) {
      const newEntries = Array.from({ length: selectedRowCount }, () => {
        const entry = {};
        headers.forEach((header) => {
          entry[header.column_header] = header.value || "";
        });
        return entry;
      });
      const updatedHeaders = headers.map((header) => ({
        column_header: header.column_header,
        row_number: 1,
        value: []
      }));
      onSave(question, updatedHeaders, newEntries);
      setQuestion("");
      setHeaders([{ row_number: 1, column_header: [""], value: [] }]);
      setSelectedRowCount(1);
      onClose();
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 xs:p-1 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all 2xl:w-[600px] lg:w-[600px] xs:w-full h-auto xs:mx-2 md:p-6 xs:p-3">
                <div className="absolute top-[10px] right-[10px] cursor-pointer">
                  <AiOutlineClose onClick={onClose} />
                </div>
                <div className="flex flex-col">
                  <h1 className="2xl:text-[24px] font-semibold lg:text-[20px]">
                    Add New Table Question
                  </h1>
                  <div className="mb-4">
                    <label className="text-[16px] text-gray-700 font-bold">
                      Question
                    </label>
                    <input
                      type="text"
                      value={question}
                      placeholder="Enter question"
                      onChange={(e) => {
                        setQuestion(e.target.value);
                        if (e.target.value.trim() !== "") {
                          setQuestionError("");
                        }
                      }}
                      className={`w-full p-2 border rounded outline-none ${
                        questionError ? "border-red-500" : "border-gray-300"
                      }`}
                    />
                    {questionError && (
                      <p className="text-red-500 text-[12px]">
                        {questionError}
                      </p>
                    )}
                  </div>
                  <div className="mb-4">
                    <label className="text-[16px] text-gray-700 font-bold">
                      Headers
                    </label>
                    <div>
                      {headers.map((header, index) => (
                        <div key={index} className="mb-4">
                          <div className="flex items-center ">
                            <input
                              type="text"
                              value={header.column_header}
                              placeholder="Enter header"
                              onChange={(e) =>
                                handleHeaderChange(
                                  index,
                                  "column_header",
                                  e.target.value
                                )
                              }
                              className={`w-full p-2 border rounded outline-none ${
                                headerErrors[index] ? "border-red-500" : ""
                              }`}
                            />

                            <button
                              type="button"
                              className="text-white rounded"
                              onClick={() => {
                                const newHeaders = headers.filter(
                                  (_, i) => i !== index
                                );
                                setHeaders(newHeaders);
                                setHeaderErrors(
                                  headerErrors.filter((_, i) => i !== index)
                                );
                              }}
                            >
                              <MdOutlineDelete className="text-red-500 text-[30px]" />
                            </button>
                          </div>
                          {headerErrors[index] && (
                            <p className="text-red-500 text-[12px]">
                              {headerErrors[index]}
                            </p>
                          )}
                        </div>
                      ))}
                    </div>
                    <button
                      type="button"
                      className="flex items-center gap-2 text-primary outline-none"
                      onClick={() =>
                        setHeaders([
                          ...headers,
                          { column_header: "", row_number: 1, value: [] }
                        ])
                      }
                    >
                      <FaPlus />
                      <p> Add Header</p>
                    </button>
                  </div>
                  <div className="mb-4">
                    <label className="text-[16px] text-gray-700 font-bold">
                      Select Number of Rows
                    </label>
                    <input
                      type="number"
                      min="1"
                      value={selectedRowCount}
                      onChange={(e) =>
                        setSelectedRowCount(parseInt(e.target.value))
                      }
                      className="w-full p-2 border rounded outline-none border-gray-300"
                    />
                  </div>
                  <div className="flex justify-end">
                    <button
                      onClick={onClose}
                      className="px-6 py-2 bg-gray-200 rounded"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleSave}
                      className="ml-2 px-6 py-2 bg-blue-500 text-white rounded"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AddMedicationTableModal;
