import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { BiHide, BiShow } from "react-icons/bi";
import { ButtonLoader_1 } from "../../components/shared/Loaders";
import AppHead from "../../seo/AppHead";
import useResetPassword from "../../hooks/useResetPasswords ";
import Cookies from "js-cookie";

const validationSchema = Yup.object({
  password: Yup.string().required("Password is required"),
  confirm: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required")
});

function ResetPassword() {
  return (
    <>
      <AppHead title="Log in - CHARITE" />
      <div className="grid grid-cols-2 w-full h-[100vh]">
        <LeftSide />
        <RightSide />
      </div>
    </>
  );
}

export default ResetPassword;

function LeftSide() {
  return (
    <div className="w-full h-full bg-[#709ACF] flex flex-col gap-12 justify-center items-center">
      <img
        src="/assets/images/login_image.png"
        alt=""
        className="w-[250px] 2xl:w-auto"
      />
      <div className="flex flex-col gap-1 items-center justify-center text-white">
        <h1 className="text-[32px] font-[600]">
          Welcome to Your Health Journey
        </h1>
        <p className="text-center text-[14px] font-[400]">
          Our portal provides a seamless way for doctors and patients to <br />{" "}
          connect and manage healthcare needs efficiently.
        </p>
      </div>
    </div>
  );
}

function RightSide() {
  const email = Cookies.get("email");
  const { token } = useParams();
  const [password, setPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const { ResetPasswords, loading } = useResetPassword();

  const initialValues = {
    password: "",
    confirm: ""
  };

  const { values, errors, handleChange, handleSubmit, touched } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      await ResetPasswords({
        token: token,
        email: email,
        password: values.password
      });
    }
  });

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !loading) {
      handleSubmit();
    }
  };

  return (
    <>
      <div className="w-full h-full bg-white flex flex-col gap-6 justify-center items-center">
        <img src="/assets/logo/logo.png" alt="CHARITE" />
        <form
          className="w-[70%] border border-[#DEE4ED] rounded-[6px] py-8 px-8"
          style={{ boxShadow: "rgba(21, 29, 40, 0.04)" }}
        >
          <div className="flex flex-col justify-center items-center">
            <h1 className="font-[600] text-[24px] text-[#272727] mb-2 leading-9">
              Create Password
            </h1>
            <p className="font-[400] text-[14px] text-[#788BA5] text-center mb-8 leading-5">
              Log in to manage your health records, consult with
              <br /> doctors, and stay updated on your healthcare needs.
            </p>
          </div>
          <div className="w-full flex flex-col gap-4">
            {/* Password field */}
            <div className="w-full">
              <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                Password
              </label>
              <div className="relative mt-1">
                <input
                  type={password ? "text" : "password"}
                  name="password"
                  value={values.password}
                  onKeyPress={handleKeyPress}
                  onChange={handleChange}
                  placeholder="Enter password"
                  className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
                />
                <div
                  className="h-full absolute top-0 right-3 cursor-pointer flex items-center justify-center text-center"
                  onClick={() => setPassword(!password)}
                >
                  {password ? (
                    <BiShow className="text-xl text-[#95969D]" />
                  ) : (
                    <BiHide className="text-xl text-[#95969D]" />
                  )}
                </div>
              </div>
              {errors.password && touched.password && (
                <p className="text-red-700 text-xs ">{errors.password}</p>
              )}
            </div>

            {/* Confirm Password field */}
            <div className="w-full">
              <label className="text-[#787878] text-[15px] leading-5 font-[400]">
                Confirm Password
              </label>
              <div className="relative mt-1">
                <input
                  type={confirmPassword ? "text" : "password"}
                  name="confirm" // This should match the key in initialValues
                  value={values.confirm}
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                  placeholder="Enter confirm password"
                  className="border border-[#DEE4ED] w-full h-[36px] px-3 text-[#5A5A5A] bg-transparent rounded-md outline-none hover:border-primary focus:border-primary"
                />
                <div
                  className="h-full absolute top-0 right-3 cursor-pointer flex items-center justify-center text-center"
                  onClick={() => setConfirmPassword(!confirmPassword)}
                >
                  {confirmPassword ? (
                    <BiShow className="text-xl text-[#95969D]" />
                  ) : (
                    <BiHide className="text-xl text-[#95969D]" />
                  )}
                </div>
              </div>
              {errors.confirm && touched.confirm && (
                <p className="text-red-700 text-xs ">{errors.confirm}</p>
              )}
            </div>
          </div>
          <button
            className="bg-primary w-full h-[40px] text-white rounded-[10px] mt-6 flex justify-center items-center"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? <ButtonLoader_1 /> : "Save"}
          </button>
        </form>
      </div>
    </>
  );
}
