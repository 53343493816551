import { useContext, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { getAccessToken } from "../storage/storage";
import GlobalContext from "../context/GlobalContext";

function useAssignDoctorToPatient() {
  const token = getAccessToken();
  const { setUpdateResponse } = useContext(GlobalContext);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);

  const AssignDoctor = async (payload, handleClose) => {
    setLoading(true);

    await axios
      .post(`${BASE_URL}/api/assign-doctors-to-patient/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        setUpdateResponse(true);
        setLoading(false);
        Swal.fire({
          icon: "success",
          text: response.data.message,
          customClass: {
            confirmButton: "custom-green-button"
          }
        });
        handleClose();
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          text:
            error?.response?.data?.message ||
            "Oops! Something went wrong on our end. Please give us a moment to fix it. Feel free to try again later",
          customClass: {
            confirmButton: "custom-red-button"
          }
        });
      });
  };
  return { AssignDoctor, loading };
}
export default useAssignDoctorToPatient;
