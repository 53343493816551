import { useContext, useState } from "react";
import axios from "axios";
import { getAccessToken } from "../storage/storage";
import GlobalContext from "../context/GlobalContext";

const useDashboardData = () => {
  const token = getAccessToken();
  const { setUpdateResponse } = useContext(GlobalContext);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [dashboard, setDashboard] = useState({
    loading: true,
    data: null,
    message: null
  });

  const DashboardData = async (url) => {
    setDashboard((prevState) => ({
      ...prevState,
      loading: true,
      message: null
    }));
    await axios
      .get(`${BASE_URL}${url}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        setUpdateResponse(false);
        setDashboard((prevState) => ({
          ...prevState,
          loading: false,
          data: response.data
        }));
      })
      .catch((error) => {
        setDashboard((prevState) => ({
          ...prevState,
          loading: false,
          data: null,
          message: "Oops! Something went wrong"
        }));
      });
  };
  return {
    dashboard,
    setDashboard,
    DashboardData
  };
};
export default useDashboardData;
