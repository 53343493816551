import React, { useEffect, useState } from "react";
import AppHead from "../../seo/AppHead";
import { ButtonLoader_1 } from "../../components/shared/Loaders";
import Swal from "sweetalert2";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;

function ExamForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const { formId } = useParams();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [examTemplate, setExamTemplate] = useState({
    loading: true,
    data: null,
    message: null
  });

  const GetExamFormTemplate = async () => {
    setExamTemplate((prevState) => ({
      ...prevState,
      loading: true,
      message: null
    }));
    await axios
      .get(`${BASE_URL}/api/exam-forms/${formId}/`)
      .then((response) => {
        setExamTemplate((prevState) => ({
          ...prevState,
          loading: false,
          data: response.data,
          message:
            response.data?.results?.length === 0 ? "Data not found" : null
        }));
      })
      .catch((error) => {
        setExamTemplate((prevState) => ({
          ...prevState,
          loading: false,
          message: "Oops! Something went wrong"
        }));
      });
  };
  useEffect(() => {
    GetExamFormTemplate();
  }, []);

  // for tabs next and previous
  const data = examTemplate?.data?.categories || [];
  const handleNextTab = () => {
    if (data?.length) {
      setSelectedTabIndex((prevIndex) =>
        prevIndex < data?.length - 1 ? prevIndex + 1 : prevIndex
      );
    }
  };
  const handlePreviousTab = () => {
    if (data.length) {
      setSelectedTabIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    }
  };

  // post form after fill
  const [selectedOptions, setSelectedOptions] = useState([]);
  const handleSubmit = async () => {
    setLoading(true);
    const payload = {
      choices: selectedOptions
    };
    await axios
      .post(
        `${BASE_URL}/api/exam-form/${examTemplate?.data?.id}/fill/?token=${token}`,
        payload
      )
      .then((response) => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          text: response.data?.message,
          customClass: {
            confirmButton: "custom-green-button"
          }
        }).then(() => {
          navigate("/");
        });
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          text:
            error?.response?.data?.message ||
            "Oops! Something went wrong on our end. Please give us a moment to fix it. Feel free to try again later"
        });
      });
  };

  const handleOptionChange = (questionId, optionId) => {
    setSelectedOptions((prev) => {
      const currentSelection = prev.find(
        (item) => item.question_id === questionId
      );
      if (currentSelection) {
        if (currentSelection.option_id === optionId) {
          return prev.filter((item) => item.question_id !== questionId);
        } else {
          return prev.map((item) =>
            item.question_id === questionId
              ? { question_id: questionId, option_id: optionId }
              : item
          );
        }
      } else {
        return [...prev, { question_id: questionId, option_id: optionId }];
      }
    });
  };

  return (
    <>
      <AppHead title="Settings - Edit Profile - CHARITE" />
      <div className="p-8">
        <div className="flex justify-between items-center gap-2">
          <h1 className="font-[600] text-[24px] font-roboto  mt-2">
            Exam Form
          </h1>
        </div>
        {examTemplate?.loading ? (
          <div className="w-full h-[400px] rounded flex justify-center items-center bg-white flex-col">
            <h1 className="text-[18px] font-[400] ">Loading ... </h1>
          </div>
        ) : (
          <div className="h-auto p-5 mt-2 bg-white rounded">
            {data[selectedTabIndex] && (
              <div className="flex gap-2 p-3">
                <h1 className="text-[16px] font-[600] font-roboto">
                  {data[selectedTabIndex]?.category}:
                </h1>
                <h1 className="text-[16px] font-[600] font-roboto">
                  {data[selectedTabIndex]?.category_name}
                </h1>
              </div>
            )}

            <hr />
            {data[selectedTabIndex]?.category === "A1" ? (
              <CategoryA1
                selectedTabIndex={selectedTabIndex}
                examTemplate={examTemplate}
                setData={setExamTemplate}
                handleOptionChange={handleOptionChange}
                selectedOptions={selectedOptions}
              />
            ) : (
              <OtherCategories
                selectedTabIndex={selectedTabIndex}
                examTemplate={examTemplate}
                setData={setExamTemplate}
                handleOptionChange={handleOptionChange}
                selectedOptions={selectedOptions}
              />
            )}
            <hr />
            <div className="h-auto mt-4">
              <div className="flex w-[100%] ">
                {examTemplate?.data?.categories?.map((category, index) => (
                  <div key={index} className=" pt-2 w-[7%] ">
                    <div
                      className={` rounded-[7px] mx-1 ${
                        index <= selectedTabIndex
                          ? "border-[#69C08C] border-b-2"
                          : "bg-gray-200 border-b-2"
                      }`}
                    ></div>
                  </div>
                ))}
              </div>
              <div className="flex justify-between items-center px-3 mt-4">
                {selectedTabIndex === 0 ? (
                  <div className="flex gap-2 items-center justify-start text-gray-400 font-[700] text-[16px] font-roboto min-w-[120px] ">
                    <button>Back</button>
                  </div>
                ) : (
                  <div
                    onClick={handlePreviousTab}
                    className="flex gap-2 items-center justify-start text-gray-600 font-[700] text-[16px] font-roboto min-w-[120px]  cursor-pointer"
                  >
                    <button>Back</button>
                  </div>
                )}

                {selectedTabIndex ===
                examTemplate?.data?.categories?.length - 1 ? (
                  <button
                    onClick={handleSubmit}
                    className="flex items-center w-[70px] justify-center text-white bg-primary  cursor-pointer font-[700] text-[16px] font-roboto py-1 rounded"
                    disabled={examTemplate?.loading}
                  >
                    {loading ? <ButtonLoader_1 /> : "Save"}
                  </button>
                ) : (
                  <div
                    onClick={handleNextTab}
                    className="flex items-center justify-end gap-2 text-white bg-primary  cursor-pointer font-[700] text-[16px] font-roboto py-1 px-4 rounded"
                  >
                    <button>Next</button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ExamForm;

const CategoryA1 = React.memo(
  ({ examTemplate, selectedTabIndex, handleOptionChange, selectedOptions }) => {
    const questions =
      examTemplate.data?.categories[selectedTabIndex]?.questions || [];

    return (
      <>
        {questions?.map((question, index) => (
          <div key={index} className="p-3">
            <div className="flex justify-between">
              <h1 className="text-[16px] font-[600] font-roboto">
                {index + 1}. {question?.question_statement}
              </h1>
            </div>
            {question?.options?.map((option, idx) => (
              <div
                key={idx}
                className="flex justify-between gap-2 my-1 text-[14px] font-[400] font-roboto"
              >
                <p>{option?.text}</p>
                <input
                  type="checkbox"
                  className="border border-[#E1E1E2] w-[18px] outline-none cursor-pointer"
                  checked={selectedOptions.some(
                    (item) =>
                      item.question_id === question.id &&
                      item.option_id === option.id
                  )}
                  onChange={() => handleOptionChange(question.id, option.id)}
                />
              </div>
            ))}
          </div>
        ))}
      </>
    );
  }
);

function OtherCategories({
  selectedTabIndex,
  examTemplate,
  handleOptionChange,
  selectedOptions
}) {
  const questions =
    examTemplate.data?.categories[selectedTabIndex]?.questions || [];

  return (
    <>
      <div className="py-3 px-2">
        <div className="flex justify-end text-[10px]">
          <div className="flex w-[30%] justify-end">
            {examTemplate.data?.categories[selectedTabIndex]?.questions
              ?.length > 0 &&
              examTemplate.data?.categories[
                selectedTabIndex
              ].questions[0].options.map((option, index) => (
                <p key={index} className="w-[25%] text-center">
                  {option.text}
                </p>
              ))}
          </div>
          <div className="w-[5%]"></div>
        </div>

        {questions?.map((question, index) => (
          <div
            key={index}
            className={`flex p-2 rounded hover:bg-[#F9F9F9] w-[100%] ${
              index % 2 === 0 ? "bg-[#F9F9F9]" : "bg-white"
            }`}
          >
            <h1 className="text-[14px] font-[400] font-roboto w-[65%]">
              {question.question_statement}
            </h1>
            <div className="w-[30%] flex justify-end">
              {question?.options?.map((option, optionIndex) => (
                <div
                  key={optionIndex}
                  className="w-[25%] flex justify-center items-center"
                >
                  <input
                    type="checkbox"
                    className="border border-[#E1E1E2] w-[20px] h-[20px] outline-none cursor-pointer"
                    // Check if this option is selected
                    checked={selectedOptions.some(
                      (item) =>
                        item.question_id === question.id &&
                        item.option_id === option.id
                    )}
                    onChange={() => handleOptionChange(question.id, option.id)}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
