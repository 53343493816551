import axios from "axios";
import Swal from "sweetalert2";
import { getAccessToken } from "../storage/storage";
import { useContext, useState } from "react";
import GlobalContext from "../context/GlobalContext";

function useActiveStatus() {
  const token = getAccessToken();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);
  const { setUpdateResponse } = useContext(GlobalContext);

  const ActiveStatus = async (payload, url) => {
    setLoading(true);
    await axios
      .post(`${BASE_URL}${url}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        setLoading(false);
        setUpdateResponse(true);
        Swal.fire({
          icon: "success",
          text: response.data?.message,
          customClass: {
            confirmButton: "custom-green-button"
          }
        });
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          text:
            error?.response?.data?.message ||
            "Oops! Something went wrong on our end. Please give us a moment to fix it. Feel free to try again later"
        });
      });
  };

  return { ActiveStatus, loading };
}

export default useActiveStatus;
