import axios from "axios";
import Swal from "sweetalert2";
import { getAccessToken } from "../storage/storage";
import { useNavigate } from "react-router-dom";

function useEditDoctor() {
  const token = getAccessToken();
  const Navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const EditDoctor = async (payload, id, handleClose) => {
    await axios
      .put(`${BASE_URL}/api/admin/manage-users/${id}/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        handleClose();
        Swal.fire({
          icon: "success",
          text: "Doctor successfully updated!",
          customClass: {
            confirmButton: "custom-green-button",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            Navigate("/doctors");
          }
        });
      })
      .catch((error) => {
        handleClose();
        Swal.fire({
          icon: "error",
          text:
            error?.response?.data?.message ||
            "Oops! Something went wrong on our end. Please give us a moment to fix it. Feel free to try again later",
          customClass: {
            confirmButton: "custom-red-button",
          },
        });
      });
  };
  return { EditDoctor };
}
export default useEditDoctor;
