import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext";
import { removeAccessToken, removeRefreshToken } from "../../storage/storage";
import { MdHelpOutline, MdOutlineClose, MdOutlineLogout } from "react-icons/md";
import { RiArrowDropDownLine } from "react-icons/ri";
import {
  AdminRoutes,
  DoctorRoutes,
  PatientRoutes,
} from "../../staticData/Routes";

function Sidebar({ toggleSidebar }) {
  const [openDropdown, setOpenDropdown] = useState(null);
  const { userInfo } = useContext(GlobalContext);
  const [routes, setRoutes] = useState(null);

  useEffect(() => {
    if (userInfo?.role === "Doctor") {
      setRoutes(DoctorRoutes);
    }
    if (userInfo?.role === "Admin") {
      setRoutes(AdminRoutes);
    }
    if (userInfo?.role === "Patient") {
      setRoutes(PatientRoutes);
    }
  }, [userInfo]);

  const handleDropdownToggle = (index) => {
    setOpenDropdown((prevOpenDropdown) =>
      prevOpenDropdown === index ? null : index
    );
  };

  const handleLogout = () => {
    removeAccessToken();
    removeRefreshToken();
    window.location.href = "/";
  };

  return (
    <div className="w-full bg-white py-6 px-4 h-[100vh] flex flex-col justify-between relative">
      <div className="lg:hidden flex absolute top-2 right-2">
        <MdOutlineClose
          onClick={toggleSidebar}
          className="text-[30px] text-[#788BA5] hover:text-primary cursor-pointer"
        />
      </div>

      <div>
        <Link to="/">
          <img src="/assets/logo/logo.png" alt="Charite" />
        </Link>
        <div className="flex flex-col gap-2 mt-5">
          {routes?.map((item, index) => (
            <div key={index}>
              {item.children ? (
                <div className="relative">
                  <div
                    className={`flex items-center gap-2 px-5 py-2 rounded-md text-[#788BA5] text-[16px] font-[500] leading-4 cursor-pointer hover:bg-primary hover:text-white
                      `}
                    // ${openDropdown === index ? "bg-primary text-white": "hover:bg-primary hover:text-white"}
                    onClick={() => handleDropdownToggle(index)}
                  >
                    {item.icon}
                    <p>{item.name}</p>
                    <span
                      className={`ml-auto ${
                        openDropdown === index ? "rotate-180" : ""
                      }`}
                    >
                      <RiArrowDropDownLine className="text-[30px]" />
                    </span>
                  </div>
                  {openDropdown === index && (
                    <div className="mt-2 px-12 flex flex-col gap-2">
                      {item.children.map((child, childIndex) => (
                        <NavLink
                          key={childIndex}
                          to={child.path}
                          className={({ isActive }) =>
                            `block text-[14px] rounded-md hover:text-primary ${
                              isActive
                                ? "text-primary font-[700]"
                                : "text-[#788BA5]"
                            }`
                          }
                          onClick={(e) => e.stopPropagation()}
                        >
                          {child.name}
                        </NavLink>
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                <NavLink
                  to={item.path}
                  className={({ isActive }) =>
                    `flex items-center gap-2 px-5 py-3 rounded-md text-[#788BA5] text-[16px] font-[500] leading-4 cursor-pointer ${
                      isActive
                        ? "bg-primary text-white"
                        : "hover:bg-primary hover:text-white"
                    }`
                  }
                >
                  {item.icon}
                  <p>{item.name}</p>
                </NavLink>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <NavLink
          to="/help"
          className={({ isActive }) =>
            `flex items-center gap-2 px-5 py-3 rounded-md text-[#788BA5] text-[16px] font-[500] leading-4 cursor-pointer ${
              isActive
                ? "bg-primary text-white"
                : "hover:bg-primary hover:text-white"
            }`
          }
        >
          <MdHelpOutline className="text-[20px]" />
          <p>Help</p>
        </NavLink>
        <div
          onClick={handleLogout}
          className="flex items-center gap-2 px-5 py-3 rounded-md text-[#EB5757] text-[16px] font-[500] leading-4 cursor-pointer hover:bg-[#EB5757] hover:text-white"
        >
          <MdOutlineLogout className="text-[20px]" />
          <p>Log out</p>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
