import React, { useEffect, useState, useRef } from "react";
import AppHead from "../../seo/AppHead";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import useGetMedicalHistoryForm from "../../hooks/useGetMedicalHistoryForm";
import { TableSkelton_5 } from "../../components/shared/Skeltons";
import Paginations from "../../components/shared/Paginations";
import ShowMessage from "../../components/shared/ShowMessage";
import { HiArrowLeft, HiOutlineChevronRight } from "react-icons/hi";
import useGetExamFormTemplate from "../../hooks/useGetExamFormTemplate";
import { GoPlus } from "react-icons/go";
import useGetConsentForm from "../../hooks/useGetConsentForm";
import SignatureCanvas from "react-signature-canvas";
import Swal from "sweetalert2";
import axios from "axios";
import { getAccessToken } from "../../storage/storage";
import useAddForm from "../../hooks/useAddForm";
import { ButtonLoader_1 } from "../../components/shared/Loaders";
import { FaEdit, FaTrash } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import AddQuestionA1Modal from "../../components/modals/AddQuestionA1Modal";
import OtherCategoryQuestionModal from "../../components/modals/OtherCategoryQuestionModal";
import ChoosePatientForAssignExamForm from "../../components/modals/ChoosePatientForAssignExamForm";
import CheckBox from "../../components/shared/CheckBox";
import useGetTreatmentForm from "../../hooks/useGetTreatmentFormTemplate";
import { AiOutlineClose } from "react-icons/ai";
import { TbUsersPlus } from "react-icons/tb";
import { PiShareFat } from "react-icons/pi";

function PatientsDetailPage() {
  const location = useLocation();
  const { selectedPatient = {} } = location.state || {};

  return (
    <>
      <AppHead title="Dashboard - CHARITE" />
      <div className="p-10">
        <div className="flex items-center gap-2">
          <Link
            to="/patients"
            className="text-[#788BA5] cursor-pointer bg-transparent text-[14px] font-[400] hover:text-primary"
          >
            Patients
          </Link>
          <MdOutlineKeyboardArrowRight className="text-[#788BA5] text-[20px] " />
          <p className="text-primary text-[14px] font-[400]">Exam Form</p>
        </div>
        <h1 className="text-[#272727] text-[24px] font-[600] mt-2">
          Personal Details
        </h1>
        <div
          className="w-full bg-white rounded-xl p-6 mt-4 flex"
          style={{ boxShadow: "0px 0px 7px #e0e0e0" }}
        >
          <div className="flex flex-col justify-center items-center w-[25%] mr-4">
            <svg
              className="w-[130px] h-[130px] rounded-full text-gray-200 dark:text-gray-700"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
            </svg>
            <h1 className="text-[#272727] text-[18px] font-[600] font-roboto mt-1">
              {selectedPatient?.user?.first_name}{" "}
              {selectedPatient?.user?.last_name}
            </h1>
            <p className="text-primary text-[14px] font-[400] font-roboto bg-[#DDE9FC] py-1 px-3 rounded">
              {selectedPatient?.disease}
            </p>
          </div>
          <div className=" border-l-2 border-gray-100 w-[38%] pl-10 flex flex-col justify-between">
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Phone No.
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedPatient?.user?.mobile_no}
              </p>
            </div>
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Email
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedPatient?.user?.email}
              </p>
            </div>{" "}
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Gender
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedPatient?.user?.gender}
              </p>
            </div>
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Marital status
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedPatient?.user?.marital_status}
              </p>
            </div>{" "}
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Date of Birth
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedPatient?.user?.date_of_birth}
              </p>
            </div>{" "}
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Occupation
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedPatient?.occupation}
              </p>
            </div>{" "}
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Blood Pressure
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedPatient?.bp}
              </p>
            </div>
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Sugar
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedPatient?.sugar}
              </p>
            </div>{" "}
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Blood Group
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedPatient?.blood_group}
              </p>
            </div>
          </div>
          <div className="w-[37%] pl-10 flex flex-col justify-between gap-2">
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Height
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedPatient?.height}
              </p>
            </div>
            {selectedPatient?.guardians.map((data, index) => (
              <div className="flex flex-col gap-2">
                <div className="flex items-center justify-between">
                  <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                    Guardian Name
                  </h1>
                  <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                    {data?.name}
                  </p>
                </div>{" "}
                <div className="flex items-center justify-between">
                  <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                    Relation
                  </h1>
                  <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                    {data?.relation}
                  </p>
                </div>{" "}
                <div className="flex items-center justify-between">
                  <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                    Guardian Mobile
                  </h1>
                  <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                    {data?.contact_no}
                  </p>
                </div>{" "}
                <div className="flex items-center justify-between">
                  <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                    Guardian Email
                  </h1>
                  <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                    {data?.email}
                  </p>
                </div>
              </div>
            ))}
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                State
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedPatient?.user?.state}
              </p>
            </div>
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                City
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedPatient?.user?.city}
              </p>
            </div>
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Postal Code
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedPatient?.user?.postal_code}
              </p>
            </div>{" "}
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Address
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedPatient?.user?.address}
              </p>
            </div>
            {/* <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Status
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedPatient?.guardians?.status}
              </p>
            </div> */}
          </div>
        </div>
        <FormTabs selectedPatient={selectedPatient} />
      </div>
    </>
  );
}

export default PatientsDetailPage;

function FormTabs(props) {
  const [showComponent, setShowComponent] = useState(false);
  const [showExamForm, setShowExamForm] = useState(false);
  const Navigate = useNavigate();
  const tabs = [
    "Medical History Form",
    "Exam Form",
    "Treatment History",
    "Consent Form"
  ];
  const [activeTab, setActiveTab] = useState("Medical History Form");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  // get medical form list
  const { medicalForm, setMedicalForm, GetMedicalHistoryForm } =
    useGetMedicalHistoryForm();
  useEffect(() => {
    GetMedicalHistoryForm(
      `/api/admin/medical-history-forms/?patient_id=${props.selectedPatient.id}&page=${medicalForm.currentPage}`
    );
  }, [medicalForm.currentPage]);
  const handlePageChange = (event, value) => {
    setMedicalForm((prevState) => ({
      ...prevState,
      currentPage: value,
      loading: true
    }));
  };
  const medicalPerPage = 12;
  const startIndex1 = (medicalForm.currentPage - 1) * medicalPerPage;

  // get exam form list
  const { examTemplate, setExamTemplate, GetExamFormTemplate } =
    useGetExamFormTemplate();

  useEffect(() => {
    GetExamFormTemplate(`/api/exam-form/?page=${examTemplate.currentPage}`);
  }, [examTemplate.currentPage]);
  const handleChangeExam = (event, value) => {
    setExamTemplate((prevState) => ({
      ...prevState,
      currentPage: value,
      loading: true
    }));
  };
  const examPerPage = 12;
  const startIndex2 = (examTemplate.currentPage - 1) * examPerPage;

  // get treatment form list
  const { treatmentForm, setTreatmentForm, GetTreatmentForm } =
    useGetTreatmentForm();
  useEffect(() => {
    GetTreatmentForm(
      `/api/doctor/${props.selectedPatient?.id}/treatment-forms/?page=${treatmentForm.currentPage}`
    );
  }, [treatmentForm.currentPage]);
  const handleChange = (event, value) => {
    setTreatmentForm((prevState) => ({
      ...prevState,
      currentPage: value,
      loading: true
    }));
  };
  const treatmentPerPage = 12;
  const startIndex3 = (treatmentForm.currentPage - 1) * treatmentPerPage;

  // get consent form list
  const { consentForm, setConsentForm, GetConsentForm } = useGetConsentForm();
  useEffect(() => {
    GetConsentForm(
      `/api/doctor/${props.selectedPatient?.id}/consent-forms/?page=${consentForm.currentPage}`
    );
  }, [consentForm.currentPage]);
  const handleChangeConsentForm = (event, value) => {
    setConsentForm((prevState) => ({
      ...prevState,
      currentPage: value,
      loading: true
    }));
  };
  const consentPerPage = 12;
  const startIndex4 = (consentForm.currentPage - 1) * consentPerPage;

  //   convert date and time in formal form
  function formatDate(apiDate) {
    const date = new Date(apiDate);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return `${day}-${month}-${year} ${formattedHours}:${formattedMinutes}`;
  }
  return (
    <>
      <div className="flex gap-10 mt-7">
        {tabs.map((item, index) => (
          <p
            key={index}
            className={`cursor-pointer hover:text-primary hover:border-b-2 hover:border-primary text-[14px] font-[600px] text-[#787878] pb-2 ${
              activeTab === item ? "text-primary border-b-2 border-primary" : ""
            }`}
            onClick={() => handleTabClick(item)}
          >
            {item}
          </p>
        ))}
      </div>
      <hr />

      <div className="mt-5">
        {activeTab === "Medical History Form" && (
          <div className="">
            <div className="bg-white p-6 rounded">
              <div className="flex items-center justify-between mb-3">
                <h1 className="text-[24px] font-[600] text-[#272727]">
                  Medical History Form{" "}
                  <span className="text-primary text-[16px] font-[400]">
                    ({medicalForm?.data?.results?.length})
                  </span>
                </h1>
              </div>
              <hr />
              {medicalForm.loading ? (
                <>
                  {Array.from({ length: 2 }).map((_, index) => (
                    <TableSkelton_5 key={index} />
                  ))}
                </>
              ) : (
                <>
                  <div className="grid grid-cols-3 gap-4 w-full mt-3">
                    {medicalForm?.data?.results?.map((data, index) => (
                      <div
                        key={index}
                        className="border border-gray-200 rounded-xl cursor-pointer h-auto"
                      >
                        <div className="flex flex-col p-3 gap-5">
                          <div className="flex items-center ">
                            <h1 className="text-[16px] font-[600] text-[#272727]">
                              Medical History Form {startIndex1 + index + 1}
                            </h1>
                          </div>
                          <div className="w-[25%] text-[10px]  font-[600] bg-[#46F7B7] rounded  text-center py-1">
                            {data.status}
                          </div>
                          <div className="flex justify-between items-center">
                            <p className="text-[14px] font-[400] text-[#787878]">
                              Sent
                            </p>
                            <p className="text-[13px] font-[500] text-[#788BA5] flex items-center gap-2">
                              {data?.sent_time
                                ? formatDate(data?.sent_time)
                                : ""}
                            </p>
                          </div>

                          <div className="flex justify-between items-center">
                            <p className="text-[14px] font-[400] text-[#787878]">
                              Received
                            </p>
                            <p className="text-[13px] font-[500] text-[#788BA5] flex items-center gap-2">
                              {data?.received_time
                                ? formatDate(data?.received_time)
                                : ""}
                            </p>
                          </div>
                        </div>

                        <hr />

                        <div
                          onClick={() => {
                            Navigate(
                              "/manage-forms/medical-history/medical-history-form",
                              {
                                state: {
                                  selectedPatient: data
                                }
                              }
                            );
                          }}
                          className="flex justify-between items-center text-primary text-[13px] p-2"
                        >
                          <p className="font-[500]">View Form</p>
                          <HiOutlineChevronRight className="text-[18px]" />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="w-full flex justify-between items-center mt-2">
                    {medicalForm?.data?.results?.length > 0 && (
                      <p className="text-[#272727] font-roboto text-[14px] font-[400] leading-5">
                        {medicalForm?.data?.display_string}
                      </p>
                    )}
                    <Paginations
                      totalPages={medicalForm?.totalPages}
                      currentPage={medicalForm?.currentPage}
                      handlePageChange={handlePageChange}
                    />
                  </div>
                  {!medicalForm?.loading && medicalForm?.message && (
                    <div className="bg-white flex justify-center items-center w-full h-[300px]">
                      <ShowMessage message={medicalForm?.message} />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
        {activeTab === "Exam Form" && (
          <div className="">
            <div className="bg-white p-6 rounded">
              <div className="flex items-center justify-between mb-3">
                <div className="flex items-center gap-2">
                  {showExamForm && (
                    <HiArrowLeft
                      onClick={() => setShowExamForm(false)}
                      className="text-[25px] cursor-pointer"
                    />
                  )}
                  <h1 className="text-[24px] font-[600] text-[#272727]">
                    Exam Form{" "}
                    <span className="text-primary text-[16px] font-[400]">
                      {!showExamForm && (
                        <span className="text-primary text-[16px] font-[400]">
                          ({examTemplate?.data?.results?.length})
                        </span>
                      )}
                    </span>
                  </h1>
                </div>
                <div
                  onClick={() => {
                    setShowExamForm(true);
                  }}
                  className="flex justify-end items-center gap-2 w-[30%]"
                >
                  {!showExamForm && (
                    <div className="flex items-center justify-center border border-primary text-primary rounded p-2 cursor-pointer gap-1">
                      <GoPlus className="text-[22px]" />
                      <p className="text-[14px] font-[500]">Exam Form</p>
                    </div>
                  )}
                </div>
              </div>
              <hr />
              {showExamForm ? (
                <DoctorExamForm selectedPatient={props.selectedPatient} />
              ) : consentForm.loading ? (
                <>
                  {Array.from({ length: 2 }).map((_, index) => (
                    <TableSkelton_5 key={index} />
                  ))}
                </>
              ) : examTemplate?.data?.results > 0 ? (
                <DoctorExamForm selectedPatient={props.selectedPatient} />
              ) : (
                <>
                  <div className="grid grid-cols-3 gap-4 w-full mt-3">
                    {examTemplate?.data?.results?.map((data, index) => (
                      <div
                        key={index}
                        className="border border-gray-200 rounded-xl cursor-pointer h-auto"
                      >
                        <div className="flex flex-col p-3 gap-5">
                          <div className="flex items-center ">
                            <h1 className="text-[16px] font-[600] text-[#272727]">
                              Exam Form {startIndex2 + index + 1}
                            </h1>
                          </div>
                          <div className="w-[25%] text-[10px]  font-[600] bg-[#46F7B7] rounded  text-center py-1">
                            Shared
                          </div>
                          <div className="flex justify-between items-center">
                            <p className="text-[14px] font-[400] text-[#787878]">
                              Sent
                            </p>
                            <p className="text-[13px] font-[500] text-[#788BA5] flex items-center gap-2">
                              {data?.sent_time
                                ? formatDate(data?.sent_time)
                                : ""}
                            </p>
                          </div>

                          <div className="flex justify-between items-center">
                            <p className="text-[14px] font-[400] text-[#787878]">
                              Received
                            </p>
                            <p className="text-[13px] font-[500] text-[#788BA5] flex items-center gap-2">
                              {data?.received_time
                                ? formatDate(data?.received_time)
                                : ""}
                            </p>
                          </div>
                        </div>

                        <hr />

                        <div
                          onClick={() => {
                            Navigate("/manage-forms/exam/exam-form", {
                              state: {
                                selectedPatient: data
                              }
                            });
                          }}
                          className="flex justify-between items-center text-primary text-[13px] p-2"
                        >
                          <p className="font-[500]">View Form</p>
                          <HiOutlineChevronRight className="text-[18px]" />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="w-full flex justify-between items-center mt-2">
                    {examTemplate?.data?.results?.length > 0 && (
                      <p className="text-[#272727] font-roboto text-[14px] font-[400] leading-5">
                        {examTemplate?.data?.display_string}
                      </p>
                    )}
                    <Paginations
                      totalPages={examTemplate?.totalPages}
                      currentPage={examTemplate?.currentPage}
                      handlePageChange={handleChangeExam}
                    />
                  </div>
                  {!examTemplate?.loading && examTemplate?.message && (
                    <div className="bg-white flex justify-center items-center w-full h-[300px]">
                      <ShowMessage message={examTemplate?.message} />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
        {activeTab === "Treatment History" && (
          <div className="">
            <div className="bg-white p-6 rounded">
              <div className="flex justify-between items-center mb-3">
                <div className="flex items-center justify-between ">
                  <h1 className="text-[24px] font-[600] text-[#272727]">
                    Treatment History{" "}
                    <span className="text-primary text-[16px] font-[400]">
                      ({treatmentForm?.data?.results?.length})
                    </span>
                  </h1>
                </div>
                <div className="flex justify-end items-center gap-2 w-[30%]">
                  <div
                    onClick={() => {
                      Navigate("/manage-forms/treatment-form", {
                        state: {
                          selectedPatient: props.selectedPatient
                        }
                      });
                    }}
                    className="flex items-center justify-center border border-primary text-primary rounded p-2 cursor-pointer"
                  >
                    <GoPlus className="text-[22px]" />
                    <p className="text-[14px] font-[500]">Treatment Form</p>
                  </div>
                </div>
              </div>
              <hr />
              {treatmentForm.loading ? (
                <>
                  {Array.from({ length: 2 }).map((_, index) => (
                    <TableSkelton_5 key={index} />
                  ))}
                </>
              ) : (
                <>
                  <div className="grid grid-cols-3 gap-4 w-full mt-3">
                    {treatmentForm?.data?.results?.map((data, index) => (
                      <div
                        key={index}
                        className="border border-gray-200 rounded-xl cursor-pointer h-auto"
                      >
                        <div className="flex flex-col p-3 gap-5">
                          <h1 className="text-[16px] font-[600] text-[#272727]">
                            Treatment History {startIndex3 + index + 1}
                          </h1>
                          <div className="w-[25%] text-[10px]  font-[600] bg-[#46F7B7] rounded  text-center py-1">
                            {data?.status}
                          </div>
                          <div className="flex justify-between items-center">
                            <p className="text-[14px] font-[400] text-[#787878]">
                              Sent
                            </p>
                            <p className="text-[13px] font-[500] text-[#788BA5] flex items-center gap-2">
                              {data?.sent ? formatDate(data?.sent) : ""}
                            </p>
                          </div>
                        </div>
                        <hr />
                        <div
                          onClick={() => {
                            Navigate(
                              "/manage-forms/treatment/view-treatment-form",
                              {
                                state: {
                                  selectedPatient: data.treatment_form_id
                                }
                              }
                            );
                          }}
                          className="flex justify-between items-center text-primary text-[13px] p-2"
                        >
                          <p className="font-[500]">View Form</p>
                          <HiOutlineChevronRight className="text-[18px]" />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="w-full flex justify-between items-center mt-2">
                    {treatmentForm?.data?.results?.length > 0 && (
                      <p className="text-[#272727] font-roboto text-[14px] font-[400] leading-5">
                        {treatmentForm?.data?.display_string}
                      </p>
                    )}
                    <Paginations
                      totalPages={treatmentForm?.totalPages}
                      currentPage={treatmentForm?.currentPage}
                      handlePageChange={handleChange}
                    />
                  </div>
                  {!treatmentForm?.loading && treatmentForm?.message && (
                    <div className="bg-white flex justify-center items-center w-full h-[300px]">
                      <ShowMessage message={treatmentForm?.message} />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
        {activeTab === "Consent Form" && (
          <div className="">
            <div className="bg-white p-6 rounded">
              <div className="flex justify-between items-center mb-3">
                <div className="flex items-center justify-between gap-2">
                  {showComponent && (
                    <HiArrowLeft
                      onClick={() => setShowComponent(false)}
                      className="text-[25px] cursor-pointer"
                    />
                  )}

                  <h1 className="text-[24px] font-[600] text-[#272727]">
                    Consent Form{" "}
                    {!showComponent && (
                      <span className="text-primary text-[16px] font-[400]">
                        ({consentForm?.data?.results?.length})
                      </span>
                    )}
                  </h1>
                </div>
                <div className="flex justify-end items-center gap-2 w-[30%]">
                  {!showComponent && (
                    <>
                      <div
                        onClick={() => {
                          setShowComponent(true);
                        }}
                        className="flex items-center justify-center border border-primary text-primary rounded p-2 cursor-pointer gap-1"
                      >
                        <GoPlus className="text-[22px]" />
                        <p className="text-[14px] font-[500]"> Consent Form</p>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <hr />
              {showComponent ? (
                <DoctorConsentForm selectedPatient={props.selectedPatient} />
              ) : consentForm.loading ? (
                <>
                  {Array.from({ length: 2 }).map((_, index) => (
                    <TableSkelton_5 key={index} />
                  ))}
                </>
              ) : consentForm?.data?.results > 0 ? (
                <DoctorConsentForm selectedPatient={props.selectedPatient} />
              ) : (
                <>
                  <div className="grid grid-cols-3 gap-4 w-full mt-3">
                    {consentForm?.data?.results?.map((data, index) => (
                      <div
                        key={index}
                        className="border border-gray-200 rounded-xl cursor-pointer h-auto"
                      >
                        <div className="flex flex-col p-3 gap-5">
                          <div className="flex items-center ">
                            <h1 className="text-[16px] font-[600] text-[#272727]">
                              Consent Form {startIndex4 + index + 1}
                            </h1>
                          </div>
                          <div className="w-[25%] text-[10px]  font-[600] bg-[#46F7B7] rounded  text-center py-1">
                            {data?.status}
                          </div>
                          <div className="flex justify-between items-center">
                            <p className="text-[14px] font-[400] text-[#787878]">
                              Sent
                            </p>
                            <p className="text-[13px] font-[500] text-[#788BA5] flex items-center gap-2">
                              {data?.sent_time
                                ? formatDate(data?.sent_time)
                                : ""}
                            </p>
                          </div>
                          <div className="flex justify-between items-center">
                            <p className="text-[14px] font-[400] text-[#787878]">
                              Received
                            </p>
                            <p className="text-[13px] font-[500] text-[#788BA5] flex items-center gap-2">
                              {data?.received_time
                                ? formatDate(data?.received_time)
                                : ""}
                            </p>
                          </div>
                        </div>

                        <hr />

                        <div
                          onClick={() => {
                            Navigate(
                              "/manage-forms/consent/view-consent-form",
                              {
                                state: {
                                  selectedForm: data
                                }
                              }
                            );
                          }}
                          className="flex justify-between items-center text-primary text-[13px] p-2"
                        >
                          <p className="font-[500]">View Form</p>
                          <HiOutlineChevronRight className="text-[18px]" />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="w-full flex justify-between items-center mt-2">
                    {consentForm?.data?.results?.length > 0 && (
                      <p className="text-[#272727] font-roboto text-[14px] font-[400] leading-5">
                        {consentForm?.data?.display_string}
                      </p>
                    )}
                    <Paginations
                      totalPages={consentForm?.totalPages}
                      currentPage={consentForm?.currentPage}
                      handlePageChange={handleChangeConsentForm}
                    />
                  </div>
                  {!consentForm?.loading && consentForm?.message && (
                    <div className="bg-white flex justify-center items-center w-full h-[300px]">
                      <ShowMessage message={consentForm?.message} />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

function DoctorConsentForm({ selectedPatient }) {
  // for doctor
  const sigCanvas = useRef({});
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [doctorSignatureImage, setDoctorSignatureImage] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [patientIds, setPatientIds] = useState(null);
  const [guardianIds, setGuardianIds] = useState(null);
  const [activeDropdown, setActiveDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const handleShow = () => {
    setActiveDropdown(!activeDropdown);
  };

  const token = getAccessToken();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const saveSignature = () => {
    const canvas = sigCanvas.current.getTrimmedCanvas();
    canvas.toBlob((blob) => {
      const formData = new FormData();
      formData.append("image", blob, "signature.png");

      axios
        .post(`${BASE_URL}/api/upload-image/`, formData, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((response) => {
          setDoctorSignatureImage(response?.data?.image_url);
          Swal.fire({
            icon: "success",
            text: "Signature Saved Successfully!",
            customClass: {
              confirmButton: "custom-green-button"
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            text: "Oops! Something went wrong on our end. Please give us a moment to fix it. Feel free to try again later"
          });
        });
    });
  };

  const { consentForm, GetConsentForm } = useGetConsentForm();
  useEffect(() => {
    GetConsentForm(`/api/consent-form/template/`);
  }, []);

  const handleEndSignature = () => {
    setIsSaveEnabled(true);
  };
  const clearSignature = () => {
    sigCanvas.current.clear();
    setIsSaveEnabled(false);
  };

  const { AddForm, loading } = useAddForm();
  const handleSubmit = async () => {
    await AddForm(
      {
        patients: patientIds.length > 0 ? patientIds : [],
        guardians: guardianIds.length > 0 ? guardianIds : [],
        categories: answers,
        medical_documentation: consentForm?.data?.medical_documentation,
        doctor_signature_image: doctorSignatureImage
      },
      `/api/consent-form/`
    );
  };

  useEffect(() => {
    if (consentForm?.data?.categories) {
      const initializedAnswers = consentForm.data.categories.map(
        (category) => ({
          ...category,
          questions: category.questions.map((question) => ({
            ...question,
            mcq_answers: [{ selected_option: "" }]
          }))
        })
      );
      setAnswers(initializedAnswers);
    }
  }, [consentForm]);

  const handleAnswerChange = (e, categoryIndex, questionIndex) => {
    const newAnswers = [...answers];
    if (newAnswers[categoryIndex]?.questions[questionIndex]) {
      newAnswers[categoryIndex].questions[questionIndex].mcq_answers = [
        e.target.value
      ];
      setAnswers(newAnswers);
    }
  };
  const handleCheckboxChange = (
    categoryIndex,
    questionIndex,
    selectedOption
  ) => {
    setAnswers((prevAnswers) => {
      const newAnswers = [...prevAnswers];
      newAnswers[categoryIndex].questions[
        questionIndex
      ].mcq_answers[0].selected_option = selectedOption;
      return newAnswers;
    });
  };

  return (
    <>
      <div className="py-3">
        {consentForm.loading ? (
          <div className="w-full h-[400px] rounded flex justify-center items-center bg-white flex-col">
            <h1 className="text-[18px] font-[400] ">Loading ... </h1>
          </div>
        ) : (
          <>
            <h1 className="text-[24px] font-[600] text-[#272727]">
              Aufklärungs-, Anamnese- und Einwilligungsbogen
            </h1>
            <p className="text-[18px] font-[500] text-[#787878]">
              ADHS/ADS-Therapie mit Methylphenidat (Ritalin®) und anderen
              Medikamenten
            </p>
            <p className="text-[12px] font-[400] text-[#787878] mt-2">
              Bitte vor dem Arztgespräch sorgfältig lesen, Fragen notieren
            </p>

            <div className="">
              {consentForm?.data?.categories?.map((category, categoryIndex) => (
                <div key={categoryIndex}>
                  {category.category_name.trim() ===
                    "Insurance Information" && (
                    <>
                      <h2 className="text-2xl font-semibold mb-4 w-full">
                        {category.category_name}
                      </h2>
                      <div className="grid grid-cols-2 gap-4">
                        {category.questions?.map((question, questionIndex) => (
                          <div
                            key={`${categoryIndex}-${questionIndex}`}
                            className="w-full"
                          >
                            <div className="flex flex-col items-center w-full">
                              <p className="font-[500] text-[16px] text-[#272727] w-[100%]">
                                {question.question_statement}:
                              </p>
                              <p
                                className={`text-[#787878] font-[400] text-[14px] w-[100%] p-2 rounded ${
                                  category.category_name ===
                                  "Sehr geehrte Patientin, sehr geehrter Patient"
                                    ? "bg-gray-300"
                                    : "bg-[#FAFAFA] "
                                }`}
                              >
                                {question.text_answers?.[0]?.answer_text ||
                                  "N/A"}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                  {category.category_name.trim() ===
                    "Sehr geehrte Patientin, sehr geehrter Patient" && (
                    <>
                      <h2 className="text-2xl font-semibold my-4 w-full">
                        {category.category_name}
                      </h2>
                      {category.questions.map((question, questionIndex) => (
                        <div
                          key={`${categoryIndex}-${questionIndex}`}
                          className="mb-4"
                        >
                          {questionIndex !== 1 && (
                            <h3 className="text-[18px] font-[600] text-[#272727] mb-2 ">
                              {question.question_statement}
                            </h3>
                          )}
                          <ul className="list-disc list-inside">
                            {question.text_answers?.map(
                              (answer, answerIndex) => (
                                <li
                                  key={`${questionIndex}-${answerIndex}`}
                                  className="p-2 rounded text-[16px] font-[400] text-[#788BA5] bg-[#FAFAFA] "
                                >
                                  {answer.answer_text}
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      ))}
                    </>
                  )}
                  {category.category_name.trim() ===
                    "Bitte folgendes mitbringen:" && (
                    <>
                      <h2 className="text-2xl font-semibold mb-4">
                        {category.category_name}
                      </h2>
                      {category.questions.map((question, questionIndex) => (
                        <div
                          key={`${categoryIndex}-${questionIndex}`}
                          className="mb-4 bg-[#FAFAFA] p-3 rounded "
                        >
                          <h3 className="text-[16px] font-[400] text-[#788BA5] mb-2 pb-2">
                            {question.question_statement}
                          </h3>
                          <hr />
                          <ul className="list-disc list-inside ml-4">
                            {question.options.map((option, optionIndex) => (
                              <div
                                className="flex gap-3 my-5"
                                key={optionIndex}
                              >
                                <input
                                  type="checkbox"
                                  name={`question-${categoryIndex}-${questionIndex}`}
                                  className="w-[25px] h-[25px]"
                                  onChange={() => {
                                    handleCheckboxChange(
                                      categoryIndex,
                                      questionIndex,
                                      option.option_text
                                    );
                                  }}
                                />
                                <p className="text-[16px] font-[400] text-[#788BA5] w-[95%]">
                                  {option.option_text}
                                </p>
                              </div>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </>
                  )}
                  {category.category_name.trim() ===
                    "Hinweise /Ergänzungen" && (
                    <div className="mb-4">
                      {category.questions.map((question, questionIndex) => (
                        <div
                          key={`${categoryIndex}-${questionIndex}`}
                          className="mb-2"
                        >
                          <p className="text-[18px] font-[600] text-[#272727] mb-2">
                            {question.question_statement}
                          </p>
                          <textarea
                            className={`p-2 rounded text-[16px] font-[400] text-[#788BA5] border mb-2 w-full outline-none h-[130px]`}
                            placeholder={
                              question.text_answers?.length > 0
                                ? question.text_answers
                                    .map((answer) => answer.answer_text)
                                    .join("\n")
                                : "Enter your answer here..."
                            }
                            onChange={(e) =>
                              handleAnswerChange(
                                e,
                                categoryIndex,
                                questionIndex
                              )
                            }
                          />
                        </div>
                      ))}
                    </div>
                  )}
                  {category.category_name.trim() ===
                    "Fragen an Ihre Ärztin/Ihren Arzt:" && (
                    <div className="mb-4">
                      {category.questions.map((question, questionIndex) => (
                        <div
                          key={`${categoryIndex}-${questionIndex}`}
                          className="mb-2"
                        >
                          <p className="text-[18px] font-[600] text-[#272727] mb-2">
                            {question.question_statement}
                          </p>
                          <textarea
                            className={`p-2 rounded text-[14px] font-[400] text-[#788BA5] border mb-2 w-full outline-none h-[130px]`}
                            placeholder={
                              question.text_answers?.length > 0
                                ? question.text_answers
                                    .map((answer) => answer.answer_text)
                                    .join("\n")
                                : "Enter your answer here..."
                            }
                            onChange={(e) =>
                              handleAnswerChange(
                                e,
                                categoryIndex,
                                questionIndex
                              )
                            }
                          />
                        </div>
                      ))}
                    </div>
                  )}
                  {category.category_name.trim() === "Patientin / Patient" && (
                    <>
                      <h2 className="text-2xl font-semibold mb-4">
                        {category.category_name}
                      </h2>
                      <div className=" grid grid-cols-4 gap-2">
                        {category.questions.map((question, questionIndex) => (
                          <div
                            key={`${categoryIndex}-${questionIndex}`}
                            className={`${
                              questionIndex < 4
                                ? "col-span-1"
                                : "col-span-4 sm:col-span-1"
                            } `}
                          >
                            <p className="text-[14px] font-[400] text-[#787878] mb-2">
                              {question.question_statement}
                            </p>
                            <input
                              className="p-2 rounded text-[14px] font-[400] text-[#788BA5] border mb-2 w-full outline-none"
                              placeholder={
                                question.text_answers?.length > 0
                                  ? question.text_answers
                                      .map((answer) => answer.answer_text)
                                      .join("\n")
                                  : "Enter your answer here..."
                              }
                              onChange={(e) => {}}
                              disabled
                            />
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                  {category.category_name.trim() === "Allergien" && (
                    <div className="mb-4 border rounded" key={categoryIndex}>
                      <div className="bg-[#FAFAFA] p-3 flex justify-between items-center">
                        <h2 className="text-[18px] font-[600] ">
                          {category.category_name}
                        </h2>
                        <div>
                          {category.questions
                            .slice(0, 1)
                            .map((question, questionIndex) => (
                              <ul
                                className="list-disc list-inside flex w-[100px] justify-between"
                                key={questionIndex}
                              >
                                {question.options.map((option, optionIndex) => (
                                  <div
                                    key={optionIndex}
                                    className="text-[14px] font-[400] text-[#788BA5] w-[25px]"
                                  >
                                    {option.option_text}
                                  </div>
                                ))}
                              </ul>
                            ))}
                        </div>
                      </div>
                      <div className="px-3">
                        {category.questions.map((question, questionIndex) => (
                          <div
                            key={`${categoryIndex}-${questionIndex}`}
                            className="flex justify-between py-3"
                          >
                            <p className="text-[16px] font-[400] text-[#788BA5] mb-2 w-[87%]">
                              {question?.question_statement}
                            </p>
                            <div className="flex w-[100px] justify-between">
                              {question.options.map((option, optionIndex) => (
                                <label
                                  key={optionIndex}
                                  className="flex items-center"
                                >
                                  <input
                                    type="checkbox"
                                    name={`question-${categoryIndex}-${questionIndex}`}
                                    className="w-[25px] h-[25px]"
                                    onChange={() => {
                                      handleCheckboxChange(
                                        categoryIndex,
                                        questionIndex,
                                        option.option_text
                                      );
                                    }}
                                  />
                                </label>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {category.category_name.trim() ===
                    "Augenerkrankungen/-verletzungen?" && (
                    <div className="mb-4 border rounded">
                      <div className="bg-[#FAFAFA] p-3 flex justify-between items-center">
                        <h2 className="text-[18px] font-[600] ">
                          {category.category_name}
                        </h2>
                        <div className="  ">
                          {category.questions
                            .slice(0, 1)
                            .map((question, questionIndex) => (
                              <ul
                                className="list-disc list-inside flex w-[100px] justify-between ite "
                                key={questionIndex}
                              >
                                {question.options.map((option, optionIndex) => (
                                  <div
                                    key={optionIndex}
                                    className="text-[14px] font-[400] text-[#788BA5] w-[25px]"
                                  >
                                    {option.option_text}
                                  </div>
                                ))}
                              </ul>
                            ))}
                        </div>
                      </div>
                      <div className="px-3">
                        {category.questions.map((question, questionIndex) => (
                          <div
                            key={`${categoryIndex}-${questionIndex}`}
                            className="flex justify-between  py-3"
                          >
                            <p className="text-[16px] font-[400] text-[#788BA5] mb-2 w-[87%]">
                              {question.question_statement}
                            </p>
                            <div className="flex w-[100px] justify-between">
                              {question.options.map((option, optionIndex) => (
                                <label
                                  key={optionIndex}
                                  className="flex items-center"
                                >
                                  <input
                                    type="checkbox"
                                    name={`question-${categoryIndex}-${questionIndex}`}
                                    className="w-[25px] h-[25px]"
                                    onChange={() => {
                                      handleCheckboxChange(
                                        categoryIndex,
                                        questionIndex,
                                        option.option_text
                                      );
                                    }}
                                  />
                                </label>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {category.category_name.trim() ===
                    "Bindegewebserkrankungen?" && (
                    <div className="mb-4 border rounded">
                      <div className="bg-[#FAFAFA] p-3 flex justify-between items-center">
                        <h2 className="text-[18px] font-[600] ">
                          {category.category_name}
                        </h2>
                        <div className="  ">
                          {category.questions
                            .slice(0, 1)
                            .map((question, questionIndex) => (
                              <ul
                                className="list-disc list-inside flex w-[100px] justify-between ite "
                                key={questionIndex}
                              >
                                {question.options.map((option, optionIndex) => (
                                  <div
                                    key={optionIndex}
                                    className="text-[14px] font-[400] text-[#788BA5] w-[25px]"
                                  >
                                    {option.option_text}
                                  </div>
                                ))}
                              </ul>
                            ))}
                        </div>
                      </div>
                      <div className="px-3">
                        {category.questions.map((question, questionIndex) => (
                          <div
                            key={`${categoryIndex}-${questionIndex}`}
                            className="flex justify-between  py-3"
                          >
                            <p className="text-[16px] font-[400] text-[#788BA5] mb-2 w-[87%]">
                              {question.question_statement}
                            </p>
                            <div className="flex w-[100px] justify-between">
                              {question.options.map((option, optionIndex) => (
                                <label
                                  key={optionIndex}
                                  className="flex items-center"
                                >
                                  <input
                                    type="checkbox"
                                    name={`question-${categoryIndex}-${questionIndex}`}
                                    className="w-[25px] h-[25px]"
                                    onChange={() => {
                                      handleCheckboxChange(
                                        categoryIndex,
                                        questionIndex,
                                        option.option_text
                                      );
                                    }}
                                  />
                                </label>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {category.category_name.trim() ===
                    "Herz-/Kreislauf-Erkrankungen?" && (
                    <div className="mb-4 border rounded">
                      <div className="bg-[#FAFAFA] p-3 flex justify-between items-center">
                        <h2 className="text-[18px] font-[600] ">
                          {category.category_name}
                        </h2>
                        <div className="  ">
                          {category.questions
                            .slice(0, 1)
                            .map((question, questionIndex) => (
                              <ul
                                className="list-disc list-inside flex w-[100px] justify-between ite "
                                key={questionIndex}
                              >
                                {question.options.map((option, optionIndex) => (
                                  <div
                                    key={optionIndex}
                                    className="text-[14px] font-[400] text-[#788BA5] w-[25px]"
                                  >
                                    {option.option_text}
                                  </div>
                                ))}
                              </ul>
                            ))}
                        </div>
                      </div>
                      <div className="px-3">
                        {category.questions.map((question, questionIndex) => (
                          <div
                            key={`${categoryIndex}-${questionIndex}`}
                            className="flex justify-between  py-3"
                          >
                            <p className="text-[16px] font-[400] text-[#788BA5] mb-2 w-[87%]">
                              {question.question_statement}
                            </p>
                            <div className="flex w-[100px] justify-between">
                              {question.options.map((option, optionIndex) => (
                                <label
                                  key={optionIndex}
                                  className="flex items-center"
                                >
                                  <input
                                    type="checkbox"
                                    name={`question-${categoryIndex}-${questionIndex}`}
                                    className="w-[25px] h-[25px]"
                                    onChange={() => {
                                      handleCheckboxChange(
                                        categoryIndex,
                                        questionIndex,
                                        option.option_text
                                      );
                                    }}
                                  />
                                </label>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {category.category_name.trim() === "Gerinnungsstörungen?" && (
                    <div className="mb-4 border rounded">
                      <div className="bg-[#FAFAFA] p-3 flex justify-between items-center">
                        <h2 className="text-[18px] font-[600] ">
                          {category.category_name}
                        </h2>
                        <div className="  ">
                          {category.questions
                            .slice(0, 1)
                            .map((question, questionIndex) => (
                              <ul
                                className="list-disc list-inside flex w-[100px] justify-between ite "
                                key={questionIndex}
                              >
                                {question.options.map((option, optionIndex) => (
                                  <div
                                    key={optionIndex}
                                    className="text-[14px] font-[400] text-[#788BA5] w-[25px]"
                                  >
                                    {option.option_text}
                                  </div>
                                ))}
                              </ul>
                            ))}
                        </div>
                      </div>
                      <div className="px-3">
                        {category.questions.map((question, questionIndex) => (
                          <div
                            key={`${categoryIndex}-${questionIndex}`}
                            className="flex justify-between  py-3"
                          >
                            <p className="text-[16px] font-[400] text-[#788BA5] mb-2 w-[87%]">
                              {question.question_statement}
                            </p>
                            <div className="flex w-[100px] justify-between">
                              {question.options.map((option, optionIndex) => (
                                <label
                                  key={optionIndex}
                                  className="flex items-center"
                                >
                                  <input
                                    type="checkbox"
                                    name={`question-${categoryIndex}-${questionIndex}`}
                                    className="w-[25px] h-[25px]"
                                    onChange={() => {
                                      handleCheckboxChange(
                                        categoryIndex,
                                        questionIndex,
                                        option.option_text
                                      );
                                    }}
                                  />
                                </label>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {category.category_name.trim() ===
                    "Infektionskrankheiten?" && (
                    <div className="mb-4 border rounded">
                      <div className="bg-[#FAFAFA] p-3 flex justify-between items-center">
                        <h2 className="text-[18px] font-[600] ">
                          {category.category_name}
                        </h2>
                        <div className="  ">
                          {category.questions
                            .slice(0, 1)
                            .map((question, questionIndex) => (
                              <ul
                                className="list-disc list-inside flex w-[100px] justify-between ite "
                                key={questionIndex}
                              >
                                {question.options.map((option, optionIndex) => (
                                  <div
                                    key={optionIndex}
                                    className="text-[14px] font-[400] text-[#788BA5] w-[25px]"
                                  >
                                    {option.option_text}
                                  </div>
                                ))}
                              </ul>
                            ))}
                        </div>
                      </div>
                      <div className="px-3">
                        {category.questions.map((question, questionIndex) => (
                          <div
                            key={`${categoryIndex}-${questionIndex}`}
                            className="flex justify-between  py-3"
                          >
                            <p className="text-[16px] font-[400] text-[#788BA5] mb-2 w-[87%]">
                              {question.question_statement}
                            </p>
                            <div className="flex w-[100px] justify-between">
                              {question.options.map((option, optionIndex) => (
                                <label
                                  key={optionIndex}
                                  className="flex items-center"
                                >
                                  <input
                                    type="checkbox"
                                    name={`question-${categoryIndex}-${questionIndex}`}
                                    className="w-[25px] h-[25px]"
                                    onChange={() => {
                                      handleCheckboxChange(
                                        categoryIndex,
                                        questionIndex,
                                        option.option_text
                                      );
                                    }}
                                  />
                                </label>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {category.category_name.trim() ===
                    "Stoffwechselerkrankungen?" && (
                    <div className="mb-4 border rounded">
                      <div className="bg-[#FAFAFA] p-3 flex justify-between items-center">
                        <h2 className="text-[18px] font-[600] ">
                          {category.category_name}
                        </h2>
                        <div className="  ">
                          {category.questions
                            .slice(0, 1)
                            .map((question, questionIndex) => (
                              <ul
                                className="list-disc list-inside flex w-[100px] justify-between ite "
                                key={questionIndex}
                              >
                                {question.options.map((option, optionIndex) => (
                                  <div
                                    key={optionIndex}
                                    className="text-[14px] font-[400] text-[#788BA5] w-[25px]"
                                  >
                                    {option.option_text}
                                  </div>
                                ))}
                              </ul>
                            ))}
                        </div>
                      </div>
                      <div className="px-3">
                        {category.questions.map((question, questionIndex) => (
                          <div
                            key={`${categoryIndex}-${questionIndex}`}
                            className="flex justify-between  py-3"
                          >
                            <p className="text-[16px] font-[400] text-[#788BA5] mb-2 w-[87%]">
                              {question.question_statement}
                            </p>
                            <div className="flex w-[100px] justify-between">
                              {question.options.map((option, optionIndex) => (
                                <label
                                  key={optionIndex}
                                  className="flex items-center"
                                >
                                  <input
                                    type="checkbox"
                                    name={`question-${categoryIndex}-${questionIndex}`}
                                    className="w-[25px] h-[25px]"
                                    onChange={() => {
                                      handleCheckboxChange(
                                        categoryIndex,
                                        questionIndex,
                                        option.option_text
                                      );
                                    }}
                                  />
                                </label>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {category.category_name.trim() === "Frühere Operationen?" && (
                    <div className="mb-4 border rounded">
                      <div className="bg-[#FAFAFA] p-3 flex justify-between items-center">
                        <h2 className="text-[18px] font-[600] ">
                          {category.category_name}
                        </h2>
                        <div className="  ">
                          {category.questions
                            .slice(0, 1)
                            .map((question, questionIndex) => (
                              <ul
                                className="list-disc list-inside flex w-[100px] justify-between ite "
                                key={questionIndex}
                              >
                                {question.options.map((option, optionIndex) => (
                                  <div
                                    key={optionIndex}
                                    className="text-[14px] font-[400] text-[#788BA5] w-[25px]"
                                  >
                                    {option.option_text}
                                  </div>
                                ))}
                              </ul>
                            ))}
                        </div>
                      </div>
                      <div className="px-3">
                        {category.questions.map((question, questionIndex) => (
                          <div
                            key={`${categoryIndex}-${questionIndex}`}
                            className="flex justify-between  py-3"
                          >
                            <p className="text-[16px] font-[400] text-[#788BA5] mb-2 w-[87%]">
                              {question.question_statement}
                            </p>
                            <div className="flex w-[100px]  justify-between">
                              <input
                                type="checkbox"
                                className="w-[25px] h-[25px]"
                              />
                              <input
                                type="checkbox"
                                className="w-[25px] h-[25px]"
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {category.category_name.trim() ===
                    "Haben/Hatten Sie/Ihr Kind andere wesentl. Erkrankungen/Unfälle?" && (
                    <div className="mb-4 border rounded">
                      <div className="bg-[#FAFAFA] p-3 flex justify-between items-center">
                        <h2 className="text-[18px] font-[600] ">
                          {category.category_name}
                        </h2>
                        <div className="  ">
                          {category.questions
                            .slice(0, 1)
                            .map((question, questionIndex) => (
                              <ul
                                className="list-disc list-inside flex w-[100px] justify-between ite "
                                key={questionIndex}
                              >
                                {question.options.map((option, optionIndex) => (
                                  <div
                                    key={optionIndex}
                                    className="text-[14px] font-[400] text-[#788BA5] w-[25px]"
                                  >
                                    {option.option_text}
                                  </div>
                                ))}
                              </ul>
                            ))}
                        </div>
                      </div>
                      <div className="px-3">
                        {category.questions.map((question, questionIndex) => (
                          <div
                            key={`${categoryIndex}-${questionIndex}`}
                            className="flex justify-between  py-3"
                          >
                            <p className="text-[16px] font-[400] text-[#788BA5] mb-2 w-[87%]">
                              {question.question_statement}
                            </p>
                            <div className="flex w-[100px] justify-between">
                              {question.options.map((option, optionIndex) => (
                                <label
                                  key={optionIndex}
                                  className="flex items-center"
                                >
                                  <input
                                    type="checkbox"
                                    name={`question-${categoryIndex}-${questionIndex}`}
                                    className="w-[25px] h-[25px]"
                                    onChange={() => {
                                      handleCheckboxChange(
                                        categoryIndex,
                                        questionIndex,
                                        option.option_text
                                      );
                                    }}
                                  />
                                </label>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {category.category_name.trim() === "Geplante Maßnahmen" && (
                    <div className="  flex justify-between items-center ">
                      <h2 className="text-[18px] font-[600] ">
                        {category.category_name}
                      </h2>

                      <div className="px-3">
                        {category.questions.map((question, questionIndex) => (
                          <div
                            key={`${categoryIndex}-${questionIndex}`}
                            className=""
                          >
                            <ul className="list-disc list-inside flex gap-5 ">
                              {question.options.map((option, optionIndex) => (
                                <div className="flex items-center gap-2">
                                  <input
                                    type="checkbox"
                                    name={`question-${categoryIndex}-${questionIndex}`}
                                    className="w-[25px] h-[25px]"
                                    onChange={() => {
                                      handleCheckboxChange(
                                        categoryIndex,
                                        questionIndex,
                                        option.option_text
                                      );
                                    }}
                                  />
                                  <p
                                    key={optionIndex}
                                    className="text-[12px] font-[400] text-[#788BA5]"
                                  >
                                    {option.option_text}
                                  </p>
                                </div>
                              ))}
                            </ul>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {category.category_name.trim() ===
                    "Medikament and Dosierung" && (
                    <>
                      <div className="mb-4  flex flex-col bg-[#FAFAFA] p-3 ">
                        <h2 className="text-[18px] font-[600] ">
                          {category.category_name}
                        </h2>
                        <textarea
                          className="h-[130px] border p-3 rounded outline-none"
                          placeholder="Enter your text"
                        ></textarea>

                        <div className="px-3">
                          {category.questions?.map(
                            (question, questionIndex) => (
                              <>
                                <div
                                  key={`${categoryIndex}-${questionIndex}`}
                                  className="py-1"
                                >
                                  <ul className="list-disc list-inside flex justify-between ">
                                    {question.options?.map(
                                      (option, optionIndex) => (
                                        <div className="flex items-center gap-2">
                                          <input
                                            type="checkbox"
                                            className="w-[20px] h-[20px]"
                                          />
                                          <p
                                            key={optionIndex}
                                            className="text-[12px] font-[400] text-[#788BA5]"
                                          >
                                            {option.option_text}
                                          </p>
                                        </div>
                                      )
                                    )}
                                  </ul>
                                </div>
                                <div>
                                  {question.dosages?.length > 0 && (
                                    <div className="w-[50%] my-5">
                                      {question.dosages?.map((data, index) => (
                                        <div
                                          key={index}
                                          className="mb-4 flex items-center justify-between"
                                        >
                                          <p className="font-[400] text-[14px] text-[#788BA5]">
                                            {data.drug}
                                          </p>
                                          <p className="font-[400] text-[14px] text-[#788BA5]">
                                            {data.dosage}
                                          </p>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              </>
                            )
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {category.category_name.trim() === "Einwilligung" && (
                    <div className="mb-4 mt-[-20px] flex flex-col bg-[#FAFAFA] p-3 ">
                      <h2 className="text-[18px] font-[600] ">
                        {category.category_name}
                      </h2>

                      <div className="px-3">
                        {category.questions.map((question, questionIndex) => (
                          <div
                            key={`${categoryIndex}-${questionIndex}`}
                            className=" flex items-center justify-between py-3"
                          >
                            <input
                              type="checkbox"
                              className="w-[4%] h-[20px] "
                            />
                            <p className="text-[14px] font-[400] text-[#788BA5] w-[95%]">
                              {question.question_statement}
                            </p>
                          </div>
                        ))}
                      </div>
                      <div className="w-[600px]">
                        <div className="mt-2 border-[#DEE4ED] border-[1px] rounded-md">
                          <SignatureCanvas
                            penColor="black"
                            canvasProps={{
                              width: 600,
                              height: 200,
                              className: "sigCanvas"
                            }}
                          />
                        </div>
                        <div className="flex justify-between items-center mt-3">
                          <p className="text-[#787878] text-[14px]">
                            Unterschrift der Patientin/des Patienten / der
                            Eltern / der Betreuerin/des Betreuers*
                          </p>
                          <button className="px-2 text-white bg-[#ff4c4c] cursor-pointer font-[500] text-[16px] py-1 rounded">
                            Clear
                          </button>
                          <button
                            disabled={!isSaveEnabled}
                            className={`px-2 text-white font-[500] text-[16px] py-1 rounded ml-2 ${
                              isSaveEnabled
                                ? "bg-[#4CAF50] cursor-pointer"
                                : "bg-gray-400 cursor-not-allowed"
                            }`}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
              <div>
                <h2 className="text-[18px] font-[600] mb-2">
                  Ärztliche Dokumentation
                </h2>
                {consentForm?.data?.medical_documentation?.questions?.map(
                  (data, index) => (
                    <div
                      key={index}
                      className="bg-[#FAFAFA] p-3 flex  gap-2 w-full"
                    >
                      <p className="w-[85%] text-[#788BA5] text-[14px] font-[400]">
                        {data.question_statement}
                      </p>
                      <div className="flex items-center gap-2 w-[10%]">
                        <div className="flex items-center gap-2 text-[#788BA5] text-[14px] font-[400]">
                          <p>Nein</p>
                          <input
                            type="checkbox"
                            className="w-[20px] h-[20px] "
                          />
                        </div>
                        <div className="flex items-center gap-2 text-[#788BA5] text-[14px] font-[400]">
                          <p>Ja</p>
                          <input
                            type="checkbox"
                            className="w-[20px] h-[20px] "
                          />
                        </div>
                      </div>
                    </div>
                  )
                )}
                <div className="bg-[#FAFAFA] p-3">
                  <p className=" text-[#788BA5] font-[600] text-[14px]">
                    Ort, Datum (tt.mm.jj), Uhrzeit (hh.mm)
                  </p>
                  <p className="w-[30%] bg-white rounded p-3 border text-[#788BA5] font-[600] text-[14px] mt-2">
                    {
                      consentForm?.data?.medical_documentation
                        ?.location_datetime
                    }
                  </p>
                </div>
                <div className="w-[600px]">
                  <div className="mt-2 border-[#DEE4ED] border-[1px] rounded-md">
                    <SignatureCanvas
                      ref={sigCanvas}
                      penColor="black"
                      canvasProps={{
                        width: 600,
                        height: 200,
                        className: "sigCanvas"
                      }}
                      onEnd={handleEndSignature}
                    />
                  </div>
                  <div className="flex justify-between items-center mt-3">
                    <p className="text-[#787878] text-[14px]">
                      Unterschrift der Patientin/des Patienten / der Eltern /
                      der Betreuerin/des Betreuers*
                    </p>
                    <button
                      onClick={clearSignature}
                      className="px-2 text-white bg-[#ff4c4c] cursor-pointer font-[500] text-[16px] py-1 rounded"
                    >
                      Clear
                    </button>
                    <button
                      onClick={saveSignature}
                      disabled={!isSaveEnabled}
                      className={`px-2 text-white font-[500] text-[16px] py-1 rounded ml-2 ${
                        isSaveEnabled
                          ? "bg-[#4CAF50] cursor-pointer"
                          : "bg-gray-400 cursor-not-allowed"
                      }`}
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div className="flex items-center justify-end text-white  cursor-pointer font-[700] text-[16px] font-roboto py-1 rounded  w-full">
                  <div className=" border border-[#3B82F6] rounded-[5px] cursor-pointer relative  ">
                    <div
                      className="mall-circle-main justify-center items-center flex gap-2  p-2"
                      onClick={handleShow}
                    >
                      <span className="text-[#3B82F6] text-[14px]">Share</span>
                      <PiShareFat className="text-[#3B82F6] text-[20px] " />
                    </div>
                    <ShareGuardianDropdown
                      dropdownRef={dropdownRef}
                      activeDropdown={activeDropdown}
                      setActiveDropdown={setActiveDropdown}
                      setPatientIds={setPatientIds}
                      patientIds={patientIds}
                      setGuardianIds={setGuardianIds}
                      guardianIds={guardianIds}
                      handleSubmit={handleSubmit}
                      loading={loading}
                      selectedPatient={selectedPatient}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

function DoctorExamForm({ selectedPatient }) {
  const [share, setShare] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [patientIds, setPatientIds] = useState(null);
  const [guardianIds, setGuardianIds] = useState(null);

  const { examTemplate, setExamTemplate, GetExamFormTemplate } =
    useGetExamFormTemplate();
  useEffect(() => {
    GetExamFormTemplate(`/api/exam-form/template/`);
  }, []);

  const data = examTemplate.data?.categories || [];

  const handleNextTab = () => {
    if (data.length) {
      setSelectedTabIndex((prevIndex) =>
        prevIndex < data.length - 1 ? prevIndex + 1 : prevIndex
      );
    }
  };

  const handlePreviousTab = () => {
    if (data.length) {
      setSelectedTabIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    }
  };

  const { AddForm, loading } = useAddForm();
  const handleSubmit = async () => {
    await AddForm(
      {
        patients: patientIds.length > 0 ? patientIds : [],
        guardians: guardianIds.length > 0 ? guardianIds : [],
        categories: examTemplate?.data?.categories
      },
      `/api/exam-form/`
    );
  };
  const [activeDropdown, setActiveDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const handleShow = () => {
    setActiveDropdown(!activeDropdown);
  };
  return (
    <>
      <div className="">
        {examTemplate.loading ? (
          <div className="w-full h-[400px] rounded flex justify-center items-center bg-white flex-col">
            <h1 className="text-[18px] font-[400] ">Loading ... </h1>
          </div>
        ) : (
          <div className="h-auto  mt-2 bg-white rounded">
            {data[selectedTabIndex] && (
              <div className="flex gap-2 p-3">
                <h1 className="text-[16px] font-[600] font-roboto">
                  {data[selectedTabIndex].category}:
                </h1>
                <h1 className="text-[16px] font-[600] font-roboto">
                  {data[selectedTabIndex].category_name}
                </h1>
              </div>
            )}

            {data[selectedTabIndex]?.category === "A1" ? (
              <CategoryA1
                selectedTabIndex={selectedTabIndex}
                examTemplate={examTemplate}
                setExamTemplate={setExamTemplate}
              />
            ) : (
              <OtherCategories
                selectedTabIndex={selectedTabIndex}
                examTemplate={examTemplate}
                setExamTemplate={setExamTemplate}
              />
            )}
            <hr />
            <div className="h-auto mt-4">
              <div className="flex w-[100%] ">
                {examTemplate.data?.categories?.map((category, index) => (
                  <div key={index} className=" pt-2 w-[7%] ">
                    <div
                      className={` rounded-[7px] mx-1 ${
                        index <= selectedTabIndex
                          ? "border-[#69C08C] border-b-2"
                          : "bg-gray-200 border-b-2"
                      }`}
                    ></div>
                  </div>
                ))}
              </div>
              <div className="flex justify-between items-center px-3 mt-4">
                {selectedTabIndex === 0 ? (
                  <div className="flex gap-2 items-center justify-start text-gray-400 font-[700] text-[16px] font-roboto min-w-[120px] ">
                    <button>Back</button>
                  </div>
                ) : (
                  <div
                    onClick={handlePreviousTab}
                    className="flex gap-2 items-center justify-start text-gray-600 font-[700] text-[16px] font-roboto min-w-[120px]  cursor-pointer"
                  >
                    <button>Back</button>
                  </div>
                )}

                {selectedTabIndex ===
                examTemplate.data?.categories?.length - 1 ? (
                  <button className="flex items-center w-[70px] justify-center text-white  cursor-pointer font-[700] text-[16px] font-roboto py-1 rounded">
                    <div className=" border border-[#3B82F6] rounded-[5px] cursor-pointer relative  ">
                      <div
                        className="mall-circle-main justify-center items-center flex gap-2  p-2"
                        onClick={handleShow}
                      >
                        <span className="text-[#3B82F6] text-[14px]">
                          Share
                        </span>
                        <PiShareFat className="text-[#3B82F6] text-[20px] " />
                      </div>
                      <ShareGuardianDropdown
                        dropdownRef={dropdownRef}
                        activeDropdown={activeDropdown}
                        setActiveDropdown={setActiveDropdown}
                        setPatientIds={setPatientIds}
                        patientIds={patientIds}
                        setGuardianIds={setGuardianIds}
                        guardianIds={guardianIds}
                        handleSubmit={handleSubmit}
                        loading={loading}
                        selectedPatient={selectedPatient}
                      />
                    </div>
                  </button>
                ) : (
                  <div
                    onClick={handleNextTab}
                    className="flex items-center justify-end gap-2 text-white bg-primary  cursor-pointer font-[700] text-[16px] font-roboto py-1 px-4 rounded"
                  >
                    <button>Next</button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <ChoosePatientForAssignExamForm
        examTemplate={examTemplate}
        setIsOpenModal={setShare}
        isOpenModal={share}
      />
    </>
  );
}

const CategoryA1 = React.memo(
  ({ examTemplate, setExamTemplate, selectedTabIndex }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [questionData, setQuestionData] = useState({
      question_statement: "",
      options: [{ text: "" }, { text: "" }, { text: "" }, { text: "" }]
    });

    const questions =
      examTemplate.data?.categories[selectedTabIndex]?.questions || [];

    const handleAddQuestionClick = () => {
      setIsEditing(false);
      setQuestionData({
        question_statement: "",
        options: [{ text: "" }, { text: "" }, { text: "" }, { text: "" }]
      });
      setModalOpen(true);
    };

    const handleEditQuestion = (index) => {
      setIsEditing(true);
      setCurrentIndex(index);
      setQuestionData({
        question_statement: questions[index].question_statement,
        options: questions[index].options
      });
      setModalOpen(true);
    };

    const handleSave = () => {
      const updatedQuestions = isEditing
        ? questions?.map((q, idx) => (idx === currentIndex ? questionData : q))
        : [...questions, questionData];

      const updatedCategories = examTemplate.data?.categories?.map(
        (item, idx) =>
          idx === selectedTabIndex
            ? { ...item, questions: updatedQuestions }
            : item
      );
      setExamTemplate((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          categories: updatedCategories
        }
      }));

      setModalOpen(false);
    };

    const handleDeleteQuestion = (index) => {
      const updatedQuestions = questions?.filter((_, idx) => idx !== index);
      const updatedData = examTemplate.data?.categories?.map((item, idx) =>
        idx === selectedTabIndex
          ? { ...item, questions: updatedQuestions }
          : item
      );
      setExamTemplate((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          categories: updatedData
        }
      }));
    };

    return (
      <>
        {questions?.map((question, index) => (
          <div key={index} className="p-3">
            <div className="flex justify-between ">
              <h1 className="text-[16px] font-[600] font-roboto">
                {index + 1}. {question?.question_statement}
              </h1>

              <div className="flex gap-2">
                <button
                  onClick={() => handleEditQuestion(index)}
                  className="text-gray-300"
                >
                  <FaEdit />
                </button>
                <button
                  onClick={() => handleDeleteQuestion(index)}
                  className="text-red-300"
                >
                  <FaTrash />
                </button>
              </div>
            </div>
            {question?.options?.map((option, idx) => (
              <div
                key={idx}
                className="flex justify-between gap-2 my-1 text-[14px] font-[400] font-roboto"
              >
                <p>{option?.text}</p>
                <input
                  type="checkbox"
                  className="border border-[#E1E1E2] w-[18px] outline-none cursor-pointer "
                  disabled
                />
              </div>
            ))}
          </div>
        ))}

        <div
          className="flex items-center gap-2 text-primary text-[14px] font-[500] cursor-pointer px-3 my-2"
          onClick={handleAddQuestionClick}
        >
          <FaPlus />
          <p>Add new question </p>
        </div>

        <AddQuestionA1Modal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          onSave={handleSave}
          questionData={questionData}
          setQuestionData={setQuestionData}
          isEditing={isEditing}
        />
      </>
    );
  }
);

function OtherCategories({ selectedTabIndex, examTemplate, setExamTemplate }) {
  const [isModalVisible, setModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [questionData, setQuestionData] = useState({
    question_statement: "",
    options: [{ text: "" }, { text: "" }, { text: "" }, { text: "" }]
  });

  const questions =
    examTemplate.data?.categories[selectedTabIndex]?.questions || [];

  const handleAddQuestionClick = () => {
    setIsEditing(false);
    setQuestionData({
      question_statement: "",
      options:
        questions?.length > 0
          ? questions[questions?.length - 1].options
          : [{ text: "" }, { text: "" }, { text: "" }, { text: "" }]
    });
    setModalVisible(true);
  };

  const handleEditQuestion = (index) => {
    setIsEditing(true);
    setCurrentIndex(index);
    setQuestionData({
      question_statement: questions[index].question_statement,
      options: questions[index].options
    });
    setModalVisible(true);
  };

  const handleSave = () => {
    const updatedQuestions = isEditing
      ? questions?.map((q, idx) => (idx === currentIndex ? questionData : q))
      : [...questions, questionData];

    const updatedData = examTemplate.data?.categories?.map((item, idx) =>
      idx === selectedTabIndex ? { ...item, questions: updatedQuestions } : item
    );

    setExamTemplate((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        categories: updatedData
      }
    }));
    setModalVisible(false);
  };

  const handleDeleteQuestion = (index) => {
    const updatedQuestions = questions?.filter((_, idx) => idx !== index);
    const updatedData = examTemplate.data?.categories?.map((item, idx) =>
      idx === selectedTabIndex ? { ...item, questions: updatedQuestions } : item
    );
    setExamTemplate((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        categories: updatedData
      }
    }));
  };

  const previousOptions =
    questions?.length > 0 ? questions[questions?.length - 1].options : [];

  return (
    <>
      <div className="py-3 px-2">
        <div className="flex justify-end text-[10px]">
          <div className="flex w-[30%] justify-end">
            {examTemplate.data?.categories[selectedTabIndex]?.questions
              ?.length > 0 &&
              examTemplate.data?.categories[
                selectedTabIndex
              ]?.questions[0].options.map((option, index) => (
                <p key={index} className="w-[25%] text-center">
                  {option.text}
                </p>
              ))}
          </div>
          <div className="w-[5%]"></div>
        </div>
        {questions?.map((question, index) => (
          <div
            key={index}
            className={`flex p-2 rounded hover:bg-[#F9F9F9] w-[100%] ${
              index % 2 === 0 ? "bg-[#F9F9F9]" : "bg-white"
            }`}
          >
            <h1 className="text-[14px] font-[400] font-roboto w-[65%]">
              {question.question_statement}
            </h1>
            <div className="w-[30%] flex justify-end">
              {question?.options?.map((option, optionIndex) => (
                <div
                  key={optionIndex}
                  className="w-[25%] flex justify-center items-center"
                >
                  <input
                    type="checkbox"
                    className="border border-[#E1E1E2] w-[20px] h-[20px] outline-none cursor-pointer"
                    disabled
                  />
                </div>
              ))}
            </div>

            <div className="flex gap-2 justify-center w-[5%]">
              <button
                onClick={() => handleEditQuestion(index)}
                className="text-gray-300"
              >
                <FaEdit />
              </button>
              <button
                onClick={() => handleDeleteQuestion(index)}
                className="text-red-300"
              >
                <FaTrash />
              </button>
            </div>
          </div>
        ))}
      </div>

      <div
        className="flex items-center gap-2 text-primary text-[14px] font-[500] cursor-pointer px-3 my-2"
        onClick={handleAddQuestionClick}
      >
        <FaPlus />
        <p>Add new question</p>
      </div>

      <OtherCategoryQuestionModal
        isVisible={isModalVisible}
        onClose={() => setModalVisible(false)}
        onSave={handleSave}
        questionData={questionData}
        setQuestionData={setQuestionData}
        isEditing={isEditing}
        previousOptions={previousOptions}
      />
    </>
  );
}

const ShareGuardianDropdown = ({
  activeDropdown,
  dropdownRef,
  setActiveDropdown,
  selectedPatient,
  handleSubmit,
  loading,
  setPatientIds,
  patientIds,
  setGuardianIds,
  guardianIds
}) => {
  const handleCheckboxPatient = (id) => {
    setPatientIds([id]);
    setGuardianIds([]);
  };

  const handleCheckboxGuardian = (id) => {
    setGuardianIds([id]);
    setPatientIds([]);
  };

  const { treatmentForm, GetTreatmentForm } = useGetTreatmentForm();

  useEffect(() => {
    GetTreatmentForm(`/api/patient-guardian-data/${selectedPatient?.id}/`);
  }, []);

  return (
    <div>
      {activeDropdown && (
        <div
          className="w-[400px] h-[auto] bg-[white] absolute bottom-0 right-0 rounded-lg border-[1px] border-[#E4E7EC] p-[15px] dropdown-content z-50"
          ref={dropdownRef}
        >
          <div className="flex justify-between items-center">
            <div className="p-2 border-[1px] border-[#E4E7EC] rounded-md text-[25px] cursor-pointer">
              <TbUsersPlus className="text-[#344054]" />
            </div>
            <AiOutlineClose
              className="text-[#667085] text-[18px] cursor-pointer"
              onClick={() => setActiveDropdown(!activeDropdown)}
            />
          </div>
          <div>
            <h1 className="18px text-[#272727] text-center">
              Share with people
            </h1>
            <h1 className="text-[14px] text-[#788BA5] text-center">
              You are about to share the treatment plan with the <br />
              selected patient and their guardian.
            </h1>
            <div className="flex gap-2 mt-4 items-start">
              <CheckBox
                onChange={() => handleCheckboxPatient(treatmentForm?.data?.id)}
                checked={patientIds?.includes(treatmentForm?.data?.id)}
              />
              <div className="flex flex-col ">
                <p className="text-[14px] text-black font-medium text-left">
                  Patient
                </p>
                <p className="text-[14px] text-[#787878] font-normal text-left">
                  {treatmentForm?.data?.patient_name}
                </p>
              </div>
            </div>
            <div>
              {treatmentForm?.data?.guardians?.map((guardian, index) => (
                <div key={guardian.id} className="flex gap-2 mt-2 items-start">
                  <CheckBox
                    onChange={() => handleCheckboxGuardian(guardian?.id)}
                    checked={guardianIds?.includes(guardian?.id)}
                  />
                  <div className="flex flex-col">
                    <p className="text-[14px] text-black font-medium text-left">
                      Guardian {index + 1}
                    </p>
                    <p className="text-[14px] text-[#787878] font-normal text-left">
                      {guardian.guardian_name}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-end gap-2 mt-2">
              <button
                onClick={() => setActiveDropdown(!activeDropdown)}
                className="text-gray-600 py-2 px-4 rounded border font-roboto font-bold text-[14px]"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                disabled={
                  ((!patientIds || patientIds.length === 0) &&
                    (!guardianIds || guardianIds.length === 0)) ||
                  loading
                }
                className={`py-2 px-6 rounded font-roboto font-bold text-[14px] flex justify-center items-center ${
                  ((!patientIds || patientIds.length === 0) &&
                    (!guardianIds || guardianIds.length === 0)) ||
                  loading
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-blue-500 text-white"
                }`}
              >
                {loading ? <ButtonLoader_1 /> : "Share"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
