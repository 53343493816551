import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import useGetTreatmentForm from "../../../hooks/useGetTreatmentFormTemplate";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

const PatientTreatmentForm = () => {
  const location = useLocation();
  const { selectedForm = {} } = location.state || {};

  const { treatmentForm, GetTreatmentForm } = useGetTreatmentForm();
  useEffect(() => {
    GetTreatmentForm(`/api/treatment-plans/${selectedForm?.treatment_plan_id}`);
  }, []);

  return (
    <>
      <div className="p-8">
        <div className="flex items-center gap-2">
          <Link
            to="/manage-forms/treatment"
            className="text-[#788BA5] cursor-pointer bg-transparent text-[14px] font-[400] hover:text-primary"
          >
            Treatment Plan
          </Link>
          <MdOutlineKeyboardArrowRight className="text-[#788BA5] text-[20px] " />
          <p className="text-primary text-[14px] font-[400]">User</p>
        </div>
        <div className="h-auto p-5 mt-2 bg-white rounded">
          <h1 className="text-[20px] font-[700] mt-4">Treatment Plan</h1>
          <form class="w-full mx-auto mt-3">
            <label
              for="countries_disabled"
              class="block mb-2 text-[16px] font-[500] text-[#272727]"
            >
              Medication Needed
            </label>
            <input
              class="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-[5px] border border-gray-300 outline-none focus:border-primary"
              value={treatmentForm.data?.medication_needed}
            />

            <div className="flex justify-between w-full gap-2 mt-5">
              <div className="w-1/2">
                <label
                  for="countries_disabled"
                  class="block mb-2 text-[16px] font-[500] text-[#272727]"
                >
                  Start Date
                </label>
                <input
                  class="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-[5px] border border-gray-300 outline-none focus:border-primary"
                  value={treatmentForm.data?.start_date}
                />
              </div>
              <div className="w-1/2">
                <label
                  for="countries_disabled"
                  class="block mb-2 text-[16px] font-[500] text-[#272727]"
                >
                  End Date
                </label>
                <input
                  class="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-[5px] border border-gray-300 outline-none focus:border-primary"
                  value={treatmentForm.data?.end_date}
                />
              </div>
            </div>
            <label
              for="countries_disabled"
              class="block mb-2 text-[16px] font-[500] text-[#272727] mt-5"
            >
              Dosage
            </label>
            <input
              class="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-[5px] border border-gray-300 outline-none focus:border-primary"
              value={treatmentForm.data?.dosage}
            />

            <label
              for="countries_disabled"
              class="block mb-2 text-[16px] font-[500] text-[#272727] mt-5"
            >
              Frequency
            </label>
            <input
              class="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-[5px] border border-gray-300 outline-none focus:border-primary"
              value={treatmentForm.data?.frequency}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default PatientTreatmentForm;
