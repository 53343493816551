import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  Fragment
} from "react";
import AppHead from "../../seo/AppHead";
import { Link, useLocation } from "react-router-dom";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { CiSearch } from "react-icons/ci";
import Dropdown from "../../components/shared/Dropdown";
import { FaPlus } from "react-icons/fa";
import {
  HiOutlineDotsVertical,
  HiArrowLeft,
  HiOutlineChevronRight
} from "react-icons/hi";
import Paginations from "../../components/shared/Paginations";
import ShowMessage from "../../components/shared/ShowMessage";
import GlobalContext from "../../context/GlobalContext";
import { debounce } from "lodash";
import { TableSkelton_4 } from "../../components/shared/Skeltons";
import ChooseDoctorModal from "../../components/modals/ChooseDoctorModal";
import useGetDoctors from "../../hooks/useGetDoctors";
import { useNavigate } from "react-router-dom";
import useGetMedicalHistoryForm from "../../hooks/useGetMedicalHistoryForm";
import { TableSkelton_5 } from "../../components/shared/Skeltons";
import { GoPlus } from "react-icons/go";
import { MdOutlineDelete } from "react-icons/md";
import EditPersonalDetailModal from "../../components/modals/MedicalHistoryModals/EditPersonalDetailModal";
import { PiShareFat } from "react-icons/pi";
import AddMedicationTableModal from "../../components/modals/MedicalHistoryModals/AddMedicationTableModal";
import AddMedicalQuestionModal from "../../components/modals/MedicalHistoryModals/AddMedicalQuestionModal";
import { Dialog, Transition } from "@headlessui/react";
import useAddForm from "../../hooks/useAddForm";
import { ButtonLoader_1 } from "../../components/shared/Loaders";
import useGetMedicalHistoryTemplate from "../../hooks/useGetMedicalHistorTemplate";
import DeleteAssignedModal from "../../components/modals/DeleteAssignedModal";

function PatientDetails() {
  const location = useLocation();
  const { selectedPatient = {} } = location.state || {};

  return (
    <>
      <AppHead title="Dashboard - CHARITE" />
      <div className="p-10">
        <div className="flex items-center gap-2">
          <Link
            to="/patients"
            className="text-[#788BA5] cursor-pointer bg-transparent text-[14px] font-[400] hover:text-primary"
          >
            Patients
          </Link>
          <MdOutlineKeyboardArrowRight className="text-[#788BA5] text-[20px] " />
          <p className="text-primary text-[14px] font-[400]">Patient Profile</p>
        </div>
        <h1 className="text-[#272727] text-[24px] font-[600] mt-2">
          Personal Details
        </h1>
        <div
          className="w-full bg-white rounded-xl p-6 mt-4 flex"
          style={{ boxShadow: "0px 0px 7px #e0e0e0" }}
        >
          <div className="flex flex-col justify-center items-center w-[25%] mr-4">
            <svg
              className="w-[130px] h-[130px] rounded-full text-gray-200 dark:text-gray-700"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
            </svg>
            <h1 className="text-[#272727] text-[18px] font-[600] font-roboto mt-1">
              {selectedPatient?.user?.first_name}{" "}
              {selectedPatient?.user?.last_name}
            </h1>
            <p className="text-[#F6833B] text-[14px] font-[400] font-roboto bg-red-100 py-1 px-3 rounded">
              {selectedPatient?.disease}
            </p>
          </div>
          <div className=" border-l-2 border-gray-100 w-[38%] pl-10 flex flex-col justify-between">
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Phone No.
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedPatient?.user?.mobile_no}
              </p>
            </div>
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Email
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedPatient?.user?.email}
              </p>
            </div>{" "}
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Gender
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedPatient?.user?.gender}
              </p>
            </div>
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Marital status
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedPatient?.user?.marital_status}
              </p>
            </div>{" "}
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Date of Birth
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedPatient?.user?.date_of_birth}
              </p>
            </div>{" "}
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Occupation
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedPatient?.occupation}
              </p>
            </div>{" "}
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Blood Pressure
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedPatient?.bp}
              </p>
            </div>
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Sugar
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedPatient?.sugar}
              </p>
            </div>{" "}
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Blood Group
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedPatient?.blood_group}
              </p>
            </div>
          </div>
          <div className="w-[37%] pl-10 flex flex-col justify-between gap-2">
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Height
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedPatient?.height}
              </p>
            </div>
            {selectedPatient?.guardians.map((data, index) => (
              <div className="flex flex-col gap-2">
                <div className="flex items-center justify-between">
                  <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                    Guardian Name
                  </h1>
                  <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                    {data?.name}
                  </p>
                </div>{" "}
                <div className="flex items-center justify-between">
                  <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                    Relation
                  </h1>
                  <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                    {data?.relation}
                  </p>
                </div>{" "}
                <div className="flex items-center justify-between">
                  <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                    Guardian Mobile
                  </h1>
                  <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                    {data?.contact_no}
                  </p>
                </div>{" "}
                <div className="flex items-center justify-between">
                  <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                    Guardian Email
                  </h1>
                  <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                    {data?.email}
                  </p>
                </div>
              </div>
            ))}
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                State
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedPatient?.user?.state}
              </p>
            </div>
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                City
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedPatient?.user?.city}
              </p>
            </div>
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Postal Code
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedPatient?.user?.postal_code}
              </p>
            </div>{" "}
            <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Address
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedPatient?.user?.address}
              </p>
            </div>
            {/* <div className="flex items-center justify-between">
              <h1 className="text-[#272727] text-[14px] font-[600] font-roboto">
                Status
              </h1>
              <p className="text-[#788BA5] text-[14px] font-[400] font-roboto">
                {selectedPatient?.guardians?.status}
              </p>
            </div> */}
          </div>
        </div>

        <AssignDoctors selectedPatient={selectedPatient} />
        <FormTabs selectedPatient={selectedPatient} />
      </div>
    </>
  );
}

export default PatientDetails;

function AssignDoctors({ selectedPatient }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [filters, setFilters] = useState({ name: "Name (A-Z)", value: "asc" });
  const [query, setQuery] = useState("");
  const { doctors, setDoctors, GetDoctors } = useGetDoctors();
  const { updateResponse } = useContext(GlobalContext);

  const debouncedSearch = useCallback(
    debounce((value) => {
      setDoctors((prevState) => ({ ...prevState, currentPage: 1 }));
      GetDoctors(
        `/api/patient/${selectedPatient?.id}/assigned-doctors/?page=${doctors.currentPage}&search=${value}&sorting=${filters.value}`
      );
    }, 1000),
    []
  );
  const handleChange = (e) => {
    const { value } = e.target;
    setQuery(value);
    debouncedSearch(value);
  };
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);
  useEffect(() => {
    GetDoctors(
      `/api/patient/${selectedPatient?.id}/assigned-doctors/?page=${doctors.currentPage}&search=${query}&sorting=${filters.value}`
    );
  }, [doctors.currentPage, updateResponse, filters]);

  const handlePageChange = (event, value) => {
    setDoctors((prevState) => ({ ...prevState, currentPage: value }));
  };
  // dropdown for delete
  const [selected, setSelected] = useState(null);
  const [activeDropdown, setActiveDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const handleShow = (index) => {
    if (activeDropdown === index) {
      setActiveDropdown(!activeDropdown);
    } else {
      setActiveDropdown(index);
    }
  };
  const handleCloseDropdown = () => {
    setActiveDropdown(false);
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        e.target.closest(".small-circle-main") ||
        (dropdownRef.current && dropdownRef.current.contains(e.target))
      ) {
        return;
      }
      handleCloseDropdown();
    };
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  return (
    <>
      <div className="mb-2 mt-7 flex justify-between items-center w-full">
        <div className=" flex items-center w-[70%]">
          <div className="flex items-center justify-center">
            <h1 className="font-[700] text-[20px] font-roboto text-[#272727] w-[130px] ">
              Doctors List
            </h1>
            <p className="font-[400] text-[16px] font-roboto text-primary">
              ({doctors?.data?.count})
            </p>
          </div>
          <div className="border rounded-xl  flex items-center bg-white w-[100%] px-2 ml-2">
            <CiSearch className="text-[25px] text-[#788BA5]" />
            <input
              type="search"
              value={query}
              onChange={handleChange}
              placeholder="Search by name or email"
              className="w-full p-2 outline-none border-none"
            />
          </div>
        </div>
        <Dropdown
          options={[
            { name: "Name (A-Z)", value: "asc" },
            { name: "Name (Z-A)", value: "desc" },
            { name: "Age (Youngest First)", value: "youngest" },
            { name: "Age (Oldest First)", value: "oldest" }
          ]}
          selected={filters}
          onChange={(option) =>
            setFilters({ name: option.name, value: option.value })
          }
        />
      </div>

      <div
        className="w-full auto bg-white rounded-xl  p-4"
        style={{ boxShadow: "0px 0px 7px #e0e0e0" }}
      >
        {doctors.loading ? (
          <>
            {Array.from({ length: 2 }).map((_, index) => (
              <TableSkelton_4 key={index} />
            ))}
          </>
        ) : (
          <>
            <div className="grid grid-cols-4 gap-4 w-full">
              {doctors?.data?.results?.map((item, index) => (
                <div key={index} className="border p-4 rounded-xl">
                  <div className="flex justify-between items-center text-[#788BA5] relative ">
                    <p className="text-[14px] font-[500]">2d ago</p>
                    <HiOutlineDotsVertical
                      onClick={() => handleShow(index)}
                      className="cursor-pointer "
                    />
                    {activeDropdown === index && (
                      <div
                        className=" absolute w-[100px] bg-white flex flex-col rounded right-0 top-6 z-20"
                        ref={dropdownRef}
                        style={{ boxShadow: "0px 0px 7px #e0e0e0" }}
                      >
                        <p
                          onClick={() => {
                            setIsOpen(!isOpen);
                            setSelected(item);
                          }}
                          className="px-3 py-2 cursor-pointer font-[400] text-[13px] font-roboto text-[#787878] hover:bg-[#F5F5FE] hover:text-primary"
                        >
                          Remove
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col items-center justify-center">
                    <svg
                      className="w-[50px] h-[50px] rounded-full text-gray-200 dark:text-gray-700"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                    </svg>
                    <h1 className="text-[#272727] text-[18px] font-[600] font-roboto mt-1">
                      {item?.user?.first_name} {item?.user?.last_name}
                    </h1>
                    <p className="text-[#F6833B] text-[10px] font-[500] font-roboto bg-red-100 py-1 px-3 rounded mt-1">
                      {item?.department}
                    </p>
                  </div>
                </div>
              ))}

              <div
                onClick={() => setIsOpenModal(true)}
                className="border border-primary px-5 py-7 rounded-xl flex flex-col items-center justify-center gap-6 cursor-pointer"
              >
                <FaPlus className="text-white bg-primary rounded-[50%] p-1 text-[25px]" />
                <p className="text-[18px] font-[600] text-[#272727] text-center">
                  Assign Doctor
                </p>
              </div>
            </div>
            <div className="w-full flex justify-between items-center mt-2">
              <p className="text-[#272727] font-roboto text-[14px] font-[400] leading-5">
                {doctors?.data?.results?.length > 0 &&
                  doctors?.data?.display_string}
              </p>
              <Paginations
                totalPages={doctors.totalPages}
                currentPage={doctors.currentPage}
                handlePageChange={handlePageChange}
              />
            </div>
            {!doctors.loading && doctors.message && (
              <div className="bg-white flex justify-center items-center w-full h-[300px]">
                <ShowMessage message={doctors.message} />
              </div>
            )}
          </>
        )}
      </div>
      <ChooseDoctorModal
        setIsOpenModal={setIsOpenModal}
        isOpenModal={isOpenModal}
        selectedPatient={selectedPatient}
      />
      <DeleteAssignedModal
        setIsOpenModal={setIsOpen}
        isOpenModal={isOpen}
        setSelected={setSelected}
        url={`/api/admin/remove-doctor-from-patient/?doctor_id=${selected?.id}&patient_id=${selectedPatient?.id}`}
      />
    </>
  );
}

function FormTabs({ selectedPatient }) {
  const tabs = [
    "Medical History Form",
    "Exam Form",
    "Consent Form",
    "Treatment History"
  ];
  const [activeTab, setActiveTab] = useState("Medical History Form");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  return (
    <>
      <div className="flex gap-10 mt-7">
        {tabs.map((item, index) => (
          <p
            key={index}
            className={`cursor-pointer hover:text-primary hover:border-b-2 hover:border-primary text-[14px] font-[600px] text-[#787878] pb-2 ${
              activeTab === item ? "text-primary border-b-2 border-primary" : ""
            }`}
            onClick={() => handleTabClick(item)}
          >
            {item}
          </p>
        ))}
      </div>
      <hr />

      <div className="mt-5">
        {activeTab === "Medical History Form" && (
          <div
            className="w-full h-auto bg-white rounded-xl  "
            style={{ boxShadow: "0px 0px 7px #e0e0e0" }}
          >
            <AdminMedicalHistoryFormList selectedPatient={selectedPatient} />
          </div>
        )}
        {activeTab === "Exam Form" && (
          <div>
            <div
              className="w-full h-[400px] bg-white rounded-xl mt-5 pt-5"
              style={{ boxShadow: "0px 0px 7px #e0e0e0" }}
            >
              <h1 className="text-[24px] font-[600px] text-[#272727] px-5 pb-2">
                Exam Form
              </h1>
              <div className="w-full bg-[#DEE4ED] h-[1px]"></div>
              <div className="p-5 grid xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
                <div className="border border-primary px-5 pt-7 pb-3 rounded-xl flex flex-col items-center justify-between gap-6 cursor-pointer">
                  <FaPlus className="text-white bg-primary rounded-[50%] p-1 text-[25px] " />
                  <div className="w-full bg-[#DEE4ED] h-[1px]"></div>
                  <p className="text-[18px] font-[600px] text-[#272727]">
                    Add Exam Form
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeTab === "Consent Form" && (
          <div>
            <div
              className="w-full h-[400px] bg-white rounded-xl mt-5 pt-5"
              style={{ boxShadow: "0px 0px 7px #e0e0e0" }}
            >
              <h1 className="text-[24px] font-[600px] text-[#272727] px-5 pb-2">
                Consent Form
              </h1>
              <div className="w-full bg-[#DEE4ED] h-[1px]"></div>
              <div className="p-5 grid xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
                <div className="border border-primary px-5 pt-7 pb-3 rounded-xl flex flex-col items-center justify-between gap-6 cursor-pointer">
                  <FaPlus className="text-white bg-primary rounded-[50%] p-1 text-[25px] " />
                  <div className="w-full bg-[#DEE4ED] h-[1px]"></div>
                  <p className="text-[18px] font-[600px] text-[#272727]">
                    Add Consent Form
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeTab === "Treatment History" && (
          <div>
            <div
              className="w-full h-[400px] bg-white rounded-xl mt-5 pt-5"
              style={{ boxShadow: "0px 0px 7px #e0e0e0" }}
            >
              <h1 className="text-[24px] font-[600px] text-[#272727] px-5 pb-2">
                Treatment History Form
              </h1>
              <div className="w-full bg-[#DEE4ED] h-[1px]"></div>
              <div className="p-5 grid xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
                <div className="border border-primary px-5 pt-7 pb-3 rounded-xl flex flex-col items-center justify-between gap-6 cursor-pointer">
                  <FaPlus className="text-white bg-primary rounded-[50%] p-1 text-[25px] " />
                  <div className="w-full bg-[#DEE4ED] h-[1px]"></div>
                  <p className="text-[18px] font-[600px] text-[#272727] text-center">
                    Add Treatment History Form
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

function AdminMedicalHistoryFormList({ selectedPatient }) {
  console.log({ selectedPatient });

  const [showComponent, setShowComponent] = useState(true);
  const Navigate = useNavigate();
  const [filters, setFilters] = useState({
    name: "All",
    value: "all"
  });
  const { medicalForm, setMedicalForm, GetMedicalHistoryForm } =
    useGetMedicalHistoryForm();
  useEffect(() => {
    GetMedicalHistoryForm(
      `/api/admin/medical-history-forms/?patient_id=${selectedPatient.id}&page=${medicalForm.currentPage}`
    );
  }, [medicalForm.currentPage]);

  const handlePageChange = (event, value) => {
    setMedicalForm((prevState) => ({
      ...prevState,
      currentPage: value,
      loading: true
    }));
  };
  const medicalPerPage = 12;
  const startIndex1 = (medicalForm.currentPage - 1) * medicalPerPage;

  // dropdown
  const [activeDropdown, setActiveDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const handleShow = (index) => {
    if (activeDropdown === index) {
      setActiveDropdown(!activeDropdown);
    } else {
      setActiveDropdown(index);
    }
  };
  const handleCloseDropdown = () => {
    setActiveDropdown(false);
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        e.target.closest(".small-circle-main") ||
        (dropdownRef.current && dropdownRef.current.contains(e.target))
      ) {
        return;
      }
      handleCloseDropdown();
    };
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  return (
    <>
      {showComponent ? (
        <>
          {medicalForm.loading && (
            <>
              {Array.from({ length: 2 }).map((_, index) => (
                <TableSkelton_5 key={index} />
              ))}
            </>
          )}
          {medicalForm?.data?.results?.length > 0 && (
            <div className=" p-6 rounded">
              <div className="flex items-center justify-between mb-3">
                <Link to="/patients" className="flex items-center gap-2 ">
                  <h1 className="text-[24px] font-[600px] text-[#272727]  ">
                    Medical History Form{" "}
                    <span className="text-primary text-[16px] font-[400]">
                      ({medicalForm?.data?.results?.length})
                    </span>
                  </h1>
                </Link>
                <div className="flex items-center gap-2">
                  <Dropdown
                    options={[
                      { name: "All", value: "all" },
                      { name: "Dr. John Smith", value: "Dr. John Smith" },
                      { name: "Dr. Emily Johnson", value: "Dr. Emily Johnson" },
                      {
                        name: "Dr. Michael Williams",
                        value: "Dr. Michael Williams"
                      },
                      {
                        name: "Dr. Michael Williams",
                        value: "Dr. Michael Williams"
                      }
                    ]}
                    selected={filters}
                    onChange={(option) =>
                      setFilters({ name: option.name, value: option.value })
                    }
                  />
                  <div
                    onClick={() => setShowComponent(false)}
                    className="flex items-center justify-center text-white bg-primary rounded p-2 cursor-pointer"
                  >
                    <GoPlus className="text-[22px]" />
                    <p className="text-[14px] font-[500]">
                      {" "}
                      Medical History Form
                    </p>
                  </div>
                </div>
              </div>
              <hr />
              <div className="grid grid-cols-3 gap-4 w-full mt-3">
                {medicalForm?.data?.results?.map((data, index) => (
                  <div
                    key={index}
                    className="border border-gray-200 rounded-xl cursor-pointer h-auto"
                  >
                    <div className="flex flex-col p-3 gap-5">
                      <div className="flex items-center justify-between">
                        <h1 className="text-[16px] font-[600] text-[#272727]">
                          Medical History Form {startIndex1 + index + 1}
                        </h1>

                        {/* <div className="relative small-circle-main">
                              <HiOutlineDotsVertical
                                className="cursor-pointer"
                                onClick={() => handleShow(index)}
                              />
                              {activeDropdown === index && (
                                <div
                                  ref={dropdownRef}
                                  className="absolute py-1 w-[100px] bg-white flex flex-col rounded-sm right-[30%] top-[100%] z-20 shadow-md"
                                >
                                  <p className="px-3 py-1 cursor-pointer font-[400] text-[13px] font-roboto text-[#787878] hover:bg-[#F5F5FE] hover:text-primary">
                                    Edit
                                  </p>
                                  <p className="px-3 py-1 cursor-pointer font-[400] text-[13px] font-roboto text-[#787878] hover:bg-[#F5F5FE] hover:text-primary">
                                    Delete
                                  </p>
                                </div>
                              )}
                            </div> */}
                      </div>
                      <div className="w-[25%] text-[10px]  font-[600] bg-[#46F7B7] rounded  text-center py-1">
                        {data.status}
                      </div>
                      <div className="flex justify-between items-center">
                        <p className="text-[14px] font-[400] text-[#787878]">
                          Sent
                        </p>
                        <p className="text-[13px] font-[500] text-[#788BA5] flex items-center gap-2">
                          {data?.sent_time}
                        </p>
                      </div>

                      <div className="flex justify-between items-center">
                        <p className="text-[14px] font-[400] text-[#787878]">
                          Received
                        </p>
                        <p className="text-[13px] font-[500] text-[#788BA5] flex items-center gap-2">
                          {data?.received_time}
                        </p>
                      </div>
                    </div>

                    <hr />
                    <div
                      onClick={() => {
                        Navigate(
                          "/manage-forms/medical-history/view-medical-history",
                          {
                            state: {
                              selectedPatient: data
                            }
                          }
                        );
                      }}
                      className="flex justify-between items-center text-primary text-[13px] p-2"
                    >
                      <p className="font-[500]">View Form</p>
                      <HiOutlineChevronRight className="text-[18px]" />
                    </div>
                  </div>
                ))}
              </div>

              <div className="w-full flex justify-between items-center mt-2">
                <p className="text-[#272727] font-roboto text-[14px] font-[400] leading-5">
                  {medicalForm?.data?.display_string}
                </p>
                <Paginations
                  totalPages={medicalForm.data?.totalPages}
                  currentPage={medicalForm.data?.currentPage}
                  handlePageChange={handlePageChange}
                />
              </div>
              {!medicalForm?.data.loading && medicalForm?.data.message && (
                <div className="bg-white flex justify-center items-center w-full h-[300px]">
                  <ShowMessage message={medicalForm?.data.message} />
                </div>
              )}
            </div>
          )}
          {!medicalForm.loading && medicalForm?.data?.results?.length === 0 && (
            <AdminMedicalHistoryTemplate selectedPatient={selectedPatient} />
          )}
        </>
      ) : (
        <AdminMedicalHistoryTemplate
          setShowComponent={setShowComponent}
          selectedPatient={selectedPatient}
        />
      )}
    </>
  );
}

function AdminMedicalHistoryTemplate({ selectedPatient, setShowComponent }) {
  const { medicalTemplate, setMedicalTemplate, GetMedicalHistoryTemplate } =
    useGetMedicalHistoryTemplate();
  useEffect(() => {
    GetMedicalHistoryTemplate(`/api/medical-history-forms/template/`);
  }, []);

  const [personalDetail, setPersonalDetail] = useState({
    clinicName:
      "Klinik und Hochschulambulanz für Psychiatrie und Psychotherapie",
    phone1: "(030) 450 517 666",
    director: "Direktor. Univ.-Prof. Dr. med. Christian Otte",
    phone2: "(030) 450 517 930",
    specialClinic: "Spezialambulanz für ADHS im Erwachsenenalter",
    email: "adhs-spezialambulanz@charite.de",
    team: `Leitung: Priv.-Doz. Dr. med. Julian Hellmann-Regen
    Fr. Dr. med. Olga Arbach
    Fr. Dr. med. Maria Lietz`,
    website: "http://psychiatrie.charite.de/"
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleEditClick = () => {
    setIsModalOpen(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPersonalDetail((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const { AddForm, loading } = useAddForm();
  const handleSubmit = async () => {
    await AddForm(
      {
        patients: selectedPatient?.id ? [selectedPatient.id] : [],
        categories: medicalTemplate?.data?.categories
      },
      `/api/medical-history-forms/`
    );
  };
  const handleSave = () => {
    // Save logic here (e.g., send data to an API)
    setIsModalOpen(false);
  };
  return (
    <>
      <div>
        <div className="p-6">
          <div className="flex justify-between items-center">
            <Link
              to="/patients/patient-details"
              className="flex items-center gap-2 "
            >
              <HiArrowLeft
                onClick={() => setShowComponent(true)}
                className="text-[25px] cursor-pointer"
              />
              <h1 className="text-[24px] font-[600px] text-[#272727]  ">
                Medical History Form
              </h1>
            </Link>

            <div className="flex justify-end items-center ">
              {loading ? (
                <div className="flex border border-[#3B82F6] bg-primary justify-center items-center p-2 rounded-md gap-2 cursor-pointer w-[78px] h-[40px]">
                  <ButtonLoader_1 />
                </div>
              ) : (
                <div
                  onClick={handleSubmit}
                  className="flex border border-[#3B82F6] justify-center items-center p-2 rounded-md gap-2 cursor-pointer"
                >
                  <span className="text-[#3B82F6] text-[14px]">Share</span>
                  <PiShareFat className="text-[#3B82F6] text-[20px]" />
                </div>
              )}
            </div>
          </div>
          <div className="w-full bg-[#DEE4ED] h-[1px] mt-4"></div>
          {medicalTemplate.loading ? (
            <div className="w-full h-[400px] rounded flex justify-center items-center bg-white flex-col">
              <h1 className="text-[18px] font-[400] ">Loading ... </h1>
            </div>
          ) : (
            <div className="bg-white  mt-2 rounded w-full h-auto p-2">
              <div className="w-full flex justify-center">
                <img
                  src="/assets/logo/logo2.png"
                  alt="CHARITE"
                  className="w-[170px]"
                />
              </div>
              <h1 className="text-[#272727] text-[24px] font-[700] text-center mt-2">
                CharitéCentrum für Neurologie, Neurochirurgie und Psychiatrie
              </h1>
              <div className="mt-10 flex relative">
                <div className="flex gap-6 w-[20%]">
                  <img src="/assets/images/user.png" alt="CHARITE" />
                </div>
                <div className="w-[80%]">
                  <div className="border-l-2 border-gray-100 w-full pl-10 flex flex-col justify-between ">
                    <div className="flex items-center justify-between w-full">
                      <h1 className="text-[#272727] text-[14px] font-[600] font-roboto w-[70%]">
                        {personalDetail.clinicName}
                      </h1>
                      <p className="text-[#788BA5] text-[14px] font-[400] font-roboto w-[30%]">
                        {personalDetail.phone1}
                      </p>
                    </div>
                    <div className="flex items-center justify-between w-full">
                      <h1 className="text-[#272727] text-[14px] font-[500] font-roboto w-[70%]">
                        {personalDetail.director}
                      </h1>
                      <p className="text-[#788BA5] text-[14px] font-[400] font-roboto w-[30%]">
                        {personalDetail.phone2}
                      </p>
                    </div>
                    <div className="flex items-center justify-between w-full">
                      <h1 className="text-[#272727] text-[14px] font-[600] font-roboto w-[70%]">
                        {personalDetail.specialClinic}
                      </h1>
                      <p className="text-[#788BA5] text-[14px] font-[400] font-roboto w-[30%]">
                        {personalDetail.email}
                      </p>
                    </div>
                    <div className="flex justify-between w-full">
                      <h1 className="text-[#272727] text-[14px] font-[500] font-roboto w-[70%]">
                        {personalDetail.team.split("\n").map((line, index) => (
                          <span key={index}>
                            {line}
                            <br />
                          </span>
                        ))}
                      </h1>
                      <p className="text-[#788BA5] text-[14px] font-[400] font-roboto w-[30%]">
                        {personalDetail.website}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-10">
                <h1 className="text-[#272727] text-[20px] font-[600] text-center">
                  {medicalTemplate?.categories?.category_name}
                </h1>
                <h1 className="text-[#E41D1D] text-[16px] font-[400] text-center">
                  CharitéCentrum für Neurologie, Neurochirurgie und Psychiatrie
                </h1>
              </div>

              <QuestionSection
                medicalTemplate={medicalTemplate}
                setMedicalTemplate={setMedicalTemplate}
              />
            </div>
          )}
        </div>
      </div>
      <EditPersonalDetailModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        personalDetail={personalDetail}
        onChange={handleInputChange}
        onSave={handleSave}
      />
    </>
  );
}

const QuestionSection = ({ medicalTemplate, setMedicalTemplate }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [activeCategoryIndex, setActiveCategoryIndex] = useState(null);

  const handleAddQuestionClick = (qIndex) => {
    setActiveCategoryIndex(qIndex);
    setIsModalOpen(true);
  };

  const handleSelectQuestionType = (type) => {
    setModalType(type);
    setIsModalOpen(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalType(null);
    setActiveCategoryIndex(null);
  };

  const handleSaveTableQuestion = (newQuestion, headers, rows) => {
    setMedicalTemplate((prevForm) => ({
      ...prevForm,
      data: {
        ...prevForm.data,
        categories: prevForm.data.categories.map((category, index) =>
          index === activeCategoryIndex
            ? {
                ...category,
                questions: [
                  ...category.questions,
                  {
                    question_statement: newQuestion,
                    question_type: "table",
                    sequence_number: rows.length,
                    text_answer: null,
                    table_data: [
                      {
                        table_headers: headers.map(
                          (header) => header.column_header
                        ),
                        table_rows: []
                      }
                    ]
                  }
                ]
              }
            : category
        )
      }
    }));
    handleCloseModal();
  };

  const handleSaveTextQuestion = (newQuestion) => {
    setMedicalTemplate((prevForm) => ({
      ...prevForm,
      data: {
        ...prevForm.data,
        categories: prevForm.data.categories.map((category, index) =>
          index === activeCategoryIndex
            ? {
                ...category,
                questions: [
                  ...category.questions,
                  {
                    question_statement: newQuestion,
                    question_type: "text",
                    sequence_number: 1,
                    text_answer: null
                  }
                ]
              }
            : category
        )
      }
    }));
    handleCloseModal();
  };

  const handleDeleteQuestion = (categoryIndex, questionIndex) => {
    setMedicalTemplate((prevForm) => ({
      ...prevForm,
      data: {
        ...prevForm.data,
        categories: prevForm.data.categories.map((category, catIndex) =>
          catIndex === categoryIndex
            ? {
                ...category,
                questions: category.questions.filter(
                  (_, qIndex) => qIndex !== questionIndex
                )
              }
            : category
        )
      }
    }));
  };

  return (
    <div className="overflow-x-auto relative">
      {medicalTemplate?.data?.categories?.map((qItem, qIndex) => (
        <div key={qIndex} className="relative">
          <h1 className="font-[600] text-[20px] text-[#272727] text-center mt-2">
            {qItem.category_name}
          </h1>
          {qItem?.questions?.map((item, itemIndex) => (
            <div key={itemIndex} className="relative mt-6">
              <h1 className="text-[#272727] text-[16px] font-[600] w-[93%]">
                {itemIndex + 1}: {item.question_statement}
              </h1>
              <div className="absolute right-0 top-0 flex">
                <MdOutlineDelete
                  onClick={() => handleDeleteQuestion(qIndex, itemIndex)}
                  className="p-1 text-[30px] text-red-300 rounded-[7px] cursor-pointer"
                />
              </div>
              {item.question_type === "table" && (
                <table className="min-w-full border-collapse border border-gray-300 mt-2">
                  <thead>
                    <tr className="bg-gray-100">
                      {item?.table_data?.[0]?.table_headers?.map(
                        (header, index) => (
                          <th
                            key={index}
                            className="border border-gray-300 p-2 text-left"
                          >
                            {header}
                          </th>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {Array.from({ length: item.sequence_number }).map(
                      (_, rowIndex) => (
                        <tr key={rowIndex}>
                          {item?.table_data?.[0]?.table_headers?.map(
                            (_, colIndex) => (
                              <td
                                key={colIndex}
                                className="border border-gray-300"
                              >
                                <input
                                  type="text"
                                  placeholder="-"
                                  className="w-full p-2 border-gray-300 rounded bg-transparent text-[#787878]"
                                  value={
                                    item?.table_data?.[0]?.table_rows?.[
                                      rowIndex
                                    ]?.[colIndex]?.answer || ""
                                  }
                                />
                              </td>
                            )
                          )}
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              )}

              {item.question_type === "text" && (
                <input
                  type="text"
                  value={""}
                  placeholder="Enter your answer here"
                  disabled
                  className="outline-none bg-gray-100 rounded py-2 px-4 w-full border text-[#787878] mt-2"
                />
              )}
            </div>
          ))}
          <div
            className="flex items-center gap-2 text-primary text-[14px] font-[500] cursor-pointer mt-5 w-[140px]"
            onClick={() => handleAddQuestionClick(qIndex)}
          >
            <FaPlus />
            <p className="border-b-2 border-primary">Add Question</p>
          </div>
        </div>
      ))}

      <Transition.Root show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={handleCloseModal}>
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full max-w-md p-6">
                <h1 className="2xl:text-[24px] font-semibold lg:text-[20px]">
                  Select Question Type
                </h1>
                <div className="mt-4 space-y-4">
                  <button
                    className="w-full py-2 px-4 bg-blue-500 text-white rounded"
                    onClick={() => handleSelectQuestionType("table")}
                  >
                    Table Question
                  </button>
                  <button
                    className="w-full py-2 px-4 bg-green-500 text-white rounded"
                    onClick={() => handleSelectQuestionType("text")}
                  >
                    Text Question
                  </button>
                </div>
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {modalType === "table" && (
        <AddMedicationTableModal
          isOpen={true}
          onClose={handleCloseModal}
          onSave={handleSaveTableQuestion}
        />
      )}

      {modalType === "text" && (
        <AddMedicalQuestionModal
          isOpen={true}
          onClose={handleCloseModal}
          onSave={handleSaveTextQuestion}
        />
      )}
    </div>
  );
};
