export const treatmentData = {
  title: "Patient Treatment Form",
  description: "This is a treatment form for patient assessment.",
  status: "Pending",
  patient: 1,
  categories: [
    {
      category_name: "Diagnosis Details",
      questions: [
        {
          question_statement: "Type",
          question_type: "DROPDOWN",
          answers: {
            answer_text: ""
          },
          dropdown_options: ["Inpatient", "Outpatient", "Emergency"]
        },
        {
          question_statement: "Condition",
          question_type: "DROPDOWN",
          answers: {
            answer_text: ""
          },
          dropdown_options: ["Stable", "Critical", "Recovering"]
        }
      ]
    },
    {
      category_name: "Previous Treatment",
      questions: [
        {
          question_statement: "Upload Treatment Media",
          question_type: "FILE",
          answers: {
            file_upload: ""
          }
        },
        {
          question_statement: "Add Description:",
          question_type: "TEXT",
          answers: {
            answer_text: ""
          }
        },
        {
          question_statement: "Lifestyle and Support",
          question_type: "TEXT",
          answers: {
            answer_text: ""
          }
        },
        {
          question_statement: "Behavioral Therapy",
          question_type: "TEXT",
          answers: {
            answer_text: ""
          }
        },
        {
          question_statement: "Educational Interventions",
          question_type: "TEXT",
          answers: {
            answer_text: ""
          }
        }
      ]
    }
  ]
};
