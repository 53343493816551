import React, { useEffect, useState } from "react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { PiShareFat } from "react-icons/pi";
import { Link, useLocation } from "react-router-dom";
import { ButtonLoader_1 } from "../../../components/shared/Loaders";
import useGetMedicalHistoryForm from "../../../hooks/useGetMedicalHistoryForm";
import useAddForm from "../../../hooks/useAddForm";
const personalDetail = {
  clinicName: "Klinik und Hochschulambulanz für Psychiatrie und Psychotherapie",
  phone1: "(030) 450 517 666",
  director: "Direktor. Univ.-Prof. Dr. med. Christian Otte",
  phone2: "(030) 450 517 930",
  specialClinic: "Spezialambulanz für ADHS im Erwachsenenalter",
  email: "adhs-spezialambulanz@charite.de",
  team: `Leitung: Priv.-Doz. Dr. med. Julian Hellmann-Regen
    Fr. Dr. med. Olga Arbach
    Fr. Dr. med. Maria Lietz`,
  website: "http://psychiatrie.charite.de/"
};
function PatientMedicalHistoryForm() {
  const location = useLocation();
  const { selectedForm = {} } = location.state || {};
  const { AddForm, loading } = useAddForm();
  const [submitMedicalForm, setSubmitMedicalForm] = useState(null);
  const { medicalForm, GetMedicalHistoryForm } = useGetMedicalHistoryForm();

  useEffect(() => {
    if (medicalForm.data) {
      setSubmitMedicalForm(medicalForm.data);
    }
  }, [medicalForm]);

  useEffect(() => {
    GetMedicalHistoryForm(`/api/medical-history-forms/${selectedForm?.id}/`);
  }, []);

  const handleSubmit = async () => {
    await AddForm(
      {
        form_id: selectedForm?.id,
        questions: submitMedicalForm.categories.flatMap((category) =>
          category.questions.map(
            ({ question_statement, sequence_number, ...rest }) => rest
          )
        )
      },
      `/api/submit-medicalhistory-form/`
    );
  };

  const handleTextInputChange = (e, c_item_id, q_item_id) => {
    const value = e.target.value;
    const updatedCategories = submitMedicalForm.categories?.map((c_item) => {
      if (c_item.id === c_item_id) {
        return {
          ...c_item,
          questions: c_item.questions?.map((q_item) => {
            if (q_item.id === q_item_id) {
              return {
                ...q_item,
                text_answer: value
              };
            }
            return q_item;
          })
        };
      }
      return c_item;
    });
    setSubmitMedicalForm((prevState) => ({
      ...prevState,
      categories: updatedCategories
    }));
  };

  const handleTableInputChange = (
    e,
    c_item_id,
    q_item_id,
    rowIndex,
    colIndex
  ) => {
    const newValue = e.target.value;
    const updatedCategories = submitMedicalForm.categories.map((c_item) => {
      if (c_item.id === c_item_id) {
        return {
          ...c_item,
          questions: c_item.questions.map((q_item) => {
            if (q_item.id === q_item_id) {
              return {
                ...q_item,
                table_data: q_item.table_data.map((data) => {
                  const updatedRows = [...(data.table_rows || [])];
                  if (!updatedRows[rowIndex]) {
                    updatedRows[rowIndex] = Array(
                      data.table_headers.length
                    ).fill({ answer: "" });
                  }
                  updatedRows[rowIndex][colIndex] = { value: newValue };
                  return {
                    ...data,
                    table_rows: updatedRows
                  };
                })
              };
            }
            return q_item;
          })
        };
      }
      return c_item;
    });
    setSubmitMedicalForm((prevState) => ({
      ...prevState,
      categories: updatedCategories
    }));
  };

  return (
    <>
      <div className="p-8">
        <div className="flex items-center gap-2">
          <Link
            to="/manage-forms/medical-history"
            className="text-[#788BA5] cursor-pointer bg-transparent text-[14px] font-[400] hover:text-primary"
          >
            Medical History Form
          </Link>
          <MdOutlineKeyboardArrowRight className="text-[#788BA5] text-[20px] " />
          <p className="text-primary text-[14px] font-[400]">User</p>
        </div>
        <div className="flex justify-between items-center mt-2">
          <h1 className="text-[#272727] text-[24px] font-[600] ">
            Medical History Form
          </h1>
          {loading ? (
            <div className="flex border border-[#3B82F6] bg-primary justify-center items-center p-2 rounded-md gap-2 cursor-pointer w-[78px] h-[40px]">
              <ButtonLoader_1 />
            </div>
          ) : (
            <div
              onClick={handleSubmit}
              className="flex border border-[#3B82F6] justify-center items-center p-2 rounded-md gap-2 cursor-pointer"
            >
              <span className="text-[#3B82F6] text-[14px]">Share</span>
              <PiShareFat className="text-[#3B82F6] text-[20px]" />
            </div>
          )}
        </div>
        {/* Medical History Form  */}
        {medicalForm.loading ? (
          <div className="w-full h-[400px] rounded flex justify-center items-center bg-white flex-col">
            <h1 className="text-[18px] font-[400] ">Loading ... </h1>
          </div>
        ) : (
          <div className="bg-white  mt-2 rounded w-full h-auto p-6">
            <div className="w-full flex justify-center">
              <img
                src="/assets/logo/logo2.png"
                alt="CHARITE"
                className="w-[170px]"
              />
            </div>
            <h1 className="text-[#272727] text-[24px] font-[700] text-center mt-2">
              CharitéCentrum für Neurologie, Neurochirurgie und Psychiatrie
            </h1>
            <div className="mt-10 flex relative">
              <div className="flex gap-6 w-[20%]">
                <img src="/assets/images/user.png" alt="CHARITE" />
              </div>
              <div className="w-[80%] ">
                <div className="border-l-2 border-gray-100 w-full pl-10 flex flex-col justify-between gap-2">
                  <div className="flex items-center justify-between w-full">
                    <h1 className="text-[#272727] text-[14px] font-[600] font-roboto w-[70%]">
                      {personalDetail.clinicName}
                    </h1>
                    <p className="text-[#788BA5] text-[14px] font-[400] font-roboto w-[30%]">
                      {personalDetail.phone1}
                    </p>
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <h1 className="text-[#272727] text-[14px] font-[500] font-roboto w-[70%]">
                      {personalDetail.director}
                    </h1>
                    <p className="text-[#788BA5] text-[14px] font-[400] font-roboto w-[30%]">
                      {personalDetail.phone2}
                    </p>
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <h1 className="text-[#272727] text-[14px] font-[600] font-roboto w-[70%]">
                      {personalDetail.specialClinic}
                    </h1>
                    <p className="text-[#788BA5] text-[14px] font-[400] font-roboto w-[30%]">
                      {personalDetail.email}
                    </p>
                  </div>
                  <div className="flex justify-between w-full">
                    <h1 className="text-[#272727] text-[14px] font-[500] font-roboto w-[70%]">
                      {personalDetail.team.split("\n").map((line, index) => (
                        <span key={index}>
                          {line}
                          <br />
                        </span>
                      ))}
                    </h1>
                    <p className="text-[#788BA5] text-[14px] font-[400] font-roboto w-[30%]">
                      {personalDetail.website}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Medical History Form questions  */}
            <div className="mt-10">
              <h1 className="text-[#272727] text-[20px] font-[600] text-center">
                {medicalForm?.categories?.category_name}
              </h1>
              <h1 className="text-[#E41D1D] text-[16px] font-[400] text-center">
                CharitéCentrum für Neurologie, Neurochirurgie und Psychiatrie
              </h1>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="overflow-x-auto relative">
                {medicalForm?.data?.categories?.map((c_Item, qIndex) => (
                  <div key={qIndex} className="relative">
                    <h1 className="font-[600] text-[20px] text-[#272727] text-center mt-2">
                      {c_Item.category_name}
                    </h1>
                    {c_Item?.questions?.map((q_item, itemIndex) => (
                      <div key={itemIndex} className="relative mt-6">
                        <h1 className="text-[#272727] text-[16px] font-[600] w-[93%]">
                          {itemIndex + 1}: {q_item.question_statement}
                        </h1>
                        {q_item.question_type === "table" && (
                          <table className="min-w-full border-collapse border border-gray-300 mt-2">
                            <thead>
                              <tr className="bg-gray-100">
                                {q_item?.table_data?.[0]?.table_headers?.map(
                                  (header, index) => (
                                    <th
                                      key={index}
                                      className="border border-gray-300 p-2 text-left"
                                    >
                                      {header}
                                    </th>
                                  )
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {Array.from({
                                length: q_item.sequence_number
                              }).map((_, rowIndex) => (
                                <tr key={rowIndex}>
                                  {q_item?.table_data?.[0]?.table_headers?.map(
                                    (_, colIndex) => (
                                      <td
                                        key={colIndex}
                                        className="border border-gray-300"
                                      >
                                        <input
                                          type="text"
                                          placeholder="-"
                                          className="w-full p-2 border-gray-300 rounded bg-transparent text-[#787878] outline-none"
                                          onChange={(e) =>
                                            handleTableInputChange(
                                              e,
                                              c_Item.id,
                                              q_item.id,
                                              rowIndex,
                                              colIndex
                                            )
                                          }
                                        />
                                      </td>
                                    )
                                  )}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        )}

                        {q_item.question_type === "text" && (
                          <input
                            type="text"
                            onChange={(e) =>
                              handleTextInputChange(e, c_Item.id, q_item.id)
                            }
                            placeholder="Enter your answer here"
                            className="outline-none rounded py-2 px-4 w-full border text-[#787878] mt-2"
                          />
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
}
export default PatientMedicalHistoryForm;
