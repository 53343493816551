/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";
import AppHead from "../../seo/AppHead";
import { HiOutlineDotsVertical, HiUserGroup } from "react-icons/hi";
import { FaUserDoctor } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import DeleteModal from "../../components/modals/DeleteModal";
import useGetPatients from "../../hooks/useGetPatients";
import useGetDoctors from "../../hooks/useGetDoctors";
import { GoChevronRight } from "react-icons/go";
import { CiSearch } from "react-icons/ci";
import { debounce } from "lodash";
import useDashboardData from "../../hooks/useDashboardData";
import { CardSkelton_1 } from "../../components/shared/Skeltons";

function DashboardPage() {
  return (
    <>
      <AppHead title="Dashboard - CHARITE" />
      <div className="p-10">
        <DashboardCard />
      </div>
    </>
  );
}

export default DashboardPage;

function DashboardCard() {
  const { dashboard, DashboardData } = useDashboardData();
  useEffect(() => {
    DashboardData(`/api/admin/dashboard/`);
  }, []);

  const dashBoard = [
    {
      title: "Total Registered Doctors",
      counts: dashboard?.data?.total_doctors || 0,
      icon: <FaUserDoctor />,
      bgColor: "bg-[#FEC53D]",
      textColor: "text-white",
    },
    {
      title: "Total Registered Patients",
      counts: dashboard?.data?.total_patients || 0,
      icon: <HiUserGroup />,
      bgColor: "bg-[#FF9972]",
      textColor: "text-white",
    },
    {
      title: "New Users This Month",
      counts: dashboard?.data?.new_users_this_month || 0,
      count_details: {
        doctors: dashboard?.data?.doctors_this_month || 0,
        patients: dashboard?.data?.patients_this_month || 0,
      },
      icon: <HiUserGroup />,
      bgColor: "bg-[#4AD991]",
      textColor: "text-white",
    },
  ];
  return (
    <>
      <div className="flex md:flex-row xs:flex-col gap-4">
        {dashboard.loading ? (
          <>
            {Array.from({ length: 3 }).map((_, index) => (
              <CardSkelton_1 key={index} />
            ))}
          </>
        ) : (
          <>
            {dashBoard?.map((data, index) => (
              <div
                key={index}
                className="bg-white rounded-lg md:w-[33.33%] xs:w-full py-6 px-6 flex items-center justify-between relative"
                style={{ boxShadow: "0px 0px 7px #e0e0e0" }}
              >
                <div className="flex flex-col">
                  <p className="font-[500] text-[16px] font-roboto text-[#787878]">
                    {data.title}
                  </p>
                  {data.count_details ? (
                    <div className="flex items-center gap-3">
                      <h1 className="font-[600] text-[32px] font-roboto text-[#272727]">
                        {data.counts}
                      </h1>
                      <p className="font-[500] text-[12px] font-roboto text-[#788BA5]">
                        Doctors: {data.count_details.doctors}, Patients:{" "}
                        {data.count_details.patients}
                      </p>
                    </div>
                  ) : (
                    <h1 className="font-[600] text-[32px] font-roboto text-[#272727]">
                      {data.counts}
                    </h1>
                  )}
                </div>
                <div
                  className={`text-[32px] ${data.textColor} ${data.bgColor} rounded-full w-[60px] h-[60px] flex justify-center items-center`}
                >
                  {data.icon}
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      <div className="flex gap-4 justify-center mt-10">
        <div className="flex flex-col w-[70%]">
          <DoctorsList />
          <PatientsList />
        </div>
        <div className="w-[30%]">
          <ActivityLog />
        </div>
      </div>
    </>
  );
}

function DoctorsList() {
  const Navigate = useNavigate();
  const [query, setQuery] = useState("");
  const { doctors, setDoctors, GetDoctors } = useGetDoctors();

  const debouncedSearch = useCallback(
    debounce((value) => {
      setDoctors((prevState) => ({ ...prevState, currentPage: 1 }));
      GetDoctors(
        `/api/admin/doctors/?page=${doctors.currentPage}&search=${value}`
      );
    }, 1000),
    []
  );

  const handleChange = (e) => {
    const { value } = e.target;
    setQuery(value);
    debouncedSearch(value);
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  useEffect(() => {
    GetDoctors(
      `/api/admin/doctors/?page=${doctors.currentPage}&search=${query}`
    );
  }, [doctors.currentPage]);
  return (
    <>
      <div className="flex items-center justify-between mb-3">
        <div className="flex items-center gap-2">
          <h1 className="font-[700] text-[20px] font-roboto text-[#272727]">
            Doctor List
          </h1>
          <p className="font-[400] text-[16px] font-roboto text-primary">
            ({doctors?.data?.count})
          </p>
        </div>
        <div className="flex items-center bg-white border border-[#DEE4ED] px-3 rounded-lg w-[60%] h-[40px]">
          <CiSearch className="text-[22px] mr-3 text-[#788BA5]" />
          <input
            type="search"
            value={query}
            onChange={handleChange}
            placeholder="Search by name or email"
            className="w-[90%] outline-none border-none font-[400] text-[14px] font-roboto"
          />
        </div>
      </div>
      <div className="bg-white border border-[#DEE4ED] rounded-lg">
        {doctors?.data?.results?.map((item, index) => (
          <div
            key={index}
            onClick={() => {
              Navigate("/doctors/doctor-details", {
                state: {
                  selectedDoctor: item,
                },
              });
            }}
            className={`flex items-center justify-between px-3 py-2 bg-white text-[14px] text-[#788BA5] border-b border-b-[#DEE4ED] hover:bg-primary hover:text-white cursor-pointer group ${
              index === 0 && "rounded-t-lg"
            }`}
          >
            <div className="flex items-center gap-2">
              <svg
                class="w-[40px] h-[40px] text-gray-200 dark:text-gray-700"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
              </svg>
              <div>
                <p className="font-[500] text-[18px] font-roboto text-[#272727] group-hover:text-white">
                  {item.first_name} {item.last_name}
                </p>
                <span className="font-[400] text-[12px] font-roboto text-[#3B82F6] group-hover:text-white">
                  {item.doctor_profile?.department}
                </span>
              </div>
            </div>
            <MdKeyboardArrowRight className="text-[25px]" />
          </div>
        ))}
        <Link
          to="/doctors"
          className="text-center py-2 px-3 text-[#3B82F6] flex items-center hover:bg-primary hover:text-white rounded-b-lg"
        >
          View all Doctors
          <MdKeyboardArrowRight className="text-[25px]" />
        </Link>
      </div>
    </>
  );
}

function PatientsList() {
  const dropdownRef = useRef(null);
  const Navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [selected, setSelected] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(false);
  const { patients, setPatients, GetPatients } = useGetPatients();

  const debouncedSearch = useCallback(
    debounce((value) => {
      setPatients((prevState) => ({ ...prevState, currentPage: 1 }));
      GetPatients(
        `/api/patient-list/?page=${patients.currentPage}&search=${value}`
      );
    }, 1000),
    []
  );

  const handleChange = (e) => {
    const { value } = e.target;
    setQuery(value);
    debouncedSearch(value);
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  useEffect(() => {
    GetPatients(
      `/api/patient-list/?page=${patients.currentPage}&search=${query}`
    );
  }, [patients.currentPage]);

  const handleShow = (index) => {
    if (activeDropdown === index) {
      setActiveDropdown(!activeDropdown);
    } else {
      setActiveDropdown(index);
    }
  };

  const handleCloseDropdown = () => {
    setActiveDropdown(false);
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        e.target.closest(".small-circle-main") ||
        (dropdownRef.current && dropdownRef.current.contains(e.target))
      ) {
        return;
      }
      handleCloseDropdown();
    };
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  return (
    <>
      <div className="flex items-center justify-between mb-3 mt-10">
        <div className="flex items-center gap-2">
          <h1 className="font-[700] text-[20px] font-roboto text-[#272727] ">
            Patients List
          </h1>
          <p className="font-[400] text-[16px] font-roboto text-primary">
            ({patients?.data?.count})
          </p>
        </div>
        <div className="flex items-center bg-white border border-[#DEE4ED] px-3 rounded-lg w-[60%] h-[40px]">
          <CiSearch className="text-[22px] mr-3 text-[#788BA5]" />
          <input
            type="search"
            value={query}
            onChange={handleChange}
            placeholder="Search by name or email"
            className="w-[90%] outline-none border-none font-[400] text-[14px] font-roboto"
          />
        </div>
      </div>
      <div className="bg-white border border-[#DEE4ED] rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right">
          <thead className="bg-[#FAFAFA] text-[#272727] font-[500] text-[15px] border-b border-[#DEE4ED] rounded-t-lg">
            <tr>
              {["Patient Name", "Disease", "Email", "Action"].map(
                (item, index) => (
                  <th
                    scope="col"
                    className={`py-4 px-5 ${
                      index === 0
                        ? "rounded-tl-lg"
                        : index === 3
                        ? "rounded-tr-lg"
                        : ""
                    }`}
                    key={index}
                  >
                    {item}
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody>
            <>
              {patients?.data?.results?.map((item, index) => (
                <tr
                  key={index}
                  className="bg-white text-[14px] text-[#788BA5] border-b border-[#DEE4ED]"
                >
                  <td className="py-3 px-5">
                    <div className="flex items-center gap-2">
                      <svg
                        className="w-[35px] h-[35px] text-gray-200 dark:text-gray-700"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                      </svg>
                      <div>
                        <p className="font-[500] text-[16px] font-roboto text-[#272727] ">
                          {item.user.first_name} {item.user.last_name}
                        </p>
                        <span className="font-[400] text-[12px] font-roboto text-[#787878]">
                          {item.user.age}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="py-3 px-5"> {item.disease}</td>
                  <td className="py-3 px-5">{item.user.email}</td>
                  <td className="py-3 px-5">
                    <div className="relative small-circle-main">
                      <HiOutlineDotsVertical
                        className="cursor-pointer"
                        onClick={() => handleShow(index)}
                      />
                      {activeDropdown === index && (
                        <div
                          ref={dropdownRef}
                          className="absolute py-1 w-[100px] bg-white flex flex-col rounded-sm right-[30%] top-[100%] z-20 shadow-md"
                        >
                          <p
                            onClick={() => {
                              Navigate("/patients/patient-details", {
                                state: {
                                  selectedPatient: item,
                                },
                              });
                            }}
                            className="px-3 py-1 cursor-pointer font-[400] text-[13px] font-roboto text-[#787878] hover:bg-[#F5F5FE] hover:text-primary"
                          >
                            View Details
                          </p>
                          <p
                            onClick={() => {
                              Navigate("/patients/patient-form", {
                                state: {
                                  action: "edit",
                                  selectedPatient: item,
                                },
                              });
                            }}
                            className="px-3 py-1 cursor-pointer font-[400] text-[13px] font-roboto text-[#787878] hover:bg-[#F5F5FE] hover:text-primary"
                          >
                            Edit
                          </p>
                          <p
                            onClick={() => {
                              setDeleteModal(!deleteModal);
                              setSelected(item);
                            }}
                            className="px-3 py-1 cursor-pointer font-[400] text-[13px] font-roboto text-[#787878] hover:bg-[#F5F5FE] hover:text-primary"
                          >
                            Delete
                          </p>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </>
          </tbody>
        </table>
        <Link
          to="/patients"
          className="text-center py-2 px-3 text-[#3B82F6] flex items-center hover:bg-primary hover:text-white rounded-b-lg"
        >
          View all Patients
          <MdKeyboardArrowRight className="text-[25px]" />
        </Link>
      </div>
      <DeleteModal
        setIsOpenModal={setDeleteModal}
        isOpenModal={deleteModal}
        setSelected={setSelected}
        url={`/api/admin/manage-users/${selected?.user?.id}`}
      />
    </>
  );
}

function ActivityLog() {
  return (
    <>
      <h1 className="font-[700] text-[20px] font-roboto text-[#272727] mb-2">
        Activity Log
      </h1>
      <div className="p-4 h-auto border border-[#DEE4ED] bg-white rounded-lg">
        {Array.from({ length: 10 }).map((_, index) => (
          <div className="mb-3" key={index}>
            <p className="text-[14px] font-[400] font-roboto text-[#272727]">
              Dr. Samantha Queque logged in from IP address 192.168.1.1.
            </p>
            <span className="text-[14px] font-[500] font-roboto text-[#788BA5]">
              1 hour ago
            </span>
          </div>
        ))}
        <Link to="#" className="flex items-center mt-3 cursor-pointer">
          <p className="text-[14px] font-[500] font-roboto text-primary">
            View All Activities
          </p>
          <GoChevronRight className="text-[20px] text-primary" />
        </Link>
      </div>
    </>
  );
}
