import axios from "axios";
import Swal from "sweetalert2";
import { getAccessToken } from "../storage/storage";
import { useNavigate } from "react-router-dom";

function useAddPatient() {
  const token = getAccessToken();
  const Navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const AddPatient = async (payload, handleClose) => {
    await axios
      .post(`${BASE_URL}/api/admin/manage-users/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        handleClose();
        Swal.fire({
          icon: "success",
          text: "Patient successfully added!",
          customClass: {
            confirmButton: "custom-green-button"
          }
        }).then((result) => {
          if (result.isConfirmed) {
            Navigate("/patients");
          }
        });
      })
      .catch((error) => {
        handleClose();
        Swal.fire({
          icon: "error",
          text:
            error?.response?.data?.message ||
            "Oops! Something went wrong on our end. Please give us a moment to fix it. Feel free to try again later",
          customClass: {
            confirmButton: "custom-red-button"
          }
        });
      });
  };
  return { AddPatient };
}
export default useAddPatient;
