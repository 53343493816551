import React from "react";
import { Dialog, Transition } from "@headlessui/react";

const EditPersonalDetailModal = ({
  isOpen,
  onClose,
  personalDetail,
  onChange,
  onSave
}) => {
  return (
    <Transition.Root show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all 2xl:w-[800px] lg:w-[800px] xs:w-full h-auto xs:mx-2 my-4 md:p-6 xs:p-3">
              <h1 className="2xl:text-[24px] font-semibold lg:text-[20px]">
                Edit Personal Details
              </h1>
              <div className="space-y-4 mt-2">
                <input
                  name="clinicName"
                  value={personalDetail.clinicName}
                  onChange={onChange}
                  placeholder="Clinic Name"
                  className="w-full p-2 border border-gray-300 rounded-md outline-none"
                />
                <input
                  name="phone1"
                  value={personalDetail.phone1}
                  onChange={onChange}
                  placeholder="Phone 1"
                  className="w-full p-2 border border-gray-300 rounded-md outline-none"
                />
                <input
                  name="director"
                  value={personalDetail.director}
                  onChange={onChange}
                  placeholder="Director"
                  className="w-full p-2 border border-gray-300 rounded-md outline-none"
                />
                <input
                  name="phone2"
                  value={personalDetail.phone2}
                  onChange={onChange}
                  placeholder="Phone 2"
                  className="w-full p-2 border border-gray-300 rounded-md outline-none"
                />
                <input
                  name="specialClinic"
                  value={personalDetail.specialClinic}
                  onChange={onChange}
                  placeholder="Special Clinic"
                  className="w-full p-2 border border-gray-300 rounded-md outline-none"
                />
                <input
                  name="email"
                  value={personalDetail.email}
                  onChange={onChange}
                  placeholder="Email"
                  className="w-full p-2 border border-gray-300 rounded-md outline-none"
                />
                <textarea
                  name="team"
                  value={personalDetail.team}
                  onChange={onChange}
                  placeholder="Team"
                  className="w-full p-2 border border-gray-300 rounded-md outline-none"
                />
                <input
                  name="website"
                  value={personalDetail.website}
                  onChange={onChange}
                  placeholder="Website"
                  className="w-full p-2 border border-gray-300 rounded-md outline-none"
                />
              </div>
              <div className="mt-6 flex justify-end space-x-2">
                <button
                  onClick={onClose}
                  className="bg-gray-300 px-4 py-2 rounded-md "
                >
                  Cancel
                </button>
                <button
                  onClick={onSave}
                  className="bg-blue-500 text-white px-4 py-2 rounded-md"
                >
                  Save
                </button>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default EditPersonalDetailModal;
