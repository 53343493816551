import React, { useEffect, useState, Fragment } from "react";
import { FaPlus } from "react-icons/fa";
import AddMedicationTableModal from "../../../components/modals/MedicalHistoryModals/AddMedicationTableModal";
import AddMedicalQuestionModal from "../../../components/modals/MedicalHistoryModals/AddMedicalQuestionModal";
import { Dialog, Transition } from "@headlessui/react";
import useAddForm from "../../../hooks/useAddForm";
import { ButtonLoader_1 } from "../../../components/shared/Loaders";
import { HiArrowLeft } from "react-icons/hi";
import { Link, useLocation } from "react-router-dom";
import useGetMedicalHistoryForm from "../../../hooks/useGetMedicalHistoryForm";

const personalDetail = {
  clinicName: "Klinik und Hochschulambulanz für Psychiatrie und Psychotherapie",
  phone1: "(030) 450 517 666",
  director: "Direktor. Univ.-Prof. Dr. med. Christian Otte",
  phone2: "(030) 450 517 930",
  specialClinic: "Spezialambulanz für ADHS im Erwachsenenalter",
  email: "adhs-spezialambulanz@charite.de",
  team: `Leitung: Priv.-Doz. Dr. med. Julian Hellmann-Regen
    Fr. Dr. med. Olga Arbach
    Fr. Dr. med. Maria Lietz`,
  website: "http://psychiatrie.charite.de/"
};
function DoctorMedicalHistoryForm() {
  const location = useLocation();
  const { selectedPatient = {} } = location.state || {};

  const { medicalForm, setMedicalForm, GetMedicalHistoryForm } =
    useGetMedicalHistoryForm();

  useEffect(() => {
    GetMedicalHistoryForm(`/api/medical-history-forms/${selectedPatient?.id}/`);
  }, []);

  return (
    <>
      <div>
        <div className="p-6 bg-white m-4 rounded-md">
          <div className="flex justify-between items-center">
            <Link
              to="/manage-forms/medical-history"
              className="flex items-center gap-2 "
            >
              <h1 className="text-[24px] font-[600px] text-[#272727]  ">
                Medical History Form
              </h1>
            </Link>
          </div>
          <div className="w-full bg-[#DEE4ED] h-[1px] mt-4"></div>
          {medicalForm.loading ? (
            <div className="w-full h-[400px] rounded flex justify-center items-center bg-white flex-col">
              <h1 className="text-[18px] font-[400] ">Loading ... </h1>
            </div>
          ) : (
            <div className="bg-white  mt-2 rounded w-full h-auto ">
              <div className="w-full flex justify-center">
                <img
                  src="/assets/logo/logo2.png"
                  alt="CHARITE"
                  className="w-[170px]"
                />
              </div>
              <h1 className="text-[#272727] text-[24px] font-[700] text-center mt-2">
                CharitéCentrum für Neurologie, Neurochirurgie und Psychiatrie
              </h1>
              <div className="mt-10 flex relative">
                <div className="flex gap-6 w-[20%]">
                  <img src="/assets/images/user.png" alt="CHARITE" />
                </div>
                <div className="w-[80%]">
                  <div className="border-l-2 border-gray-100 w-full pl-10 flex flex-col justify-between ">
                    <div className="flex items-center justify-between w-full">
                      <h1 className="text-[#272727] text-[14px] font-[600] font-roboto w-[70%]">
                        {personalDetail.clinicName}
                      </h1>
                      <p className="text-[#788BA5] text-[14px] font-[400] font-roboto w-[30%]">
                        {personalDetail.phone1}
                      </p>
                    </div>
                    <div className="flex items-center justify-between w-full">
                      <h1 className="text-[#272727] text-[14px] font-[500] font-roboto w-[70%]">
                        {personalDetail.director}
                      </h1>
                      <p className="text-[#788BA5] text-[14px] font-[400] font-roboto w-[30%]">
                        {personalDetail.phone2}
                      </p>
                    </div>
                    <div className="flex items-center justify-between w-full">
                      <h1 className="text-[#272727] text-[14px] font-[600] font-roboto w-[70%]">
                        {personalDetail.specialClinic}
                      </h1>
                      <p className="text-[#788BA5] text-[14px] font-[400] font-roboto w-[30%]">
                        {personalDetail.email}
                      </p>
                    </div>
                    <div className="flex justify-between w-full">
                      <h1 className="text-[#272727] text-[14px] font-[500] font-roboto w-[70%]">
                        {personalDetail.team.split("\n").map((line, index) => (
                          <span key={index}>
                            {line}
                            <br />
                          </span>
                        ))}
                      </h1>
                      <p className="text-[#788BA5] text-[14px] font-[400] font-roboto w-[30%]">
                        {personalDetail.website}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-10">
                <h1 className="text-[#272727] text-[20px] font-[600] text-center">
                  {medicalForm?.categories?.category_name}
                </h1>
                <h1 className="text-[#E41D1D] text-[16px] font-[400] text-center">
                  CharitéCentrum für Neurologie, Neurochirurgie und Psychiatrie
                </h1>
              </div>

              <QuestionSection
                medicalForm={medicalForm}
                setMedicalForm={setMedicalForm}
                selectedPatient={selectedPatient}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default DoctorMedicalHistoryForm;

const QuestionSection = ({ medicalForm, setMedicalForm, selectedPatient }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [activeCategoryIndex, setActiveCategoryIndex] = useState(null);

  const handleAddQuestionClick = (qIndex) => {
    setActiveCategoryIndex(qIndex);
    setIsModalOpen(true);
  };

  const handleSelectQuestionType = (type) => {
    setModalType(type);
    setIsModalOpen(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalType(null);
    setActiveCategoryIndex(null);
  };

  const handleSaveTableQuestion = (newQuestion, headers, rows) => {
    setMedicalForm((prevForm) => ({
      ...prevForm,
      data: {
        ...prevForm.data,
        categories: prevForm.data.categories.map((category, index) =>
          index === activeCategoryIndex
            ? {
                ...category,
                questions: [
                  ...category.questions,
                  {
                    question_statement: newQuestion,
                    question_type: "table",
                    sequence_number: rows.length,
                    text_answer: null,
                    table_data: [
                      {
                        table_headers: headers.map(
                          (header) => header.column_header
                        ),
                        table_rows: []
                      }
                    ]
                  }
                ]
              }
            : category
        )
      }
    }));
    handleCloseModal();
  };

  const handleSaveTextQuestion = (newQuestion) => {
    setMedicalForm((prevForm) => ({
      ...prevForm,
      data: {
        ...prevForm.data,
        categories: prevForm.data.categories.map((category, index) =>
          index === activeCategoryIndex
            ? {
                ...category,
                questions: [
                  ...category.questions,
                  {
                    question_statement: newQuestion,
                    question_type: "text",
                    sequence_number: 1,
                    text_answer: null
                  }
                ]
              }
            : category
        )
      }
    }));
    handleCloseModal();
  };

  //   save changes
  const { AddForm, loading } = useAddForm();
  const handleSubmit = async () => {
    await AddForm(
      {
        patients: selectedPatient?.id ? [selectedPatient.id] : [],
        categories: medicalForm?.data?.categories
      },
      `/api/medical-history-forms/`
    );
  };

  return (
    <div className="overflow-x-auto relative">
      {medicalForm?.data?.categories?.map((qItem, qIndex) => (
        <div key={qIndex} className="relative">
          <h1 className="font-[600] text-[20px] text-[#272727] text-center mt-2">
            {qItem.category_name}
          </h1>
          {qItem?.questions?.map((item, itemIndex) => (
            <div key={itemIndex} className="relative mt-6">
              <h1 className="text-[#272727] text-[16px] font-[600] w-[93%]">
                {itemIndex + 1}: {item.question_statement}
              </h1>

              {item.question_type === "table" && (
                <table className="min-w-full border-collapse border border-gray-300 mt-2">
                  <thead>
                    <tr className="bg-gray-100">
                      {item?.table_data?.[0]?.table_headers?.map(
                        (header, index) => (
                          <th
                            key={index}
                            className="border border-gray-300 p-2 text-left"
                          >
                            {header}
                          </th>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {item?.table_data?.[0]?.table_rows?.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {row?.map((cell, colIndex) => (
                          <td
                            key={colIndex}
                            className="border border-gray-300 p-2"
                          >
                            <input
                              type="text"
                              placeholder="-"
                              className="w-full p-2 border-gray-300 rounded bg-white text-black outline-none"
                              value={cell?.value || ""}
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

              {item.question_type === "text" && (
                <input
                  type="text"
                  placeholder="Enter your answer here"
                  className="outline-none bg-white-100 rounded py-2 px-4 w-full border text-black  mt-2 "
                  value={item?.text_answer || ""}
                />
              )}
            </div>
          ))}
          {/* <div
            className="flex items-center gap-2 text-primary text-[14px] font-[500] cursor-pointer mt-5 w-[140px]"
            onClick={() => handleAddQuestionClick(qIndex)}
          >
            <FaPlus />
            <p className="border-b-2 border-primary">Add Question</p>
          </div> */}
        </div>
      ))}

      <Transition.Root show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={handleCloseModal}>
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full max-w-md p-6">
                <h1 className="2xl:text-[24px] font-semibold lg:text-[20px]">
                  Select Question Type
                </h1>
                <div className="mt-4 space-y-4">
                  <button
                    className="w-full py-2 px-4 bg-blue-500 text-white rounded"
                    onClick={() => handleSelectQuestionType("table")}
                  >
                    Table Question
                  </button>
                  <button
                    className="w-full py-2 px-4 bg-green-500 text-white rounded"
                    onClick={() => handleSelectQuestionType("text")}
                  >
                    Text Question
                  </button>
                </div>
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* <div className="flex justify-end items-center ">
        <button className="flex  bg-primary text-white justify-center items-center w-[130px] p-2 rounded-md  cursor-pointer">
          Save Changes
        </button>
      </div> */}
      {modalType === "table" && (
        <AddMedicationTableModal
          isOpen={true}
          onClose={handleCloseModal}
          onSave={handleSaveTableQuestion}
        />
      )}

      {modalType === "text" && (
        <AddMedicalQuestionModal
          isOpen={true}
          onClose={handleCloseModal}
          onSave={handleSaveTextQuestion}
        />
      )}
    </div>
  );
};
