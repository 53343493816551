import React, { useEffect, useState } from "react";
import AppHead from "../../seo/AppHead";
import Dropdown from "../../components/shared/Dropdown";
import { HiOutlineChevronRight } from "react-icons/hi";
import Paginations from "../../components/shared/Paginations";
import ShowMessage from "../../components/shared/ShowMessage";
import { useNavigate } from "react-router-dom";
import useGetMedicalHistoryForm from "../../hooks/useGetMedicalHistoryForm";
import { TableSkelton_5 } from "../../components/shared/Skeltons";

function PatientMedicalHistoryPage() {
  const Navigate = useNavigate();
  const [filters, setFilters] = useState({
    name: "All",
    value: "all"
  });
  const { medicalForm, setMedicalForm, GetMedicalHistoryForm } =
    useGetMedicalHistoryForm();
  useEffect(() => {
    GetMedicalHistoryForm(
      `/api/patient/medical-history-forms/?page=${medicalForm.currentPage}`
    );
  }, [medicalForm.currentPage]);

  const handlePageChange = (event, value) => {
    setMedicalForm((prevState) => ({
      ...prevState,
      currentPage: value,
      loading: true
    }));
  };
  const medicalPerPage = 12;
  const startIndex = (medicalForm.currentPage - 1) * medicalPerPage;

  //   convert date and time in formal form
  function formatDate(apiDate) {
    const date = new Date(apiDate);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return `${day}-${month}-${year} ${formattedHours}:${formattedMinutes}`;
  }
  return (
    <>
      <AppHead title="Dashboard - CHARITE" />
      <div className="p-10">
        <div className="bg-white p-6 rounded">
          <div className="flex items-center justify-between mb-3">
            <h1 className="text-[24px] font-[600] text-[#272727]">
              Medical History Form{" "}
              <span className="text-primary text-[16px] font-[400]">
                ({medicalForm?.data?.results?.length})
              </span>
            </h1>
            <Dropdown
              options={[
                { name: "All", value: "all" },
                { name: "Dr. John Smith", value: "Dr. John Smith" },
                { name: "Dr. Emily Johnson", value: "Dr. Emily Johnson" },
                {
                  name: "Dr. Michael Williams",
                  value: "Dr. Michael Williams"
                },
                {
                  name: "Dr. Michael Williams",
                  value: "Dr. Michael Williams"
                }
              ]}
              selected={filters}
              onChange={(option) =>
                setFilters({ name: option.name, value: option.value })
              }
            />
          </div>
          <hr />
          {medicalForm.loading ? (
            <>
              {Array.from({ length: 2 }).map((_, index) => (
                <TableSkelton_5 key={index} />
              ))}
            </>
          ) : (
            <>
              <div className="grid grid-cols-3 gap-4 w-full mt-3">
                {medicalForm?.data?.results?.map((data, index) => (
                  <div
                    key={index}
                    className="border border-gray-200 rounded-xl cursor-pointer h-auto"
                  >
                    <div className="flex flex-col p-3 gap-5">
                      <div className="flex items-center ">
                        <h1 className="text-[16px] font-[600] text-[#272727]">
                          Medical History Form {startIndex + index + 1}
                        </h1>
                      </div>
                      {/* <div className="w-[25%] text-[10px]  font-[600] bg-[#46F7B7] rounded  text-center py-1">
                        {data.status}
                      </div> */}
                      <div className="flex justify-between items-center">
                        <p className="text-[14px] font-[400] text-[#787878]">
                          Sent
                        </p>
                        <p className="text-[13px] font-[500] text-[#788BA5] flex items-center gap-2 ">
                          {data?.sent_time ? formatDate(data?.sent_time) : ""}
                        </p>
                      </div>

                      <div className="flex justify-between items-center">
                        <p className="text-[14px] font-[400] text-[#787878]">
                          Received
                        </p>
                        <p className="text-[13px] font-[500] text-[#788BA5] flex items-center gap-2 ">
                          {data?.received_time
                            ? formatDate(data?.received_time)
                            : ""}
                        </p>
                      </div>
                    </div>

                    <hr />

                    <div
                      onClick={() => {
                        Navigate(
                          "/manage-forms/medical-history/medical-history-form",
                          {
                            state: {
                              selectedForm: data
                            }
                          }
                        );
                      }}
                      className="flex justify-between items-center text-primary text-[13px] p-2"
                    >
                      <p className="font-[500]">View Form</p>
                      <HiOutlineChevronRight className="text-[18px]" />
                    </div>
                  </div>
                ))}
              </div>
              <div className="w-full flex justify-between items-center mt-2">
                {medicalForm?.data?.results?.length > 0 && (
                  <p className="text-[#272727] font-roboto text-[14px] font-[400] leading-5">
                    {medicalForm?.data?.display_string}
                  </p>
                )}
                <Paginations
                  totalPages={medicalForm?.totalPages}
                  currentPage={medicalForm?.currentPage}
                  handlePageChange={handlePageChange}
                />
              </div>
              {!medicalForm?.loading && medicalForm?.message && (
                <div className="bg-white flex justify-center items-center w-full h-[300px]">
                  <ShowMessage message={medicalForm?.message} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default PatientMedicalHistoryPage;
